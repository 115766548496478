import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Radio } from '../Form';

const ProductOptionSelect = ({
  title,
  options,
  className,
  onChange,
  validModel,
  validSize,
  validLogo,
  validFinish,
  validBoxType,
  valueModel,
  valueSize,
  valueFinish,
  valueLogo,
  valueBoxType,
  ...otherProps
}) => {
  const checkDisable = (name, value) => {
    if (name === 'model_name') {
      return false;
    }
    if (name === 'size_name') {
      return !validSize.includes(value);
    }
    if (name === 'finish_name') {
      return !validFinish.includes(value);
    }
    if (name === 'logos_name') {
      return !validLogo.includes(value);
    }
    if (name === 'boxTypes_name') {
      return !validBoxType.includes(value);
    }
    return false;
  };
  const isChecked = (name, value) => {
    if (name === 'model_name') {
      return value === valueModel;
    }
    if (name === 'size_name') {
      return value === valueSize;
    }
    if (name === 'finish_name') {
      return value === valueFinish;
    }
    if (name === 'logos_name') {
      return value === valueLogo;
    }
    if (name === 'boxTypes_name') {
      return value === valueBoxType;
    }
    return false;
  };
  return (
    <div className={classNames('product-option-select', className)} {...otherProps}>
      <span className="product-option-select__title">{title}</span>
      {options.map((option) => {
        return (
          <Radio
            key={option.id}
            id={option.id}
            name={option.name}
            optionType="button"
            buttonShape="circle"
            className="product-option-select__radio red danger"
            onChange={onChange}
            checked={isChecked(option.name, option.label)}
            disabled={checkDisable(option.name, option.label)}
          >
            {option.label}
          </Radio>
        );
      })}
    </div>
  );
};

ProductOptionSelect.propTypes = {
  title: PropTypes.string,
  options: PropTypes.array,
  className: PropTypes.string,
};

export default ProductOptionSelect;
