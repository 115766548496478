import * as React from 'react';

function SvgLink(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13.5 15.995a5.497 5.497 0 003.887-1.61l3.003-3.002a5.496 5.496 0 10-7.773-7.773L11.116 5.11a1.25 1.25 0 001.768 1.768l1.5-1.501a2.997 2.997 0 014.238 4.237l-3.003 3.002a2.997 2.997 0 01-4.237 0 1.25 1.25 0 00-1.767 1.769 5.497 5.497 0 003.886 1.609zm-3-7.99a5.497 5.497 0 00-3.887 1.61L3.61 12.617a5.496 5.496 0 107.773 7.774l1.501-1.501a1.25 1.25 0 00-1.768-1.768l-1.5 1.5a2.997 2.997 0 01-4.238-4.237l3.003-3.002a2.997 2.997 0 014.237 0 1.25 1.25 0 001.767-1.768 5.497 5.497 0 00-3.886-1.61z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgLink;
