import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import PageHeader from '../../components/PageHeader';
import Button from '../../components/Button';
import { Dialog } from '../../components/Modal';
import API from '../../contexts/api';

const Operators = () => {
  const [loading, setLoading] = useState(false);
  const [operators, setOperators] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogDeleteOperator, setDialogDeleteOperator] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const fetchOperators = await API.get(`user/list`);
      setOperators(fetchOperators.data);
    };
    fetch();
  }, []);

  const handleClickDialogDelete = (id) => {
    setLoading(true);
    setShowDialog(true);
    setDialogDeleteOperator(id);
  };

  const handleClickDialogCancel = () => {
    setLoading(false);
    setShowDialog(false);
    setDialogDeleteOperator(null);
  };

  const handleDeleteOperator = async (id) => {
    await API.delete(`user/operator/${id}`);
    const fetchOperators = await API.get(`user/list`);
    setOperators(fetchOperators.data);
    setLoading(false);
    setShowDialog(false);
    setDialogDeleteOperator(null);
  };

  return (
    <div className="page-operators">
      <Helmet>
        <title>Operators</title>
      </Helmet>
      <PageHeader title="Operators" />
      <div className="page-operators__content">
        <div className="page-operators__section">
          <div className="content-grid">
            {operators.map((operator, index) => (
              <div key={index} className="content-grid__item">
                <span className="content-grid__item-name">{operator.fullName}</span>
                <div className="content-grid__item-actions">
                  <Button
                    href={`/operators/${operator.id}`}
                    theme="warning"
                    size="xsmall"
                    disabled={loading}
                  >
                    Update
                  </Button>
                  <Button
                    theme="danger"
                    size="xsmall"
                    disabled={loading}
                    onClick={() => {
                      handleClickDialogDelete(operator.id);
                    }}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="page-operators__actions">
          <Button href="/operators/add" theme="primary" loading={loading} disabled={loading}>
            Add Operator
          </Button>
        </div>
      </div>
      <Dialog
        theme="danger"
        show={showDialog}
        setShow={handleClickDialogCancel}
        icon="production"
        title={`Do you want to delete this operator?`}
        actions={
          <>
            <Button theme="white" onClick={handleClickDialogCancel}>
              Cancel
            </Button>
            <Button theme="danger" onClick={() => handleDeleteOperator(dialogDeleteOperator)}>
              Confirm
            </Button>
          </>
        }
      />
    </div>
  );
};

Operators.propTypes = {
  children: PropTypes.any,
};

export default Operators;
