import * as React from 'react';

function SvgAngleDown(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M21.56 7.412A1.558 1.558 0 0020.497 7c-.399 0-.781.148-1.063.412l-7.443 6.962-7.443-6.962a1.56 1.56 0 00-1.057-.395 1.557 1.557 0 00-1.05.412c-.28.261-.438.614-.44.983a1.36 1.36 0 00.421.989l8.506 7.957c.282.263.664.412 1.063.412.399 0 .781-.149 1.063-.412L21.56 9.4c.282-.264.44-.622.44-.995 0-.372-.158-.73-.44-.994z"
      />
    </svg>
  );
}

export default SvgAngleDown;
