import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ children }) => {
  const user = useSelector((state) => state.user);
  if (!user || !user.accessToken) {
    return <Navigate to={'/login'} replace />;
  }
  return children || <Outlet />;
};

export default PrivateRoute;
