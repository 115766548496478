import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Col = ({ children, col, start, className, ...otherProps }) => {
  return (
    <div
      className={classNames(
        col ? `grid__col-${col}` : 'grid__col',
        start ? `grid__col-start-${start}` : null,
        className,
      )}
      {...otherProps}
    >
      {children}
    </div>
  );
};

Col.propTypes = {
  children: PropTypes.any,
  col: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  start: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  className: PropTypes.string,
};

export default Col;
