import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Col from './Col';

const Grid = ({ children, className, ...otherProps }) => {
  return (
    <div className={classNames('grid', className)} {...otherProps}>
      {children}
    </div>
  );
};

Grid.propTypes = {
  children: PropTypes.oneOfType([PropTypes.oneOf(['Col']), PropTypes.object, PropTypes.array]),
  className: PropTypes.string,
};

Grid.Col = Col;

export default Grid;
