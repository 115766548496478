import React from 'react';
import PropTypes from 'prop-types';

const ResetPassword = () => {
  return <div>ResetPassword</div>;
};

ResetPassword.propTypes = {
  children: PropTypes.any,
};

export default ResetPassword;
