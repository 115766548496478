import Loadable from 'react-loadable';

import Loading from '../../components/Loading';

import Layout from './Layout';

const LoadableLayout = Loadable({
  loader: () => Promise.resolve(Layout),
  loading: Loading,
});

export default LoadableLayout;
