import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Analytics from './Icons/Analytics';
import AngleDown from './Icons/AngleDown';
import Bell from './Icons/Bell';
import CategoryB from './Icons/CategoryB';
import Calendar from './Icons/Calendar';
import CheckCircle from './Icons/CheckCircle';
import Download from './Icons/Download';
import ExclamationCircle from './Icons/ExclamationCircle';
import ExclamationTriangle from './Icons/ExclamationTriangle';
import Facebook from './Icons/Facebook';
import File from './Icons/File';
import FileSuccess from './Icons/FileSuccess';
import Gear from './Icons/Gear';
import GooglePlus from './Icons/GooglePlus';
import Instagram from './Icons/Instagram';
import Link from './Icons/Link';
import Product from './Icons/Product';
import Production from './Icons/Production';
import Recycle from './Icons/Recycle';
import Schedule from './Icons/Schedule';
import Search from './Icons/Search';
import Setting from './Icons/Setting';
import Shipped from './Icons/Shipped';
import Shipping from './Icons/Shipping';
import Stock from './Icons/Stock';
import Twitter from './Icons/Twitter';
import Times from './Icons/Times';
import TimesCircle from './Icons/TimesCircle';
import Trash from './Icons/Trash';
import TrashAlt from './Icons/TrashAlt';
import User from './Icons/User';
import Users from './Icons/Users';
import Whatsapp from './Icons/Whatsapp';
import Youtube from './Icons/Youtube';

const Icon = ({ name, className, ...otherProps }) => {
  if (name === 'analytics') {
    return <Analytics className={classNames('icon', className)} {...otherProps} />;
  }

  if (name === 'angle-down') {
    return <AngleDown className={classNames('icon', className)} {...otherProps} />;
  }

  if (name === 'bell') {
    return <Bell className={classNames('icon', className)} {...otherProps} />;
  }

  if (name === 'category-b') {
    return <CategoryB className={classNames('icon', className)} {...otherProps} />;
  }

  if (name === 'calendar') {
    return <Calendar className={classNames('icon', className)} {...otherProps} />;
  }

  if (name === 'check-circle') {
    return <CheckCircle className={classNames('icon', className)} {...otherProps} />;
  }

  if (name === 'download') {
    return <Download className={classNames('icon', className)} {...otherProps} />;
  }

  if (name === 'file') {
    return <File className={classNames('icon', className)} {...otherProps} />;
  }

  if (name === 'file-success') {
    return <FileSuccess className={classNames('icon', className)} {...otherProps} />;
  }

  if (name === 'exclamation-circle') {
    return <ExclamationCircle className={classNames('icon', className)} {...otherProps} />;
  }

  if (name === 'exclamation-triangle') {
    return <ExclamationTriangle className={classNames('icon', className)} {...otherProps} />;
  }

  if (name === 'facebook') {
    return <Facebook className={classNames('icon', className)} {...otherProps} />;
  }

  if (name === 'gear') {
    return <Gear className={classNames('icon', className)} {...otherProps} />;
  }

  if (name === 'google-plus') {
    return <GooglePlus className={classNames('icon', className)} {...otherProps} />;
  }

  if (name === 'instagram') {
    return <Instagram className={classNames('icon', className)} {...otherProps} />;
  }

  if (name === 'link') {
    return <Link className={classNames('icon', className)} {...otherProps} />;
  }

  if (name === 'product') {
    return <Product className={classNames('icon', className)} {...otherProps} />;
  }

  if (name === 'production') {
    return <Production className={classNames('icon', className)} {...otherProps} />;
  }

  if (name === 'recycle') {
    return <Recycle className={classNames('icon', className)} {...otherProps} />;
  }

  if (name === 'schedule') {
    return <Schedule className={classNames('icon', className)} {...otherProps} />;
  }

  if (name === 'search') {
    return <Search className={classNames('icon', className)} {...otherProps} />;
  }

  if (name === 'setting') {
    return <Setting className={classNames('icon', className)} {...otherProps} />;
  }

  if (name === 'shipped') {
    return <Shipped className={classNames('icon', className)} {...otherProps} />;
  }

  if (name === 'shipping') {
    return <Shipping className={classNames('icon', className)} {...otherProps} />;
  }

  if (name === 'stock') {
    return <Stock className={classNames('icon', className)} {...otherProps} />;
  }

  if (name === 'twitter') {
    return <Twitter className={classNames('icon', className)} {...otherProps} />;
  }

  if (name === 'times') {
    return <Times className={classNames('icon', className)} {...otherProps} />;
  }

  if (name === 'times-circle') {
    return <TimesCircle className={classNames('icon', className)} {...otherProps} />;
  }

  if (name === 'trash') {
    return <Trash className={classNames('icon', className)} {...otherProps} />;
  }

  if (name === 'trash-alt') {
    return <TrashAlt className={classNames('icon', className)} {...otherProps} />;
  }

  if (name === 'user') {
    return <User className={classNames('icon', className)} {...otherProps} />;
  }

  if (name === 'users') {
    return <Users className={classNames('icon', className)} {...otherProps} />;
  }

  if (name === 'whatsapp') {
    return <Whatsapp className={classNames('icon', className)} {...otherProps} />;
  }

  if (name === 'youtube') {
    return <Youtube className={classNames('icon', className)} {...otherProps} />;
  }

  return false;
};

Icon.propTypes = {
  name: PropTypes.oneOf([
    'analytics',
    'angle-down',
    'bell',
    'category-b',
    'calendar',
    'check-circle',
    'download',
    'exclamation-circle',
    'exclamation-triangle',
    'facebook',
    'file',
    'file-success',
    'google-plus',
    'instagram',
    'link',
    'product',
    'production',
    'recycle',
    'search',
    'setting',
    'shipped',
    'shipping',
    'stock',
    'twitter',
    'times',
    'times-circle',
    'trash',
    'trash-alt',
    'user',
    'users',
    'whatsapp',
    'youtube',
  ]),
  className: PropTypes.string,
};

export default Icon;
