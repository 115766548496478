import React, { useEffect, useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../Icon';

const Modal = ({ children, size, show, setShow, showCloseButton, className }) => {
  const handleUserKeyPress = useCallback(
    (event) => {
      const { keyCode } = event;
      if (keyCode === 27 && show) {
        setShow(false);
      }
    },
    [setShow, show],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  return (
    <div
      className={classNames(
        'modal',
        `modal--size-${size}`,
        className,
        { 'has-close-button': showCloseButton },
        {
          'is-active': show,
        },
      )}
    >
      <div aria-hidden className="modal__background" onClick={() => setShow(false)} />
      <div className="modal__wrapper">
        {showCloseButton ? (
          <button type="button" className="modal__close-button" onClick={() => setShow(false)}>
            <Icon name="times" />
          </button>
        ) : null}

        <div className="modal__content">{children}</div>
      </div>
    </div>
  );
};

Modal.defaultProps = {
  size: 'medium',
  show: false,
  showCloseButton: false,
};

Modal.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  showCloseButton: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default Modal;
