import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Logo } from '../Svg';
import SidebarMenu from '../SidebarMenu';

const Sidebar = ({ className }) => {
  return (
    <nav className={classNames('sidebar', className)}>
      <div className="logo sidebar__logo-wrapper">
        <Logo className="sidebar__logo" />
      </div>
      <SidebarMenu className="sidebar__menu" />
    </nav>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
};

export default Sidebar;
