import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { TextField } from '@mui/material';
import PageHeader from '../../components/PageHeader';
import { Field } from '../../components/Form';
import { Grid } from '../../components/Grid';
import API from '../../contexts/api';

const AnalyticsPage = ({ className }) => {
  const location = useLocation();
  const [searchParam] = useSearchParams();
  const navigate = useNavigate();
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [data, setData] = useState([]);

  const handleChange = () => {
    const startDate = dateFrom
      ? DateTime.fromJSDate(new Date(dateFrom)).toFormat('yyyy-MM-dd')
      : '';
    const endDate = dateTo ? DateTime.fromJSDate(new Date(dateTo)).toFormat('yyyy-MM-dd') : '';
    const url = `/order/analytics?from=${startDate}&to=${endDate}`;

    API.get(url).then((r) => {
      console.log(r.data);
      setData(r.data);
    });
  };

  const handleChangeDateFrom = (e) => {
    const date = e.target.value
      ? DateTime.fromJSDate(new Date(e.target.value)).toFormat('yyyy-MM-dd')
      : '';
    setDateFrom(date);
  };

  const handleChangeDateTo = (e) => {
    const date = e.target.value
      ? DateTime.fromJSDate(new Date(e.target.value)).toFormat('yyyy-MM-dd')
      : '';
    setDateTo(date);
  };

  useEffect(() => {
    // if ((dateFrom && dateTo) || (!dateFrom && !dateTo)) {
    handleChange();
    // }
  }, [dateFrom, dateTo]);

  useEffect(() => {}, [location.pathname, navigate, searchParam]);

  return (
    <div className={classNames('page-production', className)}>
      <Helmet>
        <title>Analytics</title>
      </Helmet>
      <PageHeader title="Analytics" />
      <div className="page-production__content">
        <Grid>
          <Grid.Col col={2}>
            <Field label="Date From" className="form__field grid__col-6 grid__col-start-1">
              <TextField
                inputProps={{ className: 'input' }}
                id="dateFrom"
                type="date"
                sx={{ width: 220 }}
                onChange={handleChangeDateFrom}
                InputLabelProps={{}}
              />
            </Field>
          </Grid.Col>
          <Grid.Col col={2} style={{ margin: '0 0 0 20px' }}>
            <Field label="Date To" className="form__field grid__col-6 grid__col-start-4">
              <TextField
                inputProps={{ className: 'input' }}
                id="dateTo"
                type="date"
                sx={{ width: 220 }}
                onChange={handleChangeDateTo}
                InputLabelProps={{}}
              />
            </Field>
          </Grid.Col>
        </Grid>
        <table className="notification-table page-analytics__table">
          <>
            <thead>
              <tr>
                <th>Data</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {data ? (
                data.map((cell, index) => {
                  return (
                    <tr key={index} className={classNames(`is-default`)}>
                      {cell.title ? (
                        <>
                          <td role="gridcell" style={{ textAlign: 'left' }}>
                            {cell.title}
                          </td>
                          <td role="gridcell" style={{ textAlign: 'center' }}>
                            {cell.total.toString()}
                          </td>
                        </>
                      ) : (
                        <>
                          <td role="gridcell" style={{ textAlign: 'left' }}>
                            {'‎'}
                          </td>
                          <td role="gridcell" style={{ textAlign: 'center' }}>
                            {'‎'}
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })
              ) : (
                <></>
              )}
            </tbody>
          </>
        </table>
      </div>
    </div>
  );
};

AnalyticsPage.propTypes = {
  className: PropTypes.string,
};

export default AnalyticsPage;
