import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useFormik } from 'formik';
import { DateTime } from 'luxon';
import jwtDecode from 'jwt-decode';
import { Autocomplete, TextField } from '@mui/material';
import Icon from '../../components/Icon';
import { Grid } from '../../components/Grid';
import { Field, Input } from '../../components/Form';
import Tag from '../../components/Tag';
import Button from '../../components/Button';
import API from '../../contexts/api';
import constants, { Roles } from '../../utils/constants';
import Loading from '../../components/Loading';
// import AutoComplete from '../../components/AutoComplete/AutoComplete';

// TODO: Connect API
// const DATA_ADDRESSES = ['Value 1', 'Value 2', 'Value 3', 'Value 4', 'Value 5'];

const initOrderDetail = {
  product: {
    model: {},
    size: {},
    finish: {},
    logo: {},
    productTag: [],
    contact: {},
  },
};
const OrderDetail = ({ className, loading, id, toggle, setShowDialogDelete, onSubmit }) => {
  const [orderDetail, setOrderDetail] = useState(initOrderDetail);
  const [tags, setTags] = useState([]);
  const [listCustomer, setListCustomer] = useState([]);
  const [currentTag, setCurrentTag] = useState('');
  const [role, setRole] = useState({});

  useEffect(() => {
    if (id) {
      API.get(`/order/detail/${id}`)
        .then((rs) => {
          setOrderDetail(rs.data.order);
          setTags(
            rs.data.order.product.productTag.map((tag) => {
              return tag.tag.name;
            }),
          );
        })
        .catch((reason) => console.log(reason));
    }

    const token = localStorage.getItem('access_token');
    if (token) {
      const decodedToken = jwtDecode(token);
      const roleUser = decodedToken.role;
      setRole(roleUser);
    }
  }, [id, toggle]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: orderDetail.product.contact ? orderDetail.product.contact.id : '',
      type: orderDetail.type ? orderDetail.type : '',
      model: orderDetail.product?.productModel ? orderDetail.product?.productModel?.model.name : '',
      size: orderDetail.product?.productModel ? orderDetail.product?.productModel?.size.name : '',
      finish: orderDetail.product?.productModel
        ? orderDetail.product?.productModel?.finish.name
        : '',
      logo: orderDetail.product?.productModel ? orderDetail.product?.productModel?.logo.name : '',
      trackingLink: orderDetail.product ? orderDetail.product.trackingLink : '',
      shopifyOrderId: orderDetail.product.shopifyOrderId ? orderDetail.product.shopifyOrderId : '',
      firstName: orderDetail.product.contact ? orderDetail.product.contact.firstName : '',
      lastName: orderDetail.product.contact ? orderDetail.product.contact.lastName : '',
      company: orderDetail.product.contact ? orderDetail.product.contact.company : '',
      address1: orderDetail.product.contact ? orderDetail.product.contact.address1 : '',
      address2: orderDetail.product.contact ? orderDetail.product.contact.address2 : '',
      postCode: orderDetail.product.contact ? orderDetail.product.contact.postCode : '',
      city: orderDetail.product.contact ? orderDetail.product.contact.city : '',
      countryRegion: orderDetail.product.contact ? orderDetail.product.contact.countryRegion : '',
      phone: orderDetail.product.contact ? orderDetail.product.contact.phone : '',
      tags: orderDetail.product.productTag.map((tag) => tag.tag.name),
      orderDate: orderDetail.product
        ? DateTime.fromJSDate(new Date(orderDetail.product.orderDate)).toFormat('yyyy-MM-dd HH:mm')
        : DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd HH:mm'),
    },
  });

  useEffect(() => {
    const delay = setTimeout(() => {
      API.get(`/contact/list/${formik.values.firstName}`)
        .then((value) => {
          setListCustomer(value.data);
        })
        .catch((reason) => {
          console.log(reason);
        });
    }, 500);

    return () => clearTimeout(delay);
  }, [formik.values.firstName]);

  useEffect(() => {
    const delay = setTimeout(() => {
      API.get(`/contact/list/${formik.values.lastName}`)
        .then((value) => {
          setListCustomer(value.data);
        })
        .catch((reason) => {
          console.log(reason);
        });
    }, 500);

    return () => clearTimeout(delay);
  }, [formik.values.lastName]);

  const deleteTag = (index) => {
    setTags(
      [...tags].filter((value, i) => {
        return i !== index;
      }),
    );
    formik.setFieldValue(
      'tags',
      [...tags].filter((value, i) => {
        return i !== index;
      }),
    );
  };

  const addTag = (e) => {
    if (e.charCode === 13 && currentTag) {
      setTags([...tags, e.target.value]);
      setCurrentTag('');
      formik.setFieldValue('tags', [...tags, e.target.value]);
    }
  };

  const changeDate = (e) => {
    formik.setFieldValue('orderDate', e.target.value);
  };

  const fillDataToForm = (e, value) => {
    formik.setFieldValue('firstName', value.firstName);
    formik.setFieldValue('lastName', value.lastName);
    formik.setFieldValue('company', value.company);
    formik.setFieldValue('address1', value.address1);
    formik.setFieldValue('address2', value.address2);
    formik.setFieldValue('postCode', value.postCode);
    formik.setFieldValue('city', value.city);
    formik.setFieldValue('countryRegion', value.country);
    formik.setFieldValue('phone', value.phone);
    formik.setFieldValue('countryCode', value.countryCode);
    formik.setFieldValue('id', value.id);
  };

  const handleUpdateOrder = () => {
    const body = {
      type: formik.values.type,
      shopifyOrder: formik.values.shopifyOrderId,
      orderDate: formik.values.orderDate,
      contact: {
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        company: formik.values.company,
        address1: formik.values.address1,
        address2: formik.values.address2,
        postCode: formik.values.postCode,
        city: formik.values.city,
        countryRegion: formik.values.countryRegion,
        phone: formik.values.phone,
        customerId: formik.values.id,
      },
      tags: formik.values.tags,
    };
    onSubmit(body);
  };

  const isStockOrCategoryB = () => {
    return (
      orderDetail.product.type === constants.ProductType.Stock ||
      orderDetail.product.category === constants.ProductType.CategoryB
    );
  };
  return (
    <div className={classNames('product-drawer', className)}>
      {orderDetail.product?.type ? (
        <>
          <div className="product-drawer__header">
            <Grid>
              <Grid.Col col={4}>
                <span className="product-drawer__id">{orderDetail.product.id}</span>
              </Grid.Col>
              <Grid.Col col={4}>
                <span className="product-drawer__header-title">
                  <Icon name="link" className="sidebar-menu__link-icon" />
                  Production
                </span>
              </Grid.Col>
            </Grid>
          </div>
          <div className="product-drawer__body">
            <div className="product-drawer__section">
              <span className="product-drawer__prop">
                <span className="product-drawer__prop-label">Model:</span>
                <span className="product-drawer__prop-value">{formik.values.model}</span>
              </span>
              <span className="product-drawer__prop">
                <span className="product-drawer__prop-label">Size:</span>
                <span className="product-drawer__prop-value">{formik.values.size}</span>
              </span>
              <span className="product-drawer__prop">
                <span className="product-drawer__prop-label">Finish:</span>
                <span className="product-drawer__prop-value">{formik.values.finish}</span>
              </span>
              <span className="product-drawer__prop">
                <span className="product-drawer__prop-label">Logos:</span>
                <span className="product-drawer__prop-value">{formik.values.logo}</span>
              </span>
            </div>
            <div className="product-drawer__section">
              <Grid>
                {!isStockOrCategoryB() ? (
                  <Grid.Col col={8} className="product-drawer__order-details">
                    <span className="product-drawer__order-label">Shopify Order Number</span>
                    {/* <span className="product-drawer__order-value">#14344</span> */}
                    <Input
                      id="shopifyOrderId"
                      name="shopifyOrderId"
                      placeholder="Your ID"
                      value={formik.values.shopifyOrderId}
                      onChange={formik.handleChange}
                    />
                  </Grid.Col>
                ) : null}

                <Grid.Col col={4}>
                  <Field
                    label="Order creation date"
                    className="form__field grid__col-6 grid__col-start-1"
                  >
                    <TextField
                      disabled={true}
                      inputProps={{ className: 'input' }}
                      id="date"
                      type="datetime-local"
                      value={formik.values.orderDate}
                      sx={{ width: 220 }}
                      onChange={changeDate}
                      InputLabelProps={{}}
                    />
                  </Field>
                </Grid.Col>
              </Grid>

              {!isStockOrCategoryB() ? (
                <>
                  <h2 className="product-drawer__section-title">Contact Information</h2>

                  <div className="grid">
                    <Field
                      htmlFor="FirstName"
                      label="First Name"
                      className="form__field grid__col-6 grid__col-start-1"
                    >
                      <Autocomplete
                        freeSolo
                        id="free-solo-2-demo"
                        disableClearable
                        onChange={(e, value) => {
                          fillDataToForm(e, value);
                        }}
                        value={formik.values.firstName || ''}
                        const="true"
                        renderOption={(props, option, index) => {
                          const key = `listItem-${index}-${option.id}`;
                          return (
                            <li {...props} key={key}>
                              {option.firstName}
                            </li>
                          );
                        }}
                        options={listCustomer.map((option) => ({
                          id: `${option.id}`,
                          firstName: `${option.firstName}`,
                          label: `${option.firstName}`,
                          lastName: `${option.lastName}`,
                          company: `${option.company}`,
                          email: `${option.email}`,
                          address1: `${option.address1}`,
                          address2: `${option.address2}`,
                          postCode: `${option.postCode}`,
                          city: `${option.city}`,
                          country: `${option.country}`,
                          phone: `${option.phone}`,
                          countryCode: `${option.countryCode}`,
                        }))}
                        renderInput={(params) => (
                          <>
                            <TextField
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                type: 'search',
                                className: 'input',
                              }}
                              id="FirstName"
                              name="firstName"
                              value={formik.values.firstName}
                              onChange={formik.handleChange}
                            />
                          </>
                        )}
                      />
                      {/* <AutoComplete
      options={listCustomer}
      id="SelectAddress"
      name="selectAddress"
      placeholder="Select Address"
      showOnFocus={true}
    /> */}
                    </Field>
                    <Field htmlFor="LastName" label="Last Name" className="form__field grid__col-6">
                      <Autocomplete
                        freeSolo
                        id="free-solo-2-demo"
                        disableClearable
                        onChange={(e, value) => {
                          fillDataToForm(e, value);
                        }}
                        value={formik.values.lastName || ''}
                        const="true"
                        options={listCustomer.map((option) => ({
                          id: `${option.id}`,
                          firstName: `${option.firstName}`,
                          label: `${option.lastName}`,
                          lastName: `${option.lastName}`,
                          company: `${option.company}`,
                          email: `${option.email}`,
                          address1: `${option.address1}`,
                          address2: `${option.address2}`,
                          postCode: `${option.postCode}`,
                          city: `${option.city}`,
                          country: `${option.country}`,
                          phone: `${option.phone}`,
                          countryCode: `${option.countryCode}`,
                        }))}
                        renderOption={(props, option, index) => {
                          const key = `listItem-${index}-${option.id}`;
                          return (
                            <li {...props} key={key}>
                              {option.lastName}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="input"
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              type: 'search',
                              className: 'input',
                            }}
                            id="LastName"
                            name="lastName"
                            value={formik.values.lastName}
                            onChange={formik.handleChange}
                          />
                        )}
                      />
                    </Field>
                    <Field htmlFor="Company" label="Company" className="form__field grid__col-12">
                      <Input
                        id="Company"
                        name="company"
                        placeholder="Your Company"
                        value={formik.values.company}
                        onChange={formik.handleChange}
                      />
                    </Field>
                    <Field htmlFor="Address1" label="Address" className="form__field grid__col-12">
                      <Input
                        id="address1"
                        name="address1"
                        placeholder="Your Address"
                        value={formik.values.address1}
                        onChange={formik.handleChange}
                      />
                    </Field>
                    <Field
                      htmlFor="Apartment"
                      label="Apartment, suite, etc."
                      className="form__field grid__col-12"
                    >
                      <Input
                        id="address2"
                        name="address2"
                        placeholder="Your Apartment, suite, etc."
                        value={formik.values.address2}
                        onChange={formik.handleChange}
                      />
                    </Field>
                    <Field htmlFor="PostCode" label="Post Code" className="form__field grid__col-6">
                      <Input
                        id="PostCode"
                        name="postCode"
                        placeholder="Your Post Code"
                        value={formik.values.postCode}
                        onChange={formik.handleChange}
                      />
                    </Field>
                    <Field htmlFor="City" label="City" className="form__field grid__col-6">
                      <Input
                        id="City"
                        name="city"
                        placeholder="Your City"
                        value={formik.values.city}
                        onChange={formik.handleChange}
                      />
                    </Field>
                    <Field
                      htmlFor="countryRegion"
                      label="Country/Region"
                      className="form__field grid__col-6"
                    >
                      <Input
                        id="countryRegion"
                        name="countryRegion"
                        placeholder="Your Country"
                        value={formik.values.countryRegion}
                        onChange={formik.handleChange}
                      />
                    </Field>
                    <Field htmlFor="Phone" label="Phone" className="form__field grid__col-6">
                      <Input
                        id="Phone"
                        name="phone"
                        placeholder="Your Phone"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                      />
                    </Field>
                  </div>

                  <h2 className="product-drawer__section-title">Tags</h2>
                  <Field className="form__field grid__col-6">
                    <Input
                      id="currentTag"
                      name="currentTag"
                      placeholder="Enter Your Tags"
                      onKeyPress={addTag}
                      value={currentTag}
                      onChange={(e) => setCurrentTag(e.target.value)}
                    />
                  </Field>
                  <div className="product-drawer__tags">
                    {tags.map((tag, index) => (
                      <Tag
                        key={tag.id}
                        label={tag}
                        theme={'orange'}
                        closable={true}
                        onClick={() => deleteTag(index)}
                        className="product-drawer__tag"
                      />
                    ))}
                  </div>
                </>
              ) : null}
            </div>
          </div>
          <div className="product-drawer__footer product-drawer__actions">
            {(orderDetail.product.type === constants.ProductType.Customer &&
              orderDetail.product.statusDetail.name !== constants.ProductStatus.NewOrder) ||
            ((orderDetail.product.type === constants.ProductType.Prototype ||
              orderDetail.product.type === constants.ProductType.Stock) &&
              orderDetail.product.statusDetail.name === constants.ProductStatus.NewOrder) ? (
              <Button
                disabled={role !== Roles.ADMIN}
                theme="white"
                onClick={() => setShowDialogDelete(true)}
              >
                Delete Order
              </Button>
            ) : null}

            <Button
              disabled={role !== Roles.ADMIN}
              theme="primary"
              className="product-drawer__submit-button"
              onClick={() => handleUpdateOrder()}
              loading={loading}
            >
              Update Order
            </Button>
          </div>
        </>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <Loading />
        </div>
      )}
    </div>
  );
};

OrderDetail.defaultProps = {
  loading: false,
};

OrderDetail.propTypes = {
  className: PropTypes.string,
  onDelete: PropTypes.func,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
};

export default OrderDetail;
