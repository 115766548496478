import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import PageHeader from '../../components/PageHeader';
import { Field, Input } from '../../components/Form';
import Button from '../../components/Button';

// TODO: Connect API
const PRODUCTS = [
  { id: 1, model: 'Comet V3', size: '4"1', finish: 'Black', logo: 'Black' },
  { id: 2, model: 'Glide', size: '4"1', finish: 'Black', logo: 'Black' },
  { id: 3, model: 'Glide V2', size: '4"1', finish: 'Black', logo: 'Black' },
  { id: 4, model: 'Koster Pro', size: '4"1', finish: 'Black', logo: 'Black' },
  { id: 5, model: 'Slate', size: '4"1', finish: 'Black', logo: 'Black' },
  { id: 6, model: 'Slate SW', size: '4"1', finish: 'Black', logo: 'Black' },
  { id: 7, model: 'Trix', size: '4"1', finish: 'Black', logo: 'Black' },
];

const Product = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);

  const fetchProduct = () => {
    const filteredProduct = PRODUCTS.find((prod) => prod.id === Number(id));
    setProduct(filteredProduct);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setProduct({ ...product, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(product);
  };

  useEffect(() => {
    if (!product) {
      fetchProduct();
    }
  });

  return (
    <div className="page-products">
      <Helmet>
        <title>Product</title>
      </Helmet>
      <PageHeader title="Product" />
      <div className="page-products__content">
        {product ? (
          <form className="form page-products__form">
            <div className="page-products__section">
              <div className="grid">
                <Field
                  htmlFor="model"
                  label="Model"
                  className="form__field grid__col-6 grid__col-start-1"
                >
                  <Input
                    id="model"
                    name="model"
                    value={product.model}
                    onChange={handleInputChange}
                    placeholder="Model of board"
                  />
                </Field>
                <Field
                  htmlFor="size"
                  label="Size"
                  className="form__field grid__col-6 grid__col-start-1"
                >
                  <Input
                    id="size"
                    name="size"
                    placeholder="Size of Board"
                    value={product.size}
                    onChange={handleInputChange}
                  />
                </Field>
                <Field
                  htmlFor="finish"
                  label="Finish"
                  className="form__field grid__col-6 grid__col-start-1"
                >
                  <Input
                    id="finish"
                    name="finish"
                    placeholder="Finish of Board"
                    value={product.finish}
                    onChange={handleInputChange}
                  />
                </Field>
                <Field
                  htmlFor="logo"
                  label="Logos"
                  className="form__field grid__col-6 grid__col-start-1"
                >
                  <Input
                    id="logo"
                    name="logo"
                    placeholder="Logos of Board"
                    value={product.logo}
                    onChange={handleInputChange}
                  />
                </Field>
              </div>
            </div>
            <div className="page-products__actions">
              <Button href="/products" theme="primary-outline">
                Cancel
              </Button>
              <Button type="submit" theme="primary" onClick={handleSubmit}>
                Update Product
              </Button>
            </div>
          </form>
        ) : null}
      </div>
    </div>
  );
};

Product.propTypes = {
  children: PropTypes.any,
};

export default Product;
