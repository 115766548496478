import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import PageHeader from '../../components/PageHeader';
import { Field, Input, Checkbox } from '../../components/Form';
import Button from '../../components/Button';
import API from '../../contexts/api';
import { Grid } from '../../components/Grid';

const OperatorSchema = Yup.object().shape({
  name: Yup.string().required('Name is required!'),
  statusKeys: Yup.array().min(1, 'Select at least 1 item'),
});

const Operator = () => {
  const { id } = useParams();
  const [operator, setOperator] = useState(null);
  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [status, setStatus] = useState();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: operator ? operator.fullName : null,
      statusKeys: selectedStatus,
    },
    validationSchema: OperatorSchema,
    onSubmit: async (values) => {
      const bodyData = {
        name: values.name,
        statusKey: values.statusKeys,
      };
      try {
        await API.put(`user/operator/${id}`, bodyData);
        navigate(`/operators`);
      } catch (error) {
        toast(`${error.response.data.message}`, { type: 'error' });
      }
    },
  });

  const fetchData = async () => {
    const response = await API.get(`user/operator/detail/${id}`);
    setOperator(response.data);
    // get selected status
    setSelectedStatus(
      response.data.userStatus.map((item) => {
        return item.status.key;
      }),
    );
  };

  const fetchStatus = () => {
    API.get('status/all')
      .then((rs) => {
        setStatus(
          rs.data.filter((item) => {
            return item.name !== 'Shipment' && item.name !== 'Completed';
          }),
        );
      })
      .catch((reason) => console.log(reason));
  };

  useEffect(() => {
    if (!operator) {
      fetchData();
    }
    if (!status) {
      fetchStatus();
    }
  });

  return (
    <div className="page-operators">
      <Helmet>
        <title>Operator</title>
      </Helmet>
      <PageHeader title="Operator" />
      <div className="page-operators__content">
        {operator ? (
          <form onSubmit={formik.handleSubmit} className="form page-operators__form">
            <div className="page-operators__section">
              <div className="grid">
                <Field
                  htmlFor="name"
                  label="Name"
                  className="form__field grid__col-6 grid__col-start-1"
                >
                  <Input id="name" value={formik.values.name} onChange={formik.handleChange} />
                  {formik.errors.name ? (
                    <p className="field-text is-invalid">{formik.errors.name}</p>
                  ) : null}
                </Field>
                <Field label="Status Access" className="form__field grid__col-12">
                  {status ? (
                    <Grid className="page-operators__status">
                      {status.map((item, index) => (
                        <Grid.Col key={index} col={4}>
                          <Checkbox
                            id={item.id}
                            name="statusKeys"
                            className="page-operators__status-checkbox"
                            value={item.key}
                            onChange={formik.handleChange}
                            checked={formik.values.statusKeys.includes(item.key)}
                          >
                            {item.name}
                          </Checkbox>
                        </Grid.Col>
                      ))}
                    </Grid>
                  ) : (
                    <h2>User not found</h2>
                  )}
                  {formik.errors.statusKeys ? (
                    <p className="field-text is-invalid">{formik.errors.statusKeys}</p>
                  ) : null}
                </Field>
              </div>
            </div>
            <div className="page-operators__actions">
              <Button href="/operators" theme="primary-outline">
                Cancel
              </Button>
              <Button type="submit" theme="primary">
                Update Operator
              </Button>
            </div>
          </form>
        ) : (
          <h2>User not found</h2>
        )}
      </div>
    </div>
  );
};

Operator.propTypes = {
  children: PropTypes.any,
};

export default Operator;
