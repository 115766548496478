import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import PageHeader from '../../../components/PageHeader';
import Button from '../../../components/Button';

const UserRole = () => {
  return (
    <div className="page-users">
      <Helmet>
        <title>Role</title>
      </Helmet>
      <PageHeader title="Role" />
      <div className="page-users__content">
        <form className="form page-users__form">
          <div className="page-users__section"></div>
          <div className="page-users__actions">
            <Button type="submit" theme="primary">
              Update
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

UserRole.propTypes = {
  children: PropTypes.any,
};

export default UserRole;
