import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import userSlice from './userSlice';
import utilSlice from './utilSlice';

const middlewares = [thunk];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

const store = configureStore({
  reducer: combineReducers({
    user: userSlice,
    util: utilSlice,
  }),
  middleware: middlewares,
});
export default store;
