import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import PageHeader from '../../components/PageHeader';
import Drawer from '../../components/Drawer';
import { Dialog } from '../../components/Modal';
import Tag from '../../components/Tag';
import Button from '../../components/Button';
import Pagination from '../../components/Pagination';
import OrderDetail from './OrderDetail';
import API from '../../contexts/api';
import { formatDate } from '../../utils/date';
import { activatedUpdateSideBar } from '../../contexts/utilSlice';
import { Input, InputGroup } from '../../components/Form';

const Tracking = () => {
  const [selectedId, setSelectedId] = useState();
  const [updateLoading, setUpdateLoading] = useState(false);
  const [orderDetailShow, setOrderDetailShow] = useState(false);
  const [updateOrderModalShow, setUpdateOrderModalShow] = useState(false);
  const [updateConfirmOrderLoading, setUpdateConfirmOrderLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showDialogDeleteOrder, setShowDialogDeleteOrder] = useState(false);
  const [bodyData, setBodyData] = useState(null);
  const dispatch = useDispatch();
  const orderDetailToggle = (id) => {
    setOrderDetailShow(!orderDetailShow);
    setSelectedId(id);
  };
  const [orders, setOrders] = useState([]);
  const [keyword, setKeyword] = useState('');

  const formik = useFormik({
    initialValues: {
      keyword: '',
    },
    onSubmit: (value) => {
      API.get(
        `/order/tracking-list/search?keyword=${value.keyword}&page=${currentPage}&limit=${process.env.REACT_APP_LIMIT_ITEM}`,
      )
        .then((rs) => {
          setOrders(rs.data.data);
          setCurrentPage(rs.data.currentPage);
          setTotalPages(rs.data.totalPages);
        })
        .catch((reason) => {
          console.log(reason);
        });
    },
  });

  const handleUpdateCancel = () => {
    setUpdateLoading(false);
    setShowDialogDeleteOrder(false);
  };

  const handleDeleteOrder = async () => {
    setUpdateLoading(true);
    try {
      await API.delete(`order/${selectedId}`);
    } catch (error) {
      toast(`${error.response.data.message}`, { type: 'error' });
    }
    setUpdateLoading(false);
    setShowDialogDeleteOrder(false);
    setOrderDetailShow(false);
  };

  useEffect(() => {
    API.get(
      `/order/tracking-list/search?keyword=${keyword}&page=${currentPage}&limit=${process.env.REACT_APP_LIMIT_ITEM}`,
    )
      .then((rs) => {
        setOrders(rs.data.data);
        setTotalPages(rs.data.totalPages);
        setCurrentPage(rs.data.currentPage);
      })
      .catch((reason) => {
        console.log(reason);
      });
    dispatch(activatedUpdateSideBar());
  }, [orderDetailShow, currentPage, showDialogDeleteOrder]);

  const handleUpdateOrder = (data) => {
    setBodyData(data);
    setUpdateLoading(true);
    setUpdateOrderModalShow(true);
  };

  const handleUpdateConfirmOrder = async () => {
    setUpdateConfirmOrderLoading(true);
    try {
      await API.put(`/order/${selectedId}`, bodyData);
    } catch (error) {
      toast(`${error.response.data.message}`, { type: 'error' });
    }
    setUpdateLoading(false);
    setUpdateOrderModalShow(false);
    setUpdateConfirmOrderLoading(false);
    setOrderDetailShow(!orderDetailShow);
  };

  const handleUpdateCancelOrder = () => {
    setUpdateLoading(false);
    setUpdateOrderModalShow(false);
  };

  useEffect(() => {
    if (!updateOrderModalShow) {
      setUpdateLoading(false);
    }
    dispatch(activatedUpdateSideBar());
  }, [updateOrderModalShow]);

  const handleChange = (e) => {
    formik.setFieldValue('keyword', e.target.value);
    setKeyword(e.target.value);
  };

  const handleSearch = (e) => {
    if (e.charCode === 13) {
      formik.handleSubmit();
    } else {
      const regex = new RegExp('[0-9a-zA-Z]', 'g');
      if (!regex.test(e.key)) {
        e.preventDefault();
      }
    }
  };

  return (
    <div className="page-stock">
      <Helmet>
        <title>Tracking Order</title>
      </Helmet>
      <PageHeader title="Tracking Order" />
      <div className="page-tracking__content">
        <InputGroup icon="search" className="page-tracking__search">
          <Input
            type="search"
            id="keyword"
            name="keyword"
            placeholder="Search by Shopify Order, Production ID, or Client's Name"
            onChange={handleChange}
            onKeyPress={handleSearch}
            value={formik.values.keyword}
          />
        </InputGroup>
        <table className="order-table page-tracking__table">
          <thead>
            <tr>
              <th>Type</th>
              <th>Prod ID</th>
              <th>Shopify</th>
              <th>Name</th>
              <th>Model</th>
              <th>Size</th>
              <th>Finish</th>
              <th>Box Type</th>
              <th>Logos</th>
              <th>Order date</th>
              <th>Status</th>
              <th>ETA</th>
              <th>Tags</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order, index) => (
              <tr key={index}>
                <td role="gridcell" onClick={() => orderDetailToggle(order.id)}>
                  {order.product.type}
                </td>
                <td role="gridcell" onClick={() => orderDetailToggle(order.id)}>
                  {order.product.id}
                </td>
                <td role="gridcell" onClick={() => orderDetailToggle(order.id)}>
                  {order.product.shopifyOrderId}
                </td>
                <td role="gridcell" onClick={() => orderDetailToggle(order.id)}>
                  {order.product.contact?.firstName} {order.product.contact?.lastName}
                </td>
                <td role="gridcell" onClick={() => orderDetailToggle(order.id)}>
                  {order.product?.productModel?.model.name}
                </td>
                <td role="gridcell" onClick={() => orderDetailToggle(order.id)}>
                  {order.product?.productModel?.size.name}
                </td>
                <td role="gridcell" onClick={() => orderDetailToggle(order.id)}>
                  {order.product?.productModel?.finish.name}
                </td>
                <td role="gridcell" onClick={() => orderDetailToggle(order.id)}>
                  {order.product?.productModel?.boxType.name}
                </td>
                <td role="gridcell" onClick={() => orderDetailToggle(order.id)}>
                  {order.product?.productModel?.logo.name}
                </td>
                <td role="gridcell" onClick={() => orderDetailToggle(order.id)}>
                  {formatDate(order.product.customerOrderDate)}
                </td>
                <td
                  role="gridcell"
                  onClick={() => orderDetailToggle(order.id)}
                  className="u-background-color-green-600 u-color-white"
                >
                  {order.product?.statusDetail?.qualityCheck &&
                    `Quality check ${order.product?.statusDetail?.name.toLowerCase()}`}
                  {order.product?.statusDetail?.weight &&
                    `Weight ${order.product?.statusDetail?.name.toLowerCase()}`}
                  {!order.product?.statusDetail?.qualityCheck &&
                    !order.product?.statusDetail?.weight &&
                    order.product?.statusDetail?.name}
                </td>
                <td role="gridcell" onClick={() => orderDetailToggle(order.id)}>
                  {formatDate(order.product.deliveryDate)}
                </td>
                <td>
                  {order.product.productTag.map((tag, tagIndex) => (
                    <Tag key={tagIndex} label={tag.tag.name} theme="orange" />
                  ))}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* // TODO: Connect API */}
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          threshold={2}
          onPageChange={(page) => setCurrentPage(page)}
          className="page-tracking__pagination"
        />
      </div>
      {orderDetailShow ? (
        <Drawer show={orderDetailShow} setShow={setOrderDetailShow}>
          <OrderDetail
            onSubmit={handleUpdateOrder}
            loading={updateLoading}
            id={selectedId}
            toggle={orderDetailShow}
            setShowDialogDelete={setShowDialogDeleteOrder}
          />
        </Drawer>
      ) : null}
      <Dialog
        theme="success"
        show={updateOrderModalShow}
        setShow={setUpdateOrderModalShow}
        icon="production"
        title="Update Order"
        text="Would you like to update this order?"
        actions={
          <>
            <Button theme="white" onClick={handleUpdateCancelOrder}>
              Cancel
            </Button>
            <Button
              theme="primary"
              onClick={handleUpdateConfirmOrder}
              loading={updateConfirmOrderLoading}
            >
              Confirm
            </Button>
          </>
        }
      />
      {/* delete order */}
      <Dialog
        theme="danger"
        show={showDialogDeleteOrder}
        setShow={setShowDialogDeleteOrder}
        icon="production"
        title={`Do you want to delete this order?`}
        actions={
          <>
            <Button theme="white" onClick={handleUpdateCancel}>
              Cancel
            </Button>
            <Button theme="danger" onClick={handleDeleteOrder} loading={updateLoading}>
              Confirm
            </Button>
          </>
        }
      />
    </div>
  );
};

export default Tracking;
