import * as React from 'react';

function SvgGear(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.544 15.94c.726.486 1.58.745 2.452.745a4.371 4.371 0 004.415-4.415 4.415 4.415 0 10-6.867 3.67zm1.316-6.407a2.877 2.877 0 011.136-.205 2.877 2.877 0 012.943 2.942 2.877 2.877 0 01-2.943 2.943 2.878 2.878 0 01-2.942-2.943 2.877 2.877 0 011.806-2.737z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M20.089 11.704v1.126l1.39 1.273a1.471 1.471 0 01.302 1.846l-1.677 2.943a1.471 1.471 0 01-1.273.736 1.471 1.471 0 01-.471-.074l-1.78-.603c-.312.205-.636.39-.971.552l-.376 1.854a1.471 1.471 0 01-1.471 1.184h-3.473a1.471 1.471 0 01-1.471-1.184l-.375-1.854a8.346 8.346 0 01-.964-.552l-1.788.603a1.47 1.47 0 01-.47.074 1.472 1.472 0 01-1.273-.736L2.21 15.95a1.471 1.471 0 01.302-1.876l1.39-1.236v-1.125l-1.39-1.273a1.471 1.471 0 01-.302-1.847L3.948 5.65a1.471 1.471 0 011.272-.735c.159-.011.318.004.471.044l1.751.633c.311-.206.636-.39.971-.552l.375-1.854A1.471 1.471 0 0110.26 2h3.443a1.471 1.471 0 011.471 1.185l.376 1.854c.333.162.655.346.963.552l1.788-.604c.152-.05.311-.075.471-.073a1.471 1.471 0 011.273.735l1.736 2.943a1.471 1.471 0 01-.28 1.876l-1.412 1.236zm-3.84 5.599l2.523.853 1.736-2.942-2-1.818a6.55 6.55 0 000-2.302l2-1.766-1.736-2.943-2.53.824a6.887 6.887 0 00-1.987-1.155l-.523-2.612H10.26l-.522 2.641A6.518 6.518 0 007.744 7.24L5.22 6.385 3.484 9.328l2.001 1.817a6.548 6.548 0 000 2.303l-2.001 1.766 1.736 2.942 2.531-.824a6.887 6.887 0 001.987 1.155l.522 2.612h3.472l.523-2.641a6.52 6.52 0 001.994-1.155z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgGear;
