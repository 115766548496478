import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FilterBarLink = ({
  children,
  url,
  count,
  isActive,
  onClick,
  className,
  productionData,
  ...otherProps
}) => {
  return (
    <Link
      to={url}
      className={classNames('filter-bar__list-link', { 'is-active': isActive }, className)}
      onClick={onClick}
      {...otherProps}
    >
      {children}
      {count && count > 0 ? (
        <>
          <span style={{ display: productionData ? 'none' : 'block' }} className="filter-bar__dot">
            •
          </span>
          <span
            style={{ display: productionData ? 'none' : 'block' }}
            className="filter-bar__badge"
          >
            {count}
          </span>
        </>
      ) : null}
    </Link>
  );
};

FilterBarLink.propTypes = {
  children: PropTypes.any,
  url: PropTypes.string,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default FilterBarLink;
