import * as React from 'react';

function SvgProduction(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M6.25 4A1.75 1.75 0 004.5 5.75v2.5c0 .966.784 1.75 1.75 1.75h2.5a1.75 1.75 0 001.75-1.75v-2.5A1.75 1.75 0 008.75 4h-2.5zm7.25 1.75c0-.966.784-1.75 1.75-1.75h2.5c.966 0 1.75.784 1.75 1.75v2.5A1.75 1.75 0 0117.75 10h-2.5a1.75 1.75 0 01-1.75-1.75v-2.5zm1.75-.25a.25.25 0 00-.25.25v2.5c0 .138.112.25.25.25h2.5a.25.25 0 00.25-.25v-2.5a.25.25 0 00-.25-.25h-2.5zM7 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm6.5-1.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM17 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM2 16.5A4.5 4.5 0 016.5 12h11a4.5 4.5 0 110 9h-11A4.5 4.5 0 012 16.5zm4.5-3a3 3 0 000 6h11a3 3 0 000-6h-11z"
      />
    </svg>
  );
}

export default SvgProduction;
