import Loadable from 'react-loadable';

import Loading from '../../components/Loading';

import Stock from './Stock';

const LoadableStock = Loadable({
  loader: () => Promise.resolve(Stock),
  loading: Loading,
});

export default LoadableStock;
