import * as React from 'react';

function SvgLogo(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={157}
      height={20}
      fill="none"
      viewBox="0 0 157 20"
      {...props}
    >
      <path
        fill="#FEFEFE"
        fillRule="evenodd"
        d="M90.615 19.73c-5.89 0-9.895-4.064-9.895-10.02 0-5.786 4.006-9.71 9.866-9.71 5.98 0 9.866 4.066 9.866 9.71 0 6.097-4.069 10.02-9.837 10.02zm-.181-16.455c-3.248 0-5.374 2.766-5.374 6.69 0 3.95 2.429 6.407 5.617 6.407 4.19 0 5.434-3.67 5.434-6.69 0-3.726-2.276-6.407-5.677-6.407zM0 3.67V.452h15.39V3.67H9.866v15.609H5.555V3.669H0zM32.785.452L25.438 19.28h4.341l1.489-3.924h7.284l1.489 3.924h4.432L37.096.452h-4.31zm2.094 4.262l2.55 7.565H32.33l2.549-7.565zM65.541.452L58.832 9.26V.452h-4.31V19.28h4.31V10.5l6.679 8.778h5.16l-7.74-9.682L70.276.452H65.54zM110.5 9.71c0 5.956 4.006 10.02 9.895 10.02 5.769 0 9.837-3.923 9.837-10.02 0-5.644-3.886-9.71-9.866-9.71-5.86 0-9.866 3.924-9.866 9.71zm4.34.254c0-3.923 2.126-6.69 5.374-6.69 3.4 0 5.677 2.682 5.677 6.408 0 3.02-1.245 6.69-5.434 6.69-3.188 0-5.617-2.456-5.617-6.408zm29.265-3.245l8.53 12.56h3.673V.453h-3.916l.091 12.475L144.165.453h-3.885v18.826h3.917l-.092-12.56z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgLogo;
