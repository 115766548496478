import * as React from 'react';

function SvgDownload(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M21.104 16.617a.898.898 0 00-.896.896V18.7c0 .426-.335.74-.761.74H4.553c-.403 0-.761-.337-.761-.74v-1.187a.898.898 0 00-.896-.896.898.898 0 00-.896.896V18.7c0 1.389 1.142 2.531 2.553 2.531h14.894c1.41 0 2.553-1.142 2.553-2.53v-1.188a.899.899 0 00-.896-.896z"
      />
      <path
        fill="currentColor"
        d="M11.227 16.729l.112.112h.045l.045.045h.067c.023 0 .023 0 .045.022.045.022.09.022.157.022h.29c.068 0 .113-.022.158-.022a.169.169 0 00.09-.045c.022 0 .021-.022.044-.022h.045l.09-.09 4.098-3.516c.358-.336.403-.896.09-1.254-.336-.358-.896-.403-1.255-.09l-2.575 2.218V3.829l-.022-.045c-.135-.47-.538-.784-.963-.784a.898.898 0 00-.896.896v10.19l-2.576-2.195a.859.859 0 00-.65-.224.846.846 0 00-.604.314.858.858 0 00-.224.65c.022.246.134.47.313.604l4.076 3.494z"
      />
    </svg>
  );
}

export default SvgDownload;
