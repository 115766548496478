import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';

import PageHeader from '../../components/PageHeader';
import Drawer from '../../components/Drawer';

import OrderDetail from './OrderDetail';
import OrderComments from './OrderComments';
import API from '../../contexts/api';
import { formatDate } from '../../utils/date';
import Pagination from '../../components/Pagination';
import constants from '../../utils/constants';

const Recycled = () => {
  const [orderDetailShow, setOrderDetailShow] = useState(false);
  const orderDetailToggle = () => setOrderDetailShow(!orderDetailShow);
  const [orderCommentsShow, setOrderCommentsShow] = useState(false);
  const orderCommentsToggle = () => setOrderCommentsShow(!orderCommentsShow);
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    API.get(`/order/list/recycles?page=${currentPage}&limit=${process.env.REACT_APP_LIMIT_ITEM}`)
      .then((rs) => {
        setOrders(rs.data.data);
        setCurrentPage(rs.data.currentPage);
        setTotalPages(rs.data.totalPages);
      })
      .catch((reason) => {
        console.log(reason);
      });
  }, [orderDetailShow, currentPage]);

  const getLatestComment = (product) => {
    if (product.comment.length > 0) {
      return product.comment[product.comment.length - 1].content;
    }
    return '';
  };

  return (
    <div className="page-stock">
      <Helmet>
        <title>Recycled</title>
      </Helmet>
      <PageHeader title="Recycled" />
      <div className="page-stock__content">
        <table className="order-table page-stock__table">
          <thead>
            <tr>
              <th>Type</th>
              <th>Prod ID</th>
              <th>Customer name</th>
              <th>Model</th>
              <th>Size</th>
              <th>Finish</th>
              <th>Box Type</th>
              <th>Logos</th>
              <th>Creation date</th>
              <th>Recycled Date</th>
              <th>Comments</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order, index) => (
              <tr key={index} className={classNames(`is-default`)}>
                <td className={classNames(`u-color-default-600`)} role="gridcell">
                  {order.product.category === constants.ProductType.CategoryB
                    ? constants.ProductType.CategoryB
                    : order.product.type}
                </td>
                <td role="gridcell">{order.product.id}</td>
                <td role="gridcell">
                  {`${order.product.contact?.firstName || ''} ${
                    order.product.contact?.lastName || ''
                  }`}
                </td>
                <td role="gridcell">{order.product?.productModel?.model.name}</td>
                <td role="gridcell">{order.product?.productModel?.size.name}</td>
                <td role="gridcell">{order.product?.productModel?.finish.name}</td>
                <td role="gridcell">{order.product?.productModel?.boxType.name}</td>
                <td role="gridcell">{order.product?.productModel?.logo.name}</td>
                <td role="gridcell">{formatDate(order.product.orderDate)}</td>
                <td role="gridcell">{formatDate(order.product.updateAt)}</td>
                <td role="gridcell">{getLatestComment(order?.product)}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          className="page-tracking__pagination"
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
          threshold={2}
        />
      </div>
      <Drawer show={orderDetailShow} setShow={orderDetailToggle}>
        <OrderDetail />
      </Drawer>
      <Drawer show={orderCommentsShow} setShow={orderCommentsToggle}>
        <OrderComments />
      </Drawer>
    </div>
  );
};

export default Recycled;
