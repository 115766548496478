import React from 'react';
import classNames from 'classnames';
import { Congratulations } from '../../components/Svg';

const Recycled = ({ className, id }) => {
  return (
    <div className={classNames('page-process', className)}>
      <div className="page-process__content">
        <div className="empty-state">
          <Congratulations className="empty-state__image" />
          <h1 className="text-style text-style--h3 empty-state__title">Recycled!</h1>
          <p className="text-style text-style--p empty-state__text">{`Order ${id} has been moved to the recycled list`}</p>
        </div>
      </div>
    </div>
  );
};

export default Recycled;
