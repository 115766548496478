import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import API from '../../contexts/api';
import { getUser } from '../../contexts/userSlice';

import Button from '../../components/Button';
import Alert from '../../components/Alert';
import { Field, Input } from '../../components/Form';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogin = () => {
    setLoading(true);
    API.post('/auth/login', {
      email,
      password,
    })
      .then((data) => {
        setLoading(false);
        // save data to redux
        dispatch(getUser({ accessToken: data.data.access_token }));
        localStorage.setItem('access_token', data.data.access_token);
        navigate('/production');
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setMessage('Login fail');
      });
  };

  return (
    <div className="page-login">
      <div className="container page-login__container">
        <div className="form page-login__form">
          <header className="form__header">
            <h2 className="text-style text-style--h2 form__title">Login</h2>
          </header>
          {message ? <Alert type="error" title="Error" message={message} /> : null}
          <Field className="form__field">
            <Input
              value={email}
              placeholder="Email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Field>
          <Field className="form__field">
            <Input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </Field>
          <Field className="form__field">
            <Button theme="primary" onClick={handleLogin} block loading={loading}>
              Login
            </Button>
          </Field>
        </div>
      </div>
    </div>
  );
};

export default Login;
