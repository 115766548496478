import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { DateTime } from 'luxon';
import { TextField } from '@mui/material';
import PageHeader from '../../components/PageHeader';
import FilterBar from '../../components/FilterBar';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import API from '../../contexts/api';
import Pagination from '../../components/Pagination';
import { activatedUpdateSideBar } from '../../contexts/utilSlice';
import constants from '../../utils/constants';
import { formatDateDDMMYYYHHMM } from '../../utils/date';
import { Field } from '../../components/Form';
import { Grid } from '../../components/Grid';

const listStatus = [
  'CNC',
  'Backshaping',
  'Infusion',
  'Sanding',
  'Coating',
  'Repair 1 & 2',
  'Detailing',
  'Logo + varnish',
  'Wet sanding + pad installation',
  'Repair 3 & 4',
  'Packaging',
];

const ProductionData = ({ className }) => {
  const location = useLocation();
  const [orders, setOrders] = useState([]);
  const [searchParam] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [ordersStatus, setOrdersStatus] = useState([]);
  const [filterBarSelectedIndex, setFilterBarSelectedIndex] = useState(null);
  const [isOverViewTab, setOverViewTab] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');

  const handleChange = (isOverView = false) => {
    const startDate = dateFrom
      ? DateTime.fromJSDate(new Date(dateFrom)).toFormat('yyyy-MM-dd')
      : '';
    const endDate = dateTo ? DateTime.fromJSDate(new Date(dateTo)).toFormat('yyyy-MM-dd') : '';

    let url = `/history-completed/all`;
    if (isOverView) {
      url = `/history-completed/overview`;
    }
    if (searchParam.get('status') && searchParam.get('status2')) {
      url += `?dateFrom=${startDate}&dateTo=${endDate}&page=${currentPage}&limit=${
        process.env.REACT_APP_LIMIT_ITEM
      }&status=${searchParam.get('status')}&status2=${searchParam.get('status2')}`;
    }
    if (searchParam.get('status') && !searchParam.get('status2')) {
      url += `?dateFrom=${startDate}&dateTo=${endDate}&page=${currentPage}&limit=${
        process.env.REACT_APP_LIMIT_ITEM
      }&status=${searchParam.get('status')}`;
    }
    if (!searchParam.get('status') && !searchParam.get('status2')) {
      const limit = isOverView
        ? process.env.REACT_APP_LIMIT_OVERVIEW_TAB
        : process.env.REACT_APP_LIMIT_ITEM;
      url += `?dateFrom=${startDate}&dateTo=${endDate}&page=${currentPage}&limit=${limit}`;
    }
    API.get(url)
      .then((rs) => {
        setOrders(rs.data.data);
        setTotalPages(rs.data.totalPages);
        setCurrentPage(rs.data.currentPage);
      })
      .catch((reason) => {
        console.log(reason);
      });
  };

  const handleChangeDateFrom = (e) => {
    const date = e.target.value
      ? DateTime.fromJSDate(new Date(e.target.value)).toFormat('yyyy-MM-dd')
      : '';
    setDateFrom(date);
  };

  const handleChangeDateTo = (e) => {
    const date = e.target.value
      ? DateTime.fromJSDate(new Date(e.target.value)).toFormat('yyyy-MM-dd')
      : '';
    setDateTo(date);
  };

  useEffect(() => {
    if ((dateFrom && dateTo) || (!dateFrom && !dateTo)) {
      handleChange(isOverViewTab);
    }
  }, [dateFrom, dateTo, isOverViewTab]);

  useEffect(() => {
    let url = `/history-completed/all`;
    setOverViewTab(false);
    if (location.pathname === '/production-data') {
      navigate('overview');
    }

    if (location.pathname === '/production-data/overview' && searchParam.get('status') === null) {
      setFilterBarSelectedIndex(0);
      setOverViewTab(true);
      url = `history-completed/overview`;
    }

    if (searchParam.get('status') && searchParam.get('status2')) {
      url += `?page=${currentPage}&limit=${
        process.env.REACT_APP_LIMIT_ITEM
      }&status=${searchParam.get('status')}&status2=${searchParam.get('status2')}`;
    }
    if (searchParam.get('status') && !searchParam.get('status2')) {
      url += `?page=${currentPage}&limit=${
        process.env.REACT_APP_LIMIT_ITEM
      }&status=${searchParam.get('status')}`;
    }
    if (!searchParam.get('status') && !searchParam.get('status2')) {
      const limit =
        location.pathname === '/production-data/overview'
          ? process.env.REACT_APP_LIMIT_OVERVIEW_TAB
          : process.env.REACT_APP_LIMIT_ITEM;
      url += `?page=${currentPage}&limit=${limit}`;
    }
    if (dateFrom && dateTo) {
      url += `&dateFrom=${dateFrom}&dateTo=${dateTo}`;
    }
    API.get(url)
      .then((value) => {
        setOrders(value.data.data);
        setCurrentPage(value.data.currentPage);
        setTotalPages(value.data.totalPages);
      })
      .catch((reason) => {
        console.log(reason);
      });

    Promise.all([API.get('status/all'), API.get('order/count/status')]).then((result) => {
      const listStatusToShow = result[1].data.filter(
        (status) => listStatus.indexOf(status.status) !== -1,
      );
      let all = 0;
      listStatusToShow.forEach((status) => {
        let repair1Id;
        let repair2Id;
        let repair3Id;
        let repair4Id;
        if (status.status !== 'New order') {
          all += Number.parseInt(status.count, 10);
        }
        status.name = status.status;
        delete status.status;
        result[0].data.forEach((item) => {
          if (status.name === item.name) {
            status.url = `/production-data/all?status=${item.id}`;
          }
          if (item.name === 'Repair 1') {
            repair1Id = item.id;
          }
          if (item.name === 'Repair 2') {
            repair2Id = item.id;
          }
          if (item.name === 'Repair 3') {
            repair3Id = item.id;
          }
          if (item.name === 'Repair 4') {
            repair4Id = item.id;
          }
        });

        if (status.name === 'Repair 1 & 2') {
          status.url = `/production-data/all?status=${repair1Id}&status2=${repair2Id}`;
        }
        if (status.name === 'Repair 3 & 4') {
          status.url = `/production-data/all?status=${repair3Id}&status2=${repair4Id}`;
        }
      });
      listStatusToShow.unshift({ url: '/production-data/all ', count: all, name: 'All' });
      listStatusToShow.unshift({ url: '/production-data/overview', count: 0, name: 'Overview' });
      setOrdersStatus(listStatusToShow);
    });
    dispatch(activatedUpdateSideBar());
  }, [location.pathname, navigate, searchParam, currentPage]);

  const handleDowloadPDF = async (productId, orderId) => {
    const result = await API.get(`/pdf/exportPDF/${orderId}`, { responseType: 'blob' });
    const href = window.URL.createObjectURL(new Blob([result.data], { type: 'application/pdf' }));
    const a = document.getElementById('pdfFile');
    a.download = `Production_process_${productId}.pdf`;
    a.href = href;
    a.click();
    a.href = '';
  };

  const handleExportCSV = async () => {
    let url = '/history-completed/exportCSV?';
    if (searchParam.get('status') && searchParam.get('status2')) {
      url = `${url}status=${searchParam.get('status')}&status2=${searchParam.get('status2')}`;
    }
    if (searchParam.get('status') && !searchParam.get('status2')) {
      url = `${url}status=${searchParam.get('status')}`;
    }

    if (dateFrom && dateTo) {
      url += `&dateFrom=${dateFrom}&dateTo=${dateTo}`;
    }

    if (isOverViewTab) {
      url += `&isOverViewTab=true`;
    }

    const result = await API.get(url, { responseType: 'blob' });
    const href = window.URL.createObjectURL(new Blob([result.data], { type: 'application/pdf' }));
    const a = document.getElementById('csvFile');

    a.download = `report_product_data.csv`;
    a.href = href;
    a.click();
    a.href = '';
  };

  return (
    <div className={classNames('page-production', className)}>
      <Helmet>
        <title>Production</title>
      </Helmet>
      <PageHeader title="Production Data" />
      <FilterBar
        links={ordersStatus}
        selectedIndex={filterBarSelectedIndex}
        onSelect={(index) => {
          setCurrentPage(1);
          setFilterBarSelectedIndex(index);
        }}
        productionData={true}
      />
      <div className="page-production__content">
        <Grid>
          <Grid.Col col={2}>
            <Field label="Date From" className="form__field grid__col-6 grid__col-start-1">
              <TextField
                inputProps={{ className: 'input' }}
                id="dateFrom"
                type="date"
                sx={{ width: 220 }}
                onChange={handleChangeDateFrom}
                InputLabelProps={{}}
              />
            </Field>
          </Grid.Col>
          <Grid.Col col={2} style={{ margin: '0 0 0 20px' }}>
            <Field label="Date To" className="form__field grid__col-6 grid__col-start-4">
              <TextField
                inputProps={{ className: 'input' }}
                id="dateTo"
                type="date"
                sx={{ width: 220 }}
                onChange={handleChangeDateTo}
                InputLabelProps={{}}
              />
            </Field>
          </Grid.Col>
          <Grid.Col start={12} className="product-drawer__order-details">
            <Field
              label="Export as CSV"
              className="form__field grid__col-6 grid__col-start-1"
              download
              onClick={() => {
                handleExportCSV();
              }}
            ></Field>
            <Button
              download
              onClick={() => {
                handleExportCSV();
              }}
              theme="blue"
              size="xsmall"
            >
              <Icon name="download" />
            </Button>
            <a id="csvFile" type="hidden" href="/#">
              {' '}
            </a>
          </Grid.Col>
        </Grid>
        <table className="notification-table page-analytics__table">
          {isOverViewTab ? (
            <>
              <thead>
                <tr>
                  <th>Group Status</th>
                  <th>Production step</th>
                  <th>Total</th>
                  <th style={{ display: 'none' }}>Download</th>
                  <th style={{ display: 'none' }}>Delete</th>
                </tr>
              </thead>
              <tbody>
                {orders
                  ? orders.map((order, index) => {
                      return order.statusDetails?.map((statusDetail, indexDependencies) => {
                        return (
                          <tr
                            key={`${order.statusName}-${index}-${indexDependencies}`}
                            className={classNames(`is-default`)}
                          >
                            {indexDependencies === 0 ? (
                              <td rowSpan={order.rowSpan}>{order.statusName}</td>
                            ) : null}
                            <td role="gridcell" style={{ textAlign: 'left' }}>
                              {statusDetail.statusName}
                            </td>
                            <td className="analytics-table__product-stock-production">
                              {statusDetail.count}
                            </td>
                            <td role="gridcell" style={{ display: 'none' }}>
                              <div className="content-grid__item-actions">
                                {order.action !== constants.ActionNotification.recycled ? (
                                  <>
                                    <Button
                                      download
                                      onClick={() => {
                                        handleDowloadPDF(statusDetail.notificationId);
                                      }}
                                      theme="blue"
                                      size="xsmall"
                                    >
                                      <Icon name="download" />
                                    </Button>
                                    <a id="pdfFile" type="hidden" href="/#">
                                      {' '}
                                    </a>
                                  </>
                                ) : null}
                              </div>
                            </td>
                            <td role="gridcell" style={{ display: 'none' }}>
                              <div className="content-grid__item-actions">
                                <Button theme="danger" size="xsmall" onClick={() => {}}>
                                  <Icon name="trash" />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        );
                        // });
                      });
                    })
                  : null}
              </tbody>
            </>
          ) : (
            <>
              <thead>
                <tr>
                  <th>Group Status</th>
                  <th>Date</th>
                  <th>Operator</th>
                  <th>Prod ID</th>
                  <th>Model</th>
                  <th>Size</th>
                  <th>Finish</th>
                  <th>Box Type</th>
                  <th>Logo</th>
                  <th>Production step</th>
                  <th>Total</th>
                  <th style={{ display: 'none' }}>Download</th>
                  <th style={{ display: 'none' }}>Delete</th>
                </tr>
              </thead>
              <tbody>
                {orders
                  ? orders.map((order, index) => {
                      return order.statusDetails?.map((item, indexDependencies) => {
                        return item.products?.map((productData, indexProducts) => {
                          return (
                            <tr
                              key={`${order.statusName}-${index}-${indexDependencies}-${indexProducts}`}
                              className={classNames(`is-default`)}
                            >
                              {indexProducts === 0 && indexDependencies === 0 ? (
                                <td rowSpan={order.rowSpan}>{order.statusName}</td>
                              ) : null}
                              <td
                                className={classNames(`u-color-default-600`)}
                                role="gridcell"
                                style={{ textAlign: 'left' }}
                              >
                                {formatDateDDMMYYYHHMM(productData.createAt)}
                              </td>
                              <td>{productData.operator?.fullName}</td>
                              <td>{productData.product?.id}</td>
                              <td role="gridcell">
                                {productData.product?.productModel?.model?.name}
                              </td>
                              <td role="gridcell">
                                {productData.product?.productModel?.size?.name}
                              </td>
                              <td role="gridcell">
                                {productData.product?.productModel?.finish?.name}
                              </td>
                              <td role="gridcell">
                                {productData.product?.productModel?.boxType?.name}
                              </td>
                              <td role="gridcell">
                                {productData.product?.productModel?.logo?.name}
                              </td>
                              <td role="gridcell" style={{ textAlign: 'left' }}>
                                {item.statusName}
                              </td>
                              {indexProducts === 0 ? (
                                <td
                                  className="analytics-table__product-stock-production"
                                  rowSpan={item.count}
                                >
                                  {item.count}
                                </td>
                              ) : null}
                              <td role="gridcell" style={{ display: 'none' }}>
                                <div className="content-grid__item-actions">
                                  {order.action !== constants.ActionNotification.recycled ? (
                                    <>
                                      <Button
                                        download
                                        onClick={() => {
                                          handleDowloadPDF(item.notificationId);
                                        }}
                                        theme="blue"
                                        size="xsmall"
                                      >
                                        <Icon name="download" />
                                      </Button>
                                      <a id="pdfFile" type="hidden" href="/#">
                                        {' '}
                                      </a>
                                    </>
                                  ) : null}
                                </div>
                              </td>
                              <td role="gridcell" style={{ display: 'none' }}>
                                <div className="content-grid__item-actions">
                                  <Button theme="danger" size="xsmall" onClick={() => {}}>
                                    <Icon name="trash" />
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          );
                        });
                      });
                    })
                  : null}
              </tbody>
            </>
          )}
        </table>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          className="page-tracking__pagination"
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
          threshold={2}
        />
      </div>
    </div>
  );
};

ProductionData.propTypes = {
  className: PropTypes.string,
};

export default ProductionData;
