import React, { useEffect, useState } from 'react';
import API from '../../contexts/api';

const AnalyticsLiveInventory = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const { data } = await API.get(`product/live-inventory`);
      setProducts(data);
    };

    fetch();
  }, []);

  return (
    <div className="page-analytics">
      <div className="page-analytics__content">
        {products
          ? products.map((product, productIndex) => {
              return (
                <table key={productIndex} className="analytics-table page-analytics__table">
                  <thead>
                    <tr>
                      <th colSpan="5"></th>
                      <th colSpan="2" className="analytics-table__in-stock">
                        In Stock
                      </th>
                      <th colSpan="2" className="analytics-table__in-production">
                        In Production
                      </th>
                      <th className="analytics-table__total">Total</th>
                      <th rowSpan="2" className="analytics-table__product-stock-production">
                        Size Total
                      </th>
                    </tr>
                    <tr className="analytics-table__product-props">
                      <th className="analytics-table__product-name"></th>
                      <th className="analytics-table__product-size">Size</th>
                      <th className="analytics-table__product-boxType">Box Type</th>
                      <th className="analytics-table__product-finish">Finish</th>
                      <th className="analytics-table__product-logos">Logos</th>
                      <th className="analytics-table__product-category-a">Category A</th>
                      <th className="analytics-table__product-category-b">Cat B</th>
                      <th className="analytics-table__product-customer">Customer</th>
                      <th className="analytics-table__product-stock">Stock</th>
                      <th className="analytics-table__product-stock-production">
                        Stock + Production
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {product.sizes
                      ? product.sizes.map((size, sizeIndex) => {
                          return size.dependencies.map((item, indexDependencies) => {
                            return (
                              <tr key={`${product.name}-${sizeIndex}-${indexDependencies}`}>
                                {indexDependencies === 0 && sizeIndex === 0 ? (
                                  <td style={{ width: '140px' }} rowSpan={product.rowSpan}>
                                    {product.name}
                                  </td>
                                ) : null}
                                {indexDependencies === 0 ? (
                                  <td rowSpan={size.rowSpan}>{size.name}</td>
                                ) : null}
                                <td>{item.boxType}</td>
                                <td>{item.finish}</td>
                                <td>{item.logo}</td>
                                <td>{item.categoryA}</td>
                                <td>{item.categoryB}</td>
                                <td>{item.customer}</td>
                                <td>{item.stock}</td>
                                <td className="analytics-table__product-stock-production">
                                  {item.total}
                                </td>
                                {indexDependencies === 0 ? (
                                  <td
                                    className="analytics-table__product-stock-production"
                                    rowSpan={size.rowSpan}
                                  >
                                    {size.summarize}
                                  </td>
                                ) : null}
                              </tr>
                            );
                          });
                        })
                      : null}
                  </tbody>
                </table>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default AnalyticsLiveInventory;
