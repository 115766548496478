import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Field = ({ htmlFor, label, children, className, valid, inValid, fieldText }) => {
  const labelElement = (
    <label htmlFor={htmlFor} className="field__label">
      {label}
    </label>
  );

  const fieldTextElement = <span className="field-text field__field-text">{fieldText}</span>;

  return (
    <fieldset
      className={classNames('field', { 'is-valid': valid }, { 'is-invalid': inValid }, className)}
    >
      {label ? labelElement : ''}
      {children}
      {fieldText ? fieldTextElement : ''}
    </fieldset>
  );
};

Field.propTypes = {
  htmlFor: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  valid: PropTypes.bool,
  inValid: PropTypes.bool,
  fieldText: PropTypes.string,
};

export default Field;
