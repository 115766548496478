import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';

import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import PageHeader from '../../components/PageHeader';
import Tag from '../../components/Tag';
import Drawer from '../../components/Drawer';
import Button from '../../components/Button';
import FilterBar from '../../components/FilterBar';

import OrderDetail from './OrderDetail';
import OrderDetailShipped from './OrderDetailShipped';
import API from '../../contexts/api';
import { formatDate } from '../../utils/date';
import { Dialog } from '../../components/Modal';
import Pagination from '../../components/Pagination';
import { activatedUpdateSideBar } from '../../contexts/utilSlice';
import constants from '../../utils/constants';
import { Input, InputGroup } from '../../components/Form';

const filterBarUrls = [
  // {
  //   url: '/shipping/all',
  //   name: 'All',
  //   count: 0,
  // },
  {
    url: '/shipping?type=Ready for Shipping',
    name: 'Ready for Shipping',
    count: 0,
  },
  {
    url: '/shipping?type=Shipped',
    name: 'Shipped',
    count: 0,
  },
];

const Shipping = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const [selectedId, setSelectedId] = useState();
  const [orderDetailShow, setOrderDetailShow] = useState(false);
  const [modalMoveToShippedShow, setUpdateModalMoveToShippedShow] = useState(false);
  const [modalBackToStockShow, setUpdateModalBackToStockShow] = useState(false);
  const [modalUpdateOrderShow, setUpdateModalUpdateOrderShow] = useState(false);
  const [updateConfirmOrderLoading, setUpdateConfirmOrderLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [bodyData, setBodyData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filterBarSelectedIndex, setFilterBarSelectedIndex] = useState(null);
  const [orders, setOrders] = useState([]);
  const [selecteOrder, setSelectOrder] = useState(null);
  const [keyword, setKeyword] = useState('');
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      keyword: '',
    },
    onSubmit: (value) => {
      const url = `/order/shipped/search?keyword=${value.keyword}&page=${currentPage}&limit=${process.env.REACT_APP_LIMIT_ITEM}`;

      API.get(url)
        .then((rs) => {
          setOrders(rs.data.data);
          setCurrentPage(rs.data.currentPage);
          setTotalPages(rs.data.totalPages);
        })
        .catch((reason) => {
          console.log(reason);
        });
    },
  });

  const orderDetailToggle = (order) => {
    setOrderDetailShow(!orderDetailShow);
    setSelectedId(order.id);
    setSelectOrder(order);
  };

  const countOrder = async (page) => {
    const readyShipping = API.get(
      `/order/list/ready-for-shipping?page=${page}&limit=${process.env.REACT_APP_LIMIT_ITEM}`,
    );
    const shipped = API.get(
      `/order/shipped/search?keyword=${keyword}&page=${page}&limit=${process.env.REACT_APP_LIMIT_ITEM}`,
    );

    const result = await Promise.all([readyShipping, shipped]);
    result.forEach((value, index) => {
      filterBarUrls[index].count = value.data.totalItems;
    });
    return [result[0], result[1]];
  };

  useEffect(() => {
    const fetchData = async () => {
      const arrResult = await countOrder(1);
      let index = 0;
      if (location.pathname === '/shipping' && !searchParam.get('type')) {
        navigate('?type=Ready for Shipping');
        setFilterBarSelectedIndex(0);
      }

      if (searchParam.get('type') === 'Ready for Shipping') {
        index = 0;
      } else if (searchParam.get('type') === 'Shipped') {
        index = 1;
      }

      setFilterBarSelectedIndex(index);
      setOrders(arrResult[index].data.data);
      setCurrentPage(arrResult[index].data.currentPage);
      setTotalPages(arrResult[index].data.totalPages);
    };
    fetchData();
    dispatch(activatedUpdateSideBar());
  }, [location.pathname, navigate, orderDetailShow, searchParam]);

  useEffect(() => {
    const fetchData = async () => {
      const arrResult = await countOrder(currentPage);
      let index = 0;
      if (location.pathname === '/shipping' && !searchParam.get('type')) {
        navigate('?type=Ready for Shipping');
        setFilterBarSelectedIndex(0);
      }

      if (searchParam.get('type') === 'Ready for Shipping') {
        index = 0;
      } else if (searchParam.get('type') === 'Shipped') {
        index = 1;
      }

      setFilterBarSelectedIndex(index);
      setOrders(arrResult[index].data.data);
      setCurrentPage(arrResult[index].data.currentPage);
      setTotalPages(arrResult[index].data.totalPages);
    };
    fetchData();
  }, [currentPage]);

  useEffect(() => {
    if (!modalMoveToShippedShow && !modalBackToStockShow && !modalUpdateOrderShow) {
      setUpdateLoading(false);
    }
  }, [modalMoveToShippedShow, modalBackToStockShow, modalUpdateOrderShow]);

  const handleShowPopupMoveOrder = (data, moveToShipped) => {
    setBodyData(data);
    setUpdateLoading(true);
    if (moveToShipped) {
      setUpdateModalMoveToShippedShow(true);
    } else {
      setUpdateModalBackToStockShow(true);
    }
  };

  const handleShowPopupUpdateOrder = (data) => {
    setBodyData(data);
    setUpdateLoading(true);
    setUpdateModalUpdateOrderShow(true);
  };

  const handleUpdateCancelOrder = () => {
    setUpdateLoading(false);
    setUpdateModalMoveToShippedShow(false);
    setUpdateModalBackToStockShow(false);
    setUpdateModalUpdateOrderShow(false);
  };

  const handleConfirmMoveOrder = async () => {
    // update order
    try {
      await API.put(`/order/${selectedId}`, bodyData);
    } catch (error) {
      toast(`${error.response.data.message}`, { type: 'error' });
    }
    // move to ready for shipping
    let action = '';
    if (modalMoveToShippedShow) {
      action = 'Shipped';
    } else if (modalBackToStockShow) {
      action = 'Stock';
    }
    try {
      await API.put(`/order/${selectedId}/action`, { action });
    } catch (error) {
      toast(`${error.response.data.message}`, { type: 'error' });
    }
    setUpdateConfirmOrderLoading(true);
    setUpdateLoading(false);
    setUpdateModalMoveToShippedShow(false);
    setUpdateModalBackToStockShow(false);
    setOrderDetailShow(!orderDetailShow);
    setUpdateConfirmOrderLoading(false);
  };

  const handleConfirmUpdateOrder = async () => {
    // update order
    try {
      await API.put(`/order/${selectedId}`, bodyData);
    } catch (error) {
      toast(`${error.response.data.message}`, { type: 'error' });
    }
    setUpdateConfirmOrderLoading(true);
    setUpdateLoading(false);
    setUpdateModalUpdateOrderShow(false);
    setOrderDetailShow(!orderDetailShow);
    setUpdateConfirmOrderLoading(false);
  };

  // const handleClickTrackingLink = (link) => {
  //   console.log(link);
  //   const a = document.getElementById('trackingLink');
  //   a.href = link;
  //   a.target = '_blank';
  //   a.click();
  //   a.href = '';
  // };

  const handleChange = (e) => {
    formik.setFieldValue('keyword', e.target.value);
    setKeyword(e.target.value);
  };

  const handleSearch = (e) => {
    if (e.charCode === 13) {
      formik.handleSubmit();
    } else {
      const regex = new RegExp('[0-9a-zA-Z]', 'g');
      if (!regex.test(e.key)) {
        e.preventDefault();
      }
    }
  };

  return (
    <div className="page-shipping">
      <Helmet>
        <title>Ready for Shipping</title>
      </Helmet>
      <PageHeader title="Ready for Shipping" />
      <FilterBar
        links={filterBarUrls}
        selectedIndex={filterBarSelectedIndex}
        onSelect={(index) => setFilterBarSelectedIndex(index)}
      />
      <div className="page-shipping__content">
        {filterBarSelectedIndex === 1 ? (
          <InputGroup icon="search" className="page-production__search">
            <Input
              type="search"
              id="keyword"
              name="keyword"
              placeholder="Search by Shopify Order, Production ID, or Client's Name"
              onChange={handleChange}
              onKeyPress={handleSearch}
              value={formik.values.keyword}
            />
          </InputGroup>
        ) : null}
        <table className="order-table page-shipping__table">
          <thead>
            <tr>
              <th>Category</th>
              <th>Prod ID</th>
              <th>Shopify</th>
              <th>Name</th>
              <th>Model</th>
              <th>Size</th>
              <th>Finish</th>
              <th>Box Type</th>
              <th>Logos</th>
              <th>Weight</th>
              <th>Creation date</th>
              <th>Order date</th>
              {searchParam.get('type') === 'Shipped' ? (
                <>
                  <th>Shipped date</th>
                  <th>Tracking Links</th>
                </>
              ) : null}
              <th>Tags</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order, index) => (
              <tr key={index} className={classNames(`is-default`)}>
                <td
                  role="gridcell"
                  onClick={() => orderDetailToggle(order)}
                  className={classNames(`u-color-default-600`)}
                >
                  {order.product.category === constants.ProductType.CategoryB
                    ? constants.ProductType.CategoryB
                    : order.product.type === constants.ProductType.Customer ||
                      order.product.type === constants.ProductType.Stock
                    ? 'Category A'
                    : order.product.type}
                </td>
                <td role="gridcell" onClick={() => orderDetailToggle(order)}>
                  {order.product.id}
                </td>
                <td role="gridcell" onClick={() => orderDetailToggle(order)}>
                  {order.product.shopifyOrderId}
                </td>
                <td role="gridcell" onClick={() => orderDetailToggle(order)}>
                  {order.product?.contact?.firstName} {order.product?.contact?.lastName}
                </td>
                <td role="gridcell" onClick={() => orderDetailToggle(order)}>
                  {order.product?.productModel?.model.name}
                </td>
                <td role="gridcell" onClick={() => orderDetailToggle(order)}>
                  {order.product?.productModel?.size.name}
                </td>
                <td role="gridcell" onClick={() => orderDetailToggle(order)}>
                  {order.product?.productModel?.finish.name}
                </td>
                <td role="gridcell" onClick={() => orderDetailToggle(order)}>
                  {order.product?.productModel?.boxType.name}
                </td>
                <td role="gridcell" onClick={() => orderDetailToggle(order)}>
                  {order.product?.productModel?.logo.name}
                </td>
                <td role="gridcell" onClick={() => orderDetailToggle(order)}>
                  {order.product.weight}
                </td>
                <td role="gridcell" onClick={() => orderDetailToggle(order)}>
                  {formatDate(order.product.createAt)}
                </td>
                <td role="gridcell" onClick={() => orderDetailToggle(order)}>
                  {formatDate(order.product.orderDate)}
                </td>
                {searchParam.get('type') === 'Shipped' ? (
                  <>
                    <td role="gridcell" onClick={() => orderDetailToggle(order)}>
                      {formatDate(order.product.shippedDate)}
                    </td>
                    <td>
                      {/* <Button
                        onClick={() => handleClickTrackingLink(order?.product.trackingLinks)}
                        theme="link"
                        size="small"
                      > */}
                      {order.product.trackingLinks}
                      {/* </Button> */}
                      {/* <a id="trackingLink" type="hidden" href="/#">
                        {' '}
                      </a> */}
                    </td>
                  </>
                ) : null}

                <td>
                  {order.product.productTag.map((tag, tagIndex) => (
                    <Tag key={tagIndex} label={tag.tag.name} theme="orange" />
                  ))}
                </td>
                {/* <td>
                  <Button download href={order.pdfUrl} theme="blue" size="xsmall">
                    <Icon name="download" />
                  </Button>
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>

        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          className="page-tracking__pagination"
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
          threshold={2}
        />
      </div>
      {orderDetailShow ? (
        <Drawer show={orderDetailShow} setShow={orderDetailToggle}>
          {selecteOrder.product.action === 'Ready for Shipping' ? (
            <OrderDetail
              id={selectedId}
              onSubmit={handleShowPopupMoveOrder}
              loading={updateLoading}
            />
          ) : (
            <OrderDetailShipped
              id={selectedId}
              onSubmit={handleShowPopupUpdateOrder}
              loading={updateLoading}
            />
          )}
        </Drawer>
      ) : null}
      {/* Move to shipped */}
      <Dialog
        theme="success"
        show={modalMoveToShippedShow}
        setShow={setUpdateModalMoveToShippedShow}
        icon="shipping"
        title="Move to Shipped"
        text="Would you like to move this order to Shipped?"
        actions={
          <>
            <Button theme="white" onClick={handleUpdateCancelOrder}>
              Cancel
            </Button>
            <Button
              theme="primary"
              onClick={handleConfirmMoveOrder}
              loading={updateConfirmOrderLoading}
            >
              Confirm
            </Button>
          </>
        }
      />
      {/* Move to stock */}
      <Dialog
        theme="success"
        show={modalBackToStockShow}
        setShow={setUpdateModalBackToStockShow}
        icon="shipping"
        title="Move to Stock"
        text="Would you like to move this order to Stock?"
        actions={
          <>
            <Button theme="white" onClick={handleUpdateCancelOrder}>
              Cancel
            </Button>
            <Button
              theme="primary"
              onClick={handleConfirmMoveOrder}
              loading={updateConfirmOrderLoading}
            >
              Confirm
            </Button>
          </>
        }
      />
      {/* Update order */}
      <Dialog
        theme="success"
        show={modalUpdateOrderShow}
        setShow={setUpdateModalUpdateOrderShow}
        icon="shipping"
        title="Update order"
        text="Would you like to update this order?"
        actions={
          <>
            <Button theme="white" onClick={handleUpdateCancelOrder}>
              Cancel
            </Button>
            <Button
              theme="primary"
              onClick={handleConfirmUpdateOrder}
              loading={updateConfirmOrderLoading}
            >
              Confirm
            </Button>
          </>
        }
      />
    </div>
  );
};

export default Shipping;
