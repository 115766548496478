import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import { toast } from 'react-toastify';

import { useDispatch } from 'react-redux';
import PageHeader from '../../components/PageHeader';
import Tag from '../../components/Tag';
import Drawer from '../../components/Drawer';
import Button from '../../components/Button';
import FilterBar from '../../components/FilterBar';

import OrderDetail from './OrderDetail';
import API from '../../contexts/api';
import { formatDate } from '../../utils/date';
import { Dialog } from '../../components/Modal';
import Pagination from '../../components/Pagination';
import { activatedUpdateSideBar } from '../../contexts/utilSlice';
import constants from '../../utils/constants';

const initStockUrls = [
  // {
  //   url: '/stock/all',
  //   name: 'All',
  //   count: 0,
  // },
  {
    url: '/stock?type=CategoryA',
    name: 'Category A',
    count: 0,
  },
  {
    url: '/stock?type=CategoryB',
    name: 'Category B',
    count: 0,
  },
  {
    url: '/stock?type=Prototypes',
    name: 'Prototypes',
    count: 0,
  },
];

const Stock = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const [orderDetailShow, setOrderDetailShow] = useState(false);
  const [orders, setOrders] = useState([]);
  const [selectedId, setSelectedId] = useState();
  const [updateOrderModalShow, setUpdateOrderModalShow] = useState(false);
  const [updateConfirmOrderLoading, setUpdateConfirmOrderLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [bodyData, setBodyData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filterBarSelectedIndex, setFilterBarSelectedIndex] = useState(null);
  const dispatch = useDispatch();
  const orderDetailToggle = (id) => {
    setOrderDetailShow(!orderDetailShow);
    setSelectedId(id);
  };

  const countOrder = async (page) => {
    const categoryA = API.get(
      `/order/list/categoryA?page=${page}&limit=${process.env.REACT_APP_LIMIT_ITEM}`,
    );
    const categoryB = API.get(
      `/order/list/categoryB?page=${page}&limit=${process.env.REACT_APP_LIMIT_ITEM}`,
    );
    const prototype = API.get(
      `/order/list/prototype?page=${page}&limit=${process.env.REACT_APP_LIMIT_ITEM}`,
    );

    const result = await Promise.all([categoryA, categoryB, prototype]);
    result.forEach((value, index) => {
      initStockUrls[index].count = value.data.totalItems;
    });
    return [result[0], result[1], result[2]];
  };

  useEffect(() => {
    const fetchData = async () => {
      const arrResult = await countOrder(1);
      let index = 0;
      if (location.pathname === '/stock' && !searchParam.get('type')) {
        navigate('?type=CategoryA');
        setFilterBarSelectedIndex(0);
      }

      if (searchParam.get('type') === 'CategoryA') {
        index = 0;
      } else if (searchParam.get('type') === 'CategoryB') {
        index = 1;
      } else if (searchParam.get('type') === 'Prototypes') {
        index = 2;
      }
      setFilterBarSelectedIndex(index);
      setOrders(arrResult[index].data.data);
      setCurrentPage(arrResult[index].data.currentPage);
      setTotalPages(arrResult[index].data.totalPages);
    };
    fetchData().catch((reason) => console.log(reason));
    dispatch(activatedUpdateSideBar());
  }, [location.pathname, navigate, orderDetailShow, searchParam]);

  useEffect(() => {
    const fetchData = async () => {
      const arrResult = await countOrder(currentPage);
      let index = 0;
      if (location.pathname === '/stock' && !searchParam.get('type')) {
        navigate('?type=CategoryA');
        setFilterBarSelectedIndex(0);
      }

      if (searchParam.get('type') === 'CategoryA') {
        index = 0;
      } else if (searchParam.get('type') === 'CategoryB') {
        index = 1;
      } else if (searchParam.get('type') === 'Prototypes') {
        index = 2;
      }
      setFilterBarSelectedIndex(index);
      setOrders(arrResult[index].data.data);
      setCurrentPage(arrResult[index].data.currentPage);
      setTotalPages(arrResult[index].data.totalPages);
    };
    fetchData().catch((reason) => console.log(reason));
  }, [currentPage]);

  useEffect(() => {
    if (!updateOrderModalShow) {
      setUpdateLoading(false);
    }
  }, [updateOrderModalShow]);

  const handleUpdateOrder = (data) => {
    setBodyData(data);
    setUpdateLoading(true);
    setUpdateOrderModalShow(true);
  };

  const handleUpdateCancelOrder = () => {
    setUpdateLoading(false);
    setUpdateOrderModalShow(false);
  };

  const handleUpdateConfirmOrder = async () => {
    // update order
    try {
      await API.put(`/order/${selectedId}`, bodyData);
      // move to ready for shipping
      await API.put(`/order/${selectedId}/action`, { action: 'Ready for Shipping' });
    } catch (error) {
      toast(`${error.response.data.message}`, { type: 'error' });
    }
    setUpdateConfirmOrderLoading(true);
    setTimeout(() => {
      setUpdateLoading(false);
      setUpdateOrderModalShow(false);
      setOrderDetailShow(!orderDetailShow);
      setUpdateConfirmOrderLoading(false);
    }, 500);
  };

  return (
    <div className="page-stock">
      <Helmet>
        <title>Stock</title>
      </Helmet>
      <PageHeader title="Stock" />
      <FilterBar
        links={initStockUrls}
        selectedIndex={filterBarSelectedIndex}
        onSelect={(index) => setFilterBarSelectedIndex(index)}
      />
      <div className="page-stock__content">
        <table className="order-table page-stock__table">
          <thead>
            <tr>
              <th>Category</th>
              <th>Prod ID</th>
              <th>Model</th>
              <th>Size</th>
              <th>Finish</th>
              <th>Box Type</th>
              <th>Logos</th>
              <th>Weight</th>
              <th>Creation date</th>
              <th>End of production date</th>
              {searchParam.get('type') === 'CategoryB' ? <th>Note</th> : null}
              <th>Tags</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order, index) => (
              <tr
                onClick={() => orderDetailToggle(order.id)}
                key={index}
                className={classNames(`is-${'default'}`)}
              >
                <td className={classNames(`u-color-${'default'}-600`)} role="gridcell">
                  {order.product.category === constants.ProductType.CategoryB
                    ? constants.ProductType.CategoryB
                    : order.product.type === constants.ProductType.Customer ||
                      order.product.type === constants.ProductType.Stock
                    ? 'Category A'
                    : order.product.type}
                </td>
                <td role="gridcell">{order.product.id}</td>
                <td role="gridcell">{order.product?.productModel?.model.name}</td>
                <td role="gridcell">{order.product?.productModel?.size.name}</td>
                <td role="gridcell">{order.product?.productModel?.finish.name}</td>
                <td role="gridcell">{order.product?.productModel?.boxType.name}</td>
                <td role="gridcell">{order.product?.productModel?.logo.name}</td>
                <td role="gridcell">{order.product.weight}</td>
                <td role="gridcell">{formatDate(order.product.orderDate)}</td>
                <td role="gridcell">{formatDate(order.product.productionEndDate)}</td>
                {searchParam.get('type') === 'CategoryB' ? (
                  <td role="gridcell">
                    {order.product?.comment
                      ? order.product?.comment[order.product?.comment?.length - 1]?.content
                      : ''}
                  </td>
                ) : null}
                <td>
                  {order.product.productTag.map((tag, tagIndex) => (
                    <Tag key={tagIndex} label={tag.tag.name} theme="orange" />
                  ))}
                </td>
                {/* <td>
                  <Button download href={''} theme="blue" size="xsmall">
                    <Icon name="download" />
                  </Button>
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          className="page-tracking__pagination"
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
          threshold={2}
        />
      </div>
      {orderDetailShow ? (
        <Drawer show={orderDetailShow} setShow={orderDetailToggle}>
          <OrderDetail id={selectedId} onSubmit={handleUpdateOrder} loading={updateLoading} />
        </Drawer>
      ) : (
        ''
      )}
      <Dialog
        theme="success"
        show={updateOrderModalShow}
        setShow={setUpdateOrderModalShow}
        icon="shipping"
        title="Move to Ready for shipping"
        text="Would you like to move this order to Ready for Shipping?"
        actions={
          <>
            <Button theme="white" onClick={handleUpdateCancelOrder}>
              Cancel
            </Button>
            <Button
              theme="primary"
              onClick={handleUpdateConfirmOrder}
              loading={updateConfirmOrderLoading}
            >
              Confirm
            </Button>
          </>
        }
      />
    </div>
  );
};

export default Stock;
