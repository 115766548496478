import React, { useEffect, useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../Icon';

const Drawer = ({ children, show, setShow, placement, className }) => {
  const handleUserKeyPress = useCallback(
    (event) => {
      const { keyCode } = event;
      if (keyCode === 27 && show) {
        setShow(false);
      }
    },
    [setShow, show],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  return (
    <div
      className={classNames('drawer', `drawer--placement-${placement}`, className, {
        'is-active': show,
      })}
    >
      <button className="drawer__background" onClick={() => setShow(false)} />
      <div className="drawer__wrapper">
        <button type="button" className="drawer__close-button" onClick={() => setShow(false)}>
          <Icon name="times" />
        </button>

        <div className="drawer__content">{children}</div>
      </div>
    </div>
  );
};

Drawer.defaultProps = {
  placement: 'right',
  show: false,
};

Drawer.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  children: PropTypes.any,
  className: PropTypes.string,
  placement: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
};

export default Drawer;
