import Loadable from 'react-loadable';

import Loading from '../../components/Loading';

import Production from './Production';

const LoadableProduction = Loadable({
  loader: () => Promise.resolve(Production),
  loading: Loading,
});

export default LoadableProduction;
