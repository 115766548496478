import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageHeader from '../../components/PageHeader';
import { Checkbox, Field, Input } from '../../components/Form';
import Button from '../../components/Button';
import API from '../../contexts/api';
import { Grid } from '../../components/Grid';

const OperatorSchema = Yup.object().shape({
  name: Yup.string().required('Name is required!'),
  statusKeys: Yup.array().min(1, 'Select at least 1 item'),
});

const OperatorAdd = () => {
  const [statusAll, setStatusAll] = useState(null);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: '',
      statusKeys: [],
    },
    validationSchema: OperatorSchema,
    onSubmit: async (values) => {
      const bodyData = {
        name: values.name,
        statusKey: values.statusKeys,
      };
      try {
        await API.post(`user/operator`, bodyData);
        navigate(`/operators`);
      } catch (error) {
        toast(`${error.response.data.message}`, { type: 'error' });
      }
    },
  });

  useEffect(() => {
    const fetch = async () => {
      const response = await API.get(`status/all`);
      setStatusAll(
        response.data.filter((item) => {
          return item.name !== 'Shipment' && item.name !== 'Completed';
        }),
      );
    };
    fetch();
  }, []);

  return (
    <div className="page-operators">
      <Helmet>
        <title>Add Operator</title>
      </Helmet>
      <PageHeader title="Add Operator" />
      <div className="page-operators__content">
        <form onSubmit={formik.handleSubmit} className="form page-operators__form">
          <div className="page-operators__section">
            <div className="grid">
              <Field htmlFor="OperatorName" label="Name" className="form__field grid__col-6">
                <Input id="name" value={formik.values.name} onChange={formik.handleChange} />
                {formik.errors.name ? (
                  <p className="field-text is-invalid">{formik.errors.name}</p>
                ) : null}
              </Field>
            </div>
            <div className="grid">
              <Field label="Status Access" className="form__field grid__col-12">
                {statusAll ? (
                  <Grid className="page-operators__status">
                    {statusAll.map((item, index) => (
                      <Grid.Col key={index} col={4}>
                        <Checkbox
                          id={item.id}
                          name="statusKeys"
                          value={item.key}
                          onChange={formik.handleChange}
                          className="page-operators__status-checkbox"
                          checked={formik.values.statusKeys.includes(item.key)}
                        >
                          {item.name}
                        </Checkbox>
                      </Grid.Col>
                    ))}
                  </Grid>
                ) : null}
                {formik.errors.statusKeys ? (
                  <p className="field-text is-invalid">{formik.errors.statusKeys}</p>
                ) : null}
              </Field>
            </div>
          </div>
          <div className="page-operators__actions">
            <Button href="/operators" theme="primary-outline">
              Cancel
            </Button>
            <Button type="submit" theme="primary">
              Add Operator
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

OperatorAdd.propTypes = {
  children: PropTypes.any,
};

export default OperatorAdd;
