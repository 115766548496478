import * as React from 'react';

function SvgRecycle(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M13.56 4.261c-.682-1.177-2.436-1.177-3.118 0L8.374 7.835l-1.279-.617 2.1-3.629c1.227-2.12 4.385-2.118 5.611 0l1.956 3.38.259-.998a.693.693 0 01.328-.412.737.737 0 01.533-.074.713.713 0 01.435.307c.098.153.13.337.09.513l-.715 2.758a.678.678 0 01-.325.424.73.73 0 01-.541.078l-2.857-.69a.726.726 0 01-.439-.314.672.672 0 01.245-.946.731.731 0 01.541-.077l1.324.32-2.08-3.597zm3.86 6.672l1.277-.618 2.89 4.994c1.197 2.068-.352 4.614-2.805 4.614h-5.058l.924.892a.691.691 0 01.218.49.669.669 0 01-.21.493.718.718 0 01-.51.202.735.735 0 01-.508-.21l-2.142-2.067a.677.677 0 01-.21-.488c0-.183.076-.358.21-.487l2.142-2.069a.714.714 0 01.507-.21.737.737 0 01.512.202.689.689 0 01.209.493.669.669 0 01-.218.49l-.923.89h5.057c1.363 0 2.223-1.414 1.559-2.563l-2.921-5.048zm-7.562 7.611h-4.64c-1.363 0-2.223-1.415-1.558-2.563l2.763-4.774.639 1.411c.077.165.219.295.394.36.176.065.37.06.543-.012a.7.7 0 00.377-.377.667.667 0 00-.006-.524L7.12 9.308a.7.7 0 00-.335-.34.737.737 0 00-.482-.054l-3.035.69a.728.728 0 00-.459.303.675.675 0 00-.093.53.678.678 0 00.328.433.73.73 0 00.55.075l1.547-.352-2.728 4.716c-1.197 2.068.352 4.614 2.806 4.614h4.64v-1.378z"
      />
    </svg>
  );
}

export default SvgRecycle;
