import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Modal from './Modal';
import Icon from '../Icon';

const Dialog = ({ className, icon, title, text, actions, theme, size, show, setShow }) => {
  return (
    <Modal
      show={show}
      setShow={setShow}
      size={size}
      className={classNames('modal-dialog', `modal-dialog--theme-${theme}`, className)}
    >
      <div className="modal-dialog__content">
        {icon ? <Icon name={icon} className="modal-dialog__icon" /> : null}
        {title ? <h4 className="modal-dialog__title">{title}</h4> : null}
        {text ? <p className="modal-dialog__text">{text}</p> : null}
      </div>
      {actions ? <div className="modal-dialog__actions">{actions}</div> : null}
    </Modal>
  );
};

Dialog.defaultProps = {
  size: 'small',
  theme: 'success',
};

Dialog.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  actions: PropTypes.element,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  theme: PropTypes.oneOf(['info', 'warning', 'success', 'danger']),
  show: PropTypes.bool,
  setShow: PropTypes.func,
  className: PropTypes.string,
};

export default Dialog;
