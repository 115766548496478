import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import PageHeader from '../../components/PageHeader';
import { Field, Input } from '../../components/Form';
import Button from '../../components/Button';

const USER = {
  name: 'Aydin Senturk',
  email: 'aydin@takoon.com',
};

const Profile = () => {
  const [user, setUser] = useState(null);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userCurrentPassword, setUserCurrentPassword] = useState('');
  const [userNewPassword, setUserNewPassword] = useState('');

  const fetchData = () => {
    setUser(USER);
  };

  useEffect(() => {
    fetchData();
  });

  return (
    <div className="page-users">
      <Helmet>
        <title>Profile</title>
      </Helmet>
      <PageHeader title="Profile" />
      <div className="page-users__content">
        {user ? (
          <form className="form page-users__form">
            <div className="page-users__section">
              <div className="grid">
                <Field htmlFor="name" label="Name" className="form__field grid__col-6">
                  <Input
                    id="name"
                    value={userName}
                    onChange={(event) => setUserName(event.target.value)}
                  />
                </Field>
                <Field htmlFor="email" label="Email" className="form__field grid__col-6">
                  <Input
                    id="email"
                    value={userEmail}
                    onChange={(event) => setUserEmail(event.target.value)}
                  />
                </Field>
                <Field
                  htmlFor="CurrentPassword"
                  label="Current Password"
                  className="form__field grid__col-6"
                >
                  <Input
                    type="password"
                    id="CurrentPassword"
                    value={userCurrentPassword}
                    onChange={(event) => setUserCurrentPassword(event.target.value)}
                  />
                </Field>
                <Field
                  htmlFor="NewPassword"
                  label="New Password"
                  className="form__field grid__col-6"
                >
                  <Input
                    type="password"
                    id="NewPassword"
                    value={userNewPassword}
                    onChange={(event) => setUserNewPassword(event.target.value)}
                  />
                </Field>
              </div>
            </div>
            <div className="page-users__actions">
              <Button type="submit" theme="primary">
                Update
              </Button>
            </div>
          </form>
        ) : null}
      </div>
    </div>
  );
};

Profile.propTypes = {
  children: PropTypes.any,
};

export default Profile;
