import React from 'react';
import classNames from 'classnames';

// import Button from '../../components/Button';

import { Congratulations } from '../../components/Svg';

const Completed = ({ className }) => {
  return (
    <div className={classNames('page-process', className)}>
      <div className="page-process__content">
        <div className="empty-state">
          <Congratulations className="empty-state__image" />
          <h1 className="text-style text-style--h3 empty-state__title">Congrats!</h1>
          <p className="text-style text-style--p empty-state__text">Keep it going</p>
        </div>
      </div>
      {/* <div className="page-process__actions">
        <Button theme="primary" block className="page-process__action-button">
          Move to ready for shipping
        </Button>
      </div> */}
    </div>
  );
};

export default Completed;
