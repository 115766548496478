import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '../Icon';

const Alert = ({ title, message, type, showIcon, className }) => {
  const icon = () => {
    if (type === 'success') {
      return 'check-circle';
    }

    if (type === 'info') {
      return 'exclamation-circle';
    }

    if (type === 'warning') {
      return 'exclamation-triangle';
    }

    if (type === 'error') {
      return 'times-circle';
    }

    return null;
  };

  return (
    <div className={classNames('alert', className, `is-${type}`)}>
      {showIcon ? <Icon name={icon()} className="alert__icon" /> : null}
      <div className="alert__body">
        {title ? <h6 className="alert__title">{title}</h6> : null}
        <div className="alert__message">
          <p>{message}</p>
        </div>
      </div>
    </div>
  );
};

Alert.defaultProps = {
  showIcon: true,
};

Alert.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['success', 'info', 'warning', 'error']).isRequired,
  showIcon: PropTypes.bool,
};

export default Alert;
