import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import PageHeader from '../../../components/PageHeader';
import { Checkbox, Field, Input } from '../../../components/Form';
import Button from '../../../components/Button';

const UserRole = () => {
  const [userRoleName, setUserRoleName] = useState('');

  return (
    <div className="page-users">
      <Helmet>
        <title>User Role</title>
      </Helmet>
      <PageHeader title="User Role" />
      <div className="page-users__content">
        <form className="form page-users__form">
          <div className="page-users__section">
            <div className="grid">
              <Field htmlFor="name" label="Name" className="form__field grid__col-6">
                <Input
                  id="name"
                  value={userRoleName}
                  onChange={(event) => setUserRoleName(event.target.value)}
                />
              </Field>
            </div>
            <table>
              <thead>
                <tr>
                  <th>View</th>
                  <th>Edit</th>
                  <th>Create</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <Checkbox id="ID" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="page-users__actions">
            <Button type="submit" theme="primary">
              Update
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

UserRole.propTypes = {
  children: PropTypes.any,
};

export default UserRole;
