import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class Button extends Component {
  render() {
    const { children, type, href, theme, size, block, loading, className, ...otherProps } =
      this.props;
    const buttonClasses = classNames(
      'button',
      `button--${theme}`,
      `button--size-${size}`,
      className,
      {
        'button--block': block,
        'is-loading': loading,
      },
    );

    if (href) {
      return (
        <Link to={href} className={buttonClasses} {...otherProps}>
          {children}
        </Link>
      );
    }

    return (
      <button type={type} className={buttonClasses} {...otherProps}>
        {children}
      </button>
    );
  }
}

Button.defaultProps = {
  theme: 'default',
  type: 'button',
  size: 'default',
};

Button.propTypes = {
  children: PropTypes.any,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  href: PropTypes.any,
  theme: PropTypes.oneOf([
    'default',
    'white',
    'link',
    'primary',
    'primary-outline',
    'green',
    'green-outline',
    'info',
    'info-outline',
    'blue',
    'blue-outline',
    'danger',
    'danger-outline',
    'red',
    'red-outline',
    'warning',
    'warning-outline',
    'orange',
    'orange-outline',
  ]),
  size: PropTypes.oneOf(['default', 'xsmall', 'small', 'large']),
  block: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
};

export default Button;
