import React from 'react';
import { connect, useSelector } from 'react-redux';
import { hot } from 'react-hot-loader/root';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';

import './styles/app.scss';

import { useLocation } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Pages from './pages';

const App = () => {
  const location = useLocation();
  const user = useSelector((state) => state.user);
  return (
    <div
      className={classNames('layout', {
        'layout--dashboard': user.accessToken && !location.pathname.startsWith(`/process`),
      })}
    >
      <Helmet>
        <title>Takoon Factory</title>
      </Helmet>
      {user.accessToken && !location.pathname.startsWith(`/process`) ? (
        <Sidebar className="layout__sidebar" />
      ) : (
        ''
      )}
      <div className={classNames('layout__main')}>
        <Pages className="layout__page" />
      </div>
    </div>
  );
};

App.propTypes = {};

const mapStateToProps = () => ({});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(hot(App));
