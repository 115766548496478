import axios from 'axios';
import { getValueFromLocalstorage, removeItemFromLocalstorage } from '../utils/storage';

const API = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? '/api' : process.env.REACT_APP_API_BASEURL,
});

API.interceptors.request.use((req) => {
  if (getValueFromLocalstorage('access_token')) {
    req.headers.Authorization = `Bearer ${getValueFromLocalstorage('access_token')}`;
  }
  return req;
});
API.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error);
    if (error.response.status === 401) {
      removeItemFromLocalstorage('access_token');
      window.location = '/login';
    }
    throw error;
  },
);

export default API;
