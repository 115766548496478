import * as React from 'react';

function SvgFileSuccess(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13.042 2h1.438a2.861 2.861 0 012.538 1.543c.219.015.437.031.655.049h.002c1.462.12 2.534 1.356 2.534 2.787v9.914a2.84 2.84 0 01-.837 2.013 2.86 2.86 0 01-2.017.832h-1.459v1.091c0 .98-.798 1.771-1.776 1.771H4.777C3.798 22 3 21.21 3 20.229V9.496c0-.981.798-1.771 1.776-1.771h2.537V6.379c0-1.43 1.073-2.666 2.534-2.787h.002c.218-.018.436-.034.655-.05A2.848 2.848 0 0113.042 2zm-2.849 2.964a1.416 1.416 0 001.412 1.294h4.313a1.418 1.418 0 001.41-1.294l.233.019c.706.058 1.253.66 1.253 1.396v9.914c0 .384-.153.752-.426 1.024a1.464 1.464 0 01-1.033.425h-1.459V9.496c0-.981-.798-1.771-1.776-1.771H8.709V6.379c0-.736.547-1.338 1.253-1.396l.232-.019zm5.681-.546a1.45 1.45 0 00-.527-.739 1.465 1.465 0 00-.867-.284h-1.438c-.312 0-.616.1-.867.284a1.451 1.451 0 00-.59 1.17c0 .002.001.004.004.007a.022.022 0 00.007.005l.009.001h4.313a.023.023 0 00.016-.006.016.016 0 00.005-.012c0-.144-.022-.288-.065-.426zM4.776 9.12a.379.379 0 00-.38.376v10.733c0 .204.167.376.38.376h9.345c.212 0 .38-.172.38-.376V9.496a.379.379 0 00-.38-.376H4.775zm7.266 3.767c.3.242.348.68.107.981l-2.876 3.578a.698.698 0 01-1.036.057L6.8 16.073a.698.698 0 01.984-.99l.888.884 2.389-2.973c.241-.3.68-.348.98-.107z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgFileSuccess;
