import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import Button from '../../components/Button';
import { Dialog } from '../../components/Modal';
import PageHeader from '../../components/PageHeader';

const USERS = [
  { id: 1, name: 'User 1' },
  { id: 2, name: 'User 2' },
  { id: 3, name: 'User 3' },
];

const Users = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState(USERS);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogDeleteUser, setDialogDeleteUser] = useState(null);

  const handleClickDialogDelete = (id) => {
    setLoading(true);
    setShowDialog(true);
    setDialogDeleteUser(id);
  };

  const handleClickDialogCancel = () => {
    setLoading(false);
    setShowDialog(false);
    setDialogDeleteUser(null);
  };

  const handleDeleteUser = (id) => {
    const filteredUsers = users.filter((user) => user.id !== id);
    setUsers(filteredUsers);
    setLoading(false);
    setShowDialog(false);
    setDialogDeleteUser(null);
  };

  return (
    <div className="page-users">
      <Helmet>
        <title>Users</title>
      </Helmet>
      <PageHeader title="Users" />
      <div className="page-users__content">
        <div className="page-users__section">
          <div className="content-grid">
            {users.map((user, index) => (
              <div key={index} className="content-grid__item">
                <span className="content-grid__item-name">{user.name}</span>
                <div className="content-grid__item-actions">
                  <Button
                    href={`/users/${user.id}`}
                    theme="warning"
                    size="xsmall"
                    loading={loading}
                    disabled={loading}
                  >
                    Update
                  </Button>
                  <Button
                    theme="danger"
                    size="xsmall"
                    loading={loading}
                    disabled={loading}
                    onClick={() => handleClickDialogDelete(user.id)}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="page-users__actions">
          <Button href="/users/roles" theme="primary-outline" loading={loading} disabled={loading}>
            User Roles
          </Button>
          <Button href="/users/add" theme="primary" loading={loading} disabled={loading}>
            Add User
          </Button>
        </div>
      </div>
      <Dialog
        theme="danger"
        show={showDialog}
        setShow={handleClickDialogCancel}
        icon="production"
        title={`Do you want to delete this user?`}
        actions={
          <>
            <Button theme="white" onClick={handleClickDialogCancel}>
              Cancel
            </Button>
            <Button theme="danger" onClick={() => handleDeleteUser(dialogDeleteUser)}>
              Confirm
            </Button>
          </>
        }
      />
    </div>
  );
};

Users.propTypes = {
  children: PropTypes.any,
};

export default Users;
