import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '../Icon';

const InputGroup = ({ children, icon, iconLeft, iconRight, className, ...otherProps }) => {
  const ChildComponent = () =>
    Children.map(children, (child) =>
      cloneElement(child, {
        className: classNames(child.props.className, 'input-group__input'),
      }),
    );

  if (icon && !iconRight) {
    iconLeft = true;
  }

  return (
    <div
      className={classNames(
        'input-group',
        { 'input-group--icon-left': iconLeft },
        { 'input-group--icon-right': iconRight },
        className,
      )}
      {...otherProps}
    >
      {icon ? (
        <span className="input-group__icon" tabIndex="-1">
          <Icon name={icon} />
        </span>
      ) : null}
      {ChildComponent()}
    </div>
  );
};

InputGroup.defaultProps = {
  iconLeft: false,
  iconRight: false,
};

InputGroup.propTypes = {
  children: PropTypes.element,
  icon: PropTypes.string,
  iconLeft: PropTypes.bool,
  iconRight: PropTypes.bool,
  className: PropTypes.string,
};

export default InputGroup;
