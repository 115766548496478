import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../components/Button';
import { Input, Select, Textarea } from '../../components/Form';
import { Dialog } from '../../components/Modal';
import API from '../../contexts/api';
import constants from '../../utils/constants';
import Completed from './Completed';
import { activatedUpdateSideBar } from '../../contexts/utilSlice';
import Recycled from './Recycled';

const Process = ({ className }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [showDialog3rdHotCoat, setShowDialog3rdHotCoat] = useState(false);
  const [showDialogRepairHotCoat, setShowDialogRepairHotCoat] = useState(false);
  const [showDialogSendToRepair, setShowDialogSendToRepair] = useState(false);
  const [showDialogSendToRecycle, setShowDialogSendToRecycle] = useState(false);
  const [showDialogCompleted, setShowDialogCompleted] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [productDetail, setProductDetail] = useState();
  const [theme, setTheme] = useState();
  const [comment, setComment] = useState(null);
  const [weight, setWeight] = useState(null);
  const [category, setCategory] = useState(null);
  const [status, setStatus] = useState(null);
  const [operators, setOperators] = useState([]);
  const [operator, setOperator] = useState('');
  const [orderDetail, setOrderDetail] = useState();
  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    const fetch = async () => {
      const rsProduct = await API.get(`product/detail/${id}`);
      setProductDetail(rsProduct.data.product);
      setOrderDetail(rsProduct.data.order);
      const rsOperators = await API.get(`user/operator/${rsProduct?.data?.product?.status?.id}`);
      const users = rsOperators.data.map((user) => {
        return { id: user.id, label: user.fullName, value: user.id };
      });
      users.unshift({ id: '', label: 'Select operator', value: '' });
      setOperators(users);
      if (rsProduct.data.product?.statusDetail?.weight) {
        setTheme('yellow');
        return;
      }
      if (rsProduct.data.product?.statusDetail?.qualityCheck) {
        setTheme('green');
        return;
      }
      if (
        rsProduct.data.product?.status?.name === 'Repair 1' ||
        rsProduct.data.product?.status?.name === 'Repair 2' ||
        rsProduct.data.product?.status?.name === 'Repair 3' ||
        rsProduct.data.product?.status?.name === 'Repair 4'
      ) {
        setTheme('red');
        return;
      }
      setTheme('purple');
    };
    fetch();
    dispatch(activatedUpdateSideBar());
  }, [
    id,
    showDialog,
    showDialog3rdHotCoat,
    showDialogRepairHotCoat,
    showDialogSendToRepair,
    showDialogSendToRecycle,
    showDialogCompleted,
  ]);

  useEffect(() => {
    setOperator('');
    API.get(`product/detail/${id}`).then((rs) => {
      setProductDetail(rs.data.product);
      setOrderDetail(rs.data.order);
      setCategory(rs.data.product.category);
      setComment(rs.data.comment);
    });
  }, [status]);

  const handleUpdateConfirm = async () => {
    setUpdateLoading(true);
    let keyNextStatus = '';
    let isStartProduction = false;
    switch (productDetail?.statusDetail?.key) {
      case 'DT0_1':
        isStartProduction = true;
        keyNextStatus = 'DT1_1';
        break;
      case 'DT1_1':
        keyNextStatus = 'DT2_1';
        break;
      case 'DT2_1':
        keyNextStatus = 'DT4_0';
        break;
      // case 'DT2_2':
      //   keyNextStatus = 'DT4_0';
      //   break;
      case 'DT4_0':
        keyNextStatus = 'DT4_1';
        break;
      case 'DT4_1':
        keyNextStatus = 'DT4_2';
        break;
      case 'DT4_2':
        keyNextStatus = 'DT4_3';
        break;
      case 'DT4_3':
        keyNextStatus = 'DT5_1';
        break;
      case 'DT5_1':
        keyNextStatus = 'DT6_1';
        break;
      case 'DT6_1':
        keyNextStatus = 'DT5_2';
        break;
      case 'DT5_2':
        keyNextStatus = 'DT6_2';
        break;
      case 'DT6_2':
        keyNextStatus = 'DT5_3';
        break;
      case 'DT5_3':
        keyNextStatus = 'DT14_2';
        break;
      case 'DT14_2':
        keyNextStatus = 'DT7_1';
        break;
      case 'DT7_1':
        keyNextStatus = 'DT7_2';
        break;
      case 'DT7_2':
        keyNextStatus = 'DT8_1';
        break;
      case 'DT8_1':
        keyNextStatus = 'DT9_1';
        break;
      // case 'DT8_2':
      //   keyNextStatus = 'DT9_1';
      //   break;
      case 'DT9_1':
        keyNextStatus = 'DT9_2';
        break;
      case 'DT9_2':
        keyNextStatus = 'DT13_3';
        break;
      case 'DT13_3':
        keyNextStatus = 'DT10_1';
        break;
      case 'DT10_1':
        keyNextStatus = 'STT15';
        break;
      case 'DT12_1_1':
        keyNextStatus = 'DT12_1_2';
        break;
      case 'DT12_1_2':
        keyNextStatus = 'DT12_3';
        break;
      case 'DT12_3':
        keyNextStatus = 'DT7_1';
        break;
      case 'DT12_2_1':
        keyNextStatus = 'DT12_2_2';
        break;
      case 'DT12_2_2':
        keyNextStatus = 'DT7_1';
        break;
      case 'DT12_3_1':
        keyNextStatus = 'DT12_3_2';
        break;
      case 'DT12_3_2':
        keyNextStatus = 'DT12_4_1';
        break;
      case 'DT12_4_1':
        keyNextStatus = 'DT12_4_2';
        break;
      case 'DT12_4_2':
        keyNextStatus = 'DT13_3';
        break;

      default:
        break;
    }
    const body = {
      productId: productDetail?.id,
      keyNextStatus,
      operatorId: Number.parseInt(operator, 10),
      comment,
      weight: Number.parseFloat(weight),
      category,
    };
    if (body.comment === null || body.comment === undefined) {
      delete body.comment;
    }
    if (body.weight === null) {
      delete body.weight;
    }
    if (body.category === null) {
      delete body.category;
    }
    if (isStartProduction) {
      try {
        await API.put(`product/${productDetail.id}/start`);
      } catch (error) {
        toast(`${error.response.data.message}`, { type: 'error' });
      }
    }
    try {
      await API.put(`product/step`, body);
    } catch (error) {
      toast(`${error.response.data.message}`, { type: 'error' });
    }
    setUpdateLoading(false);
    setShowDialog(false);
    setStatus(keyNextStatus);
    setWeight(null);
  };

  const handleSendToRepair = () => {
    setUpdateLoading(true);
    let keyNextStatus = '';
    switch (productDetail?.statusDetail?.key) {
      case 'DT7_2':
        keyNextStatus = 'DT12_2_1';
        break;
      case 'DT9_2':
        keyNextStatus = 'DT12_3_1';
        break;
      case 'DT12_3':
        keyNextStatus = 'DT12_2_1';
        break;
      case 'DT12_2_2':
        keyNextStatus = 'DT12_2_1';
        break;
      case 'DT12_3_2':
        keyNextStatus = 'DT12_3_1';
        break;
      default:
        break;
    }
    const body = {
      productId: productDetail?.id,
      keyNextStatus,
      operatorId: Number.parseInt(operator, 10),
      category,
    };
    API.put(`product/step`, body)
      .then(() => {
        setUpdateLoading(false);
        setShowDialogSendToRepair(false);
        setStatus(keyNextStatus);
      })
      .catch((error) => toast(`${error.response.data.message}`, { type: 'error' }));
  };

  const handleRecycle = async () => {
    setUpdateLoading(true);
    try {
      // update status to completed
      await API.put(`product/step`, {
        productId: productDetail?.id,
        operatorId: Number.parseInt(operator, 10),
        category,
        comment,
      });
      // update action
      await API.put(`order/${orderDetail.id}/action`, {
        action: constants.ActionProduct.recycled,
      });
    } catch (error) {
      toast(`${error.response.data.message}`, { type: 'error' });
    }
    setUpdateLoading(false);
    setShowDialogSendToRecycle(false);
  };

  const handleSendTo3rdHotCoat = () => {
    setUpdateLoading(true);
    const body = {
      productId: productDetail?.id,
      keyNextStatus: 'DT12_1_1',
      operatorId: Number.parseInt(operator, 10),
      category,
    };

    API.put(`product/step`, body)
      .then(() => {
        setUpdateLoading(false);
        setShowDialog3rdHotCoat(false);
        setStatus('DT12_1_1');
      })
      .catch((reason) => console.log(reason));
  };

  const handleSendToRepairInHotCoat = () => {
    setUpdateLoading(true);
    const body = {
      productId: productDetail?.id,
      keyNextStatus: 'DT12_2_1',
      operatorId: Number.parseInt(operator, 10),
      category,
    };

    API.put(`product/step`, body)
      .then(() => {
        setUpdateLoading(false);
        setShowDialogRepairHotCoat(false);
        setStatus('DT12_2_1');
      })
      .catch((reason) => console.log(reason));
  };

  const handleUpdateCancel = () => {
    setUpdateLoading(false);
    setShowDialog(false);
    setShowDialog3rdHotCoat(false);
    setShowDialogRepairHotCoat(false);
    setShowDialogSendToRepair(false);
    setShowDialogSendToRecycle(false);
    setShowDialogCompleted(false);
  };

  // const theme = orderDetail?.product.statusDetail.name'yellow'; // yellow, red, purple

  const handleSelectCategory = (newCategory) => {
    setCategory(newCategory);
  };

  const handleChangeComment = (e) => {
    setComment(e.target.value);
  };

  const handleChangeWeight = (e) => {
    setWeight(Number.parseFloat(e.target.value));
  };

  const handleCompleteProcess = async () => {
    setUpdateLoading(true);
    let action = '';
    if (productDetail?.type === constants.ProductType.Customer) {
      action = constants.ActionProduct.readyforShipping;
    }
    if (
      productDetail?.type === constants.ProductType.Stock ||
      productDetail?.type === constants.ProductType.Prototype ||
      productDetail?.category === constants.ProductType.CategoryB
    ) {
      action = constants.ActionProduct.stock;
    }

    // update action
    try {
      await API.put(`order/${orderDetail.id}/action`, {
        action,
      });
    } catch (error) {
      toast(`${error.response.data.message}`, { type: 'error' });
    }

    // update status to completed
    await API.put(`product/step`, {
      productId: productDetail?.id,
      keyNextStatus: 'DT15_1',
      operatorId: Number.parseInt(operator, 10),
      category,
    });

    setUpdateLoading(false);
    setShowDialogCompleted(false);
  };

  const handleChangeOperator = (event) => {
    setOperator(event.target.value);
  };

  const isDisabled = () => {
    return (
      (productDetail?.statusDetail?.weight && weight === null) || !operator || Number.isNaN(weight)
    );
  };

  const lastConfirmationTitle = () => {
    if (productDetail?.category === constants.ProductType.CategoryB) {
      return `Move to category B stock`;
    }
    if (productDetail?.type === constants.ProductType.Customer) {
      return `Move to ready for shipping`;
    }
    if (productDetail?.type === constants.ProductType.Stock) {
      return `Move to stock list`;
    }
    if (productDetail?.type === constants.ProductType.Prototype) {
      return `Move to prototype stock`;
    }
    return null;
  };

  const buttonColor = (parentStatus, statusDetail) => {
    if (statusDetail?.qualityCheck) {
      return 'primary';
    }
    if (statusDetail?.weight) {
      return 'warning';
    }
    if (
      parentStatus?.name === constants.ParentStatus.Repair1 ||
      parentStatus?.name === constants.ParentStatus.Repair2 ||
      parentStatus?.name === constants.ParentStatus.Repair3 ||
      parentStatus?.name === constants.ParentStatus.Repair4
    ) {
      return 'danger';
    }
    return 'info';
  };

  const themeColor = (parentStatus, statusDetail) => {
    if (statusDetail?.qualityCheck) {
      return 'success';
    }
    if (statusDetail?.weight) {
      return 'warning';
    }
    if (
      parentStatus?.name === constants.ParentStatus.Repair1 ||
      parentStatus?.name === constants.ParentStatus.Repair2 ||
      parentStatus?.name === constants.ParentStatus.Repair3 ||
      parentStatus?.name === constants.ParentStatus.Repair4
    ) {
      return 'danger';
    }
    return 'info';
  };

  const showCategoryButton = () => {
    return (
      productDetail?.statusDetail?.qualityCheck &&
      productDetail?.type !== constants.ProductType.Prototype
    );
  };

  const showCompletedButton = () => {
    return productDetail?.statusDetail?.name !== constants.ProductStatus.BoardPackaging;
  };

  const showStockListButton = () => {
    return (
      productDetail?.category !== constants.ProductType.CategoryB &&
      productDetail?.type === constants.ProductType.Stock &&
      productDetail?.statusDetail?.name === constants.ProductStatus.BoardPackaging
    );
  };
  const showCategoryBButton = () => {
    return (
      productDetail?.category === constants.ProductType.CategoryB &&
      productDetail?.statusDetail?.name === constants.ProductStatus.BoardPackaging
    );
  };

  const showReady4ShippingButton = () => {
    return (
      productDetail?.type === constants.ProductType.Customer &&
      productDetail?.statusDetail?.name === constants.ProductStatus.BoardPackaging &&
      productDetail?.category !== constants.ProductType.CategoryB
    );
  };

  const showRecycleButton = () => {
    return (
      (productDetail?.status?.name === constants.ParentStatus.Infusion &&
        productDetail?.statusDetail?.qualityCheck) ||
      (productDetail?.status?.name === constants.ParentStatus.Sanding &&
        productDetail?.statusDetail?.name ===
          constants.ProductStatus.After_Sanding_After_Second_Hot_Coat) ||
      (productDetail?.status?.name === constants.ParentStatus.Repair1 &&
        productDetail?.statusDetail?.name ===
          constants.ProductStatus.Sanding_After_Third_Hot_Coat_Repair &&
        productDetail?.statusDetail?.qualityCheck) ||
      (productDetail?.status?.name === constants.ParentStatus.Repair2 &&
        productDetail?.statusDetail?.name === constants.ProductStatus.Repair_In_Hot_Coat) ||
      (productDetail?.status?.name === constants.ParentStatus.Detailing &&
        productDetail?.statusDetail?.name === constants.ProductStatus.AfterDetailing) ||
      (productDetail?.status?.name === constants.ParentStatus.WetSanding &&
        productDetail?.statusDetail?.qualityCheck) ||
      // (productDetail?.status?.name === constants.ParentStatus.Repair3 &&
      //   productDetail?.statusDetail?.qualityCheck) ||
      (productDetail?.status?.name === constants.ParentStatus.Repair4 &&
        productDetail?.statusDetail?.qualityCheck)
    );
  };

  const showPrototypeButton = () => {
    return (
      productDetail?.type === constants.ProductType.Prototype &&
      productDetail?.statusDetail?.name === constants.ProductStatus.BoardPackaging
    );
  };

  const showSend3rdHotCoatRepairButton = () => {
    return (
      productDetail?.status?.name === constants.ParentStatus.Sanding &&
      productDetail?.statusDetail?.qualityCheck
    );
  };

  const showSendToRepairButton = () => {
    return (
      (productDetail?.status?.name === constants.ParentStatus.Repair1 ||
        productDetail?.status?.name === constants.ParentStatus.Detailing ||
        productDetail?.status?.name === constants.ParentStatus.WetSanding ||
        productDetail?.status?.name === constants.ParentStatus.Repair3 ||
        productDetail?.status?.name === constants.ParentStatus.Repair2) &&
      productDetail?.statusDetail?.qualityCheck
    );
  };

  return (
    <>
      {productDetail?.action === constants.ActionProduct.product ? (
        <div className={classNames('page-process', `page-process--theme-${theme}`, className)}>
          <div className="page-process__order-id">{productDetail?.id}</div>
          {!productDetail?.statusDetail?.weight &&
          !productDetail?.statusDetail?.qualityCheck &&
          productDetail?.status?.name !== constants.ParentStatus.Repair1 &&
          productDetail?.status?.name !== constants.ParentStatus.Repair2 &&
          productDetail?.status?.name !== constants.ParentStatus.Repair3 &&
          productDetail?.status?.name !== constants.ParentStatus.Repair4 ? (
            <>
              <div className="page-process__title">{productDetail?.statusDetail?.name}</div>
              <div className="page-process__step">
                {Number.parseInt(productDetail?.statusDetail.step, 10) === 0
                  ? 'Step 0 - Start Production'
                  : `Step ${Number.parseInt(productDetail?.statusDetail.step, 10)}/13`}
              </div>
            </>
          ) : (
            <>
              <div className="page-process__title">
                {productDetail?.statusDetail?.weight
                  ? 'Weight'
                  : productDetail?.statusDetail?.qualityCheck
                  ? 'Quality check'
                  : productDetail?.status?.name}
              </div>
              <div className="page-process__subtitle">{productDetail?.statusDetail?.name}</div>
            </>
          )}

          <div className="page-process__order-type">
            {productDetail?.category === constants.ProductType.CategoryB
              ? constants.ProductType.CategoryB
              : productDetail?.type}
          </div>
          <div className="page-process__content">
            {/* Category A & B Button */}
            {showCategoryButton() ? (
              <div className="page-process__category-buttons">
                <Button
                  onClick={() => handleSelectCategory('Category A')}
                  theme={category === 'Category A' ? 'primary' : 'white'}
                  className="page-process__category-button"
                >
                  Category A
                </Button>
                <Button
                  onClick={() => handleSelectCategory('Category B')}
                  theme={category === 'Category B' ? 'danger' : 'white'}
                  className="page-process__category-button"
                >
                  Category B
                </Button>
              </div>
            ) : null}

            <div className="page-process__order-details">
              <div className="page-process__order-details-item">
                <span className="page-process__order-details-label">Model:</span>
                {productDetail?.productModel?.model?.name}
              </div>
              <div className="page-process__order-details-item">
                <span className="page-process__order-details-label">Size:</span>
                {productDetail?.productModel?.size.name}
              </div>
              <div className="page-process__order-details-item">
                <span className="page-process__order-details-label">Finish:</span>
                {productDetail?.productModel?.finish.name}
              </div>
              <div className="page-process__order-details-item">
                <span className="page-process__order-details-label">Logos:</span>
                {productDetail?.productModel?.logo.name}
              </div>
              <div className="page-process__order-details-item">
                <span className="page-process__order-details-label">Box:</span>
                {productDetail?.productModel?.boxType.name}
              </div>
              {productDetail?.statusDetail?.weight ? (
                <div className="page-process__order-details-item">
                  <span className="page-process__order-details-label">Weight (kg):</span>
                  <Input
                    type={'number'}
                    value={weight || undefined}
                    min={0}
                    onChange={handleChangeWeight}
                  />
                </div>
              ) : null}
            </div>

            <Select
              id="NameID"
              name=""
              value={operator}
              onChange={handleChangeOperator}
              options={operators}
              className="page-process__name"
            />

            {productDetail?.statusDetail?.qualityCheck ? (
              <Textarea
                maxLength={255}
                onChange={handleChangeComment}
                value={comment || ''}
                placeholder="Add notes.."
                rows={5}
                className="page-process__note"
              />
            ) : null}
          </div>
          <div className="page-process__actions">
            {/* Step completed button */}
            {showCompletedButton() ? (
              <Button
                disabled={isDisabled()}
                theme={buttonColor(productDetail?.status, productDetail?.statusDetail)}
                className="page-process__action-button"
                block
                onClick={() => setShowDialog(true)}
              >
                {productDetail?.statusDetail?.name === constants.ProductStatus.NewOrder
                  ? 'Start production'
                  : 'Step completed'}
              </Button>
            ) : null}
            {/* Send to repair button */}
            {showSendToRepairButton() ? (
              <Button
                disabled={isDisabled()}
                theme="red-outline"
                className="page-process__action-button"
                block
                onClick={() => setShowDialogSendToRepair(true)}
              >
                Send to repair
              </Button>
            ) : null}
            {/* Send to 3rd hot coat button */}
            {showSend3rdHotCoatRepairButton() ? (
              <>
                <Button
                  disabled={isDisabled()}
                  onClick={() => setShowDialog3rdHotCoat(true)}
                  theme="red-outline"
                  className="page-process__action-button"
                  block
                >
                  Send to 3rd hot coat
                </Button>
                <Button
                  disabled={isDisabled()}
                  onClick={() => setShowDialogRepairHotCoat(true)}
                  theme="red-outline"
                  className="page-process__action-button"
                  block
                >
                  Send to repair in hot coat
                </Button>
              </>
            ) : null}
            {/* Recycled button */}
            {showRecycleButton() ? (
              <Button
                disabled={isDisabled()}
                onClick={() => setShowDialogSendToRecycle(true)}
                theme="white"
                className="page-process__action-button"
                block
              >
                Recycle
              </Button>
            ) : null}
            {/* Move to ready for shipping button */}
            {showReady4ShippingButton() ? (
              <Button
                disabled={isDisabled()}
                onClick={() => setShowDialogCompleted(true)}
                theme="primary"
                className="page-process__action-button"
                block
              >
                Move to ready for shipping
              </Button>
            ) : null}
            {/* Move to stock list button */}
            {showStockListButton() ? (
              <Button
                disabled={isDisabled()}
                onClick={() => setShowDialogCompleted(true)}
                theme="primary"
                className="page-process__action-button"
                block
              >
                Move to stock list
              </Button>
            ) : null}
            {/* Move to category B stock button */}
            {showCategoryBButton() ? (
              <Button
                disabled={isDisabled()}
                onClick={() => setShowDialogCompleted(true)}
                theme="primary"
                className="page-process__action-button"
                block
              >
                Move to category B stock
              </Button>
            ) : null}
            {/* Move to prototype stock button */}
            {showPrototypeButton() ? (
              <Button
                disabled={isDisabled()}
                onClick={() => setShowDialogCompleted(true)}
                theme="primary"
                className="page-process__action-button"
                block
              >
                Move to prototype stock
              </Button>
            ) : null}
          </div>
        </div>
      ) : productDetail?.action === constants.ActionProduct.recycled ? (
        <Recycled id={productDetail?.id} />
      ) : productDetail?.statusDetail?.name === constants.ProductStatus.Completed ? (
        <Completed />
      ) : null}

      <Dialog
        show={showDialog}
        setShow={setShowDialog}
        icon="production"
        theme={themeColor(productDetail?.status, productDetail?.statusDetail)}
        title={
          productDetail?.statusDetail?.name === constants.ProductStatus.NewOrder
            ? `Start production`
            : productDetail?.statusDetail?.qualityCheck
            ? `Quality check ${productDetail?.statusDetail?.name} completed`
            : productDetail?.statusDetail?.weight
            ? `Weight ${productDetail?.statusDetail?.name} completed`
            : `${productDetail?.statusDetail?.name} completed`
        }
        actions={
          <>
            <Button theme="white" onClick={handleUpdateCancel}>
              Cancel
            </Button>
            <Button
              theme={buttonColor(productDetail?.status, productDetail?.statusDetail)}
              onClick={handleUpdateConfirm}
              loading={updateLoading}
            >
              Confirm
            </Button>
          </>
        }
      />
      {/* 3rd hot coat */}
      <Dialog
        show={showDialog3rdHotCoat}
        setShow={setShowDialog3rdHotCoat}
        icon="production"
        title={`Send to 3rd hot coat repair`}
        theme={themeColor(productDetail?.status, productDetail?.statusDetail)}
        actions={
          <>
            <Button theme="white" onClick={handleUpdateCancel}>
              Cancel
            </Button>
            <Button
              theme={buttonColor(productDetail?.status, productDetail?.statusDetail)}
              onClick={handleSendTo3rdHotCoat}
              loading={updateLoading}
            >
              Confirm
            </Button>
          </>
        }
      />
      {/* repair in hot coat */}
      <Dialog
        show={showDialogRepairHotCoat}
        setShow={setShowDialogRepairHotCoat}
        icon="production"
        title={`Send to repair in hot coat repair`}
        theme={themeColor(productDetail?.status, productDetail?.statusDetail)}
        actions={
          <>
            <Button theme="white" onClick={handleUpdateCancel}>
              Cancel
            </Button>
            <Button
              theme={buttonColor(productDetail?.status, productDetail?.statusDetail)}
              onClick={handleSendToRepairInHotCoat}
              loading={updateLoading}
            >
              Confirm
            </Button>
          </>
        }
      />
      {/* send to repair */}
      <Dialog
        show={showDialogSendToRepair}
        setShow={setShowDialogSendToRepair}
        icon="production"
        theme={themeColor(productDetail?.status, productDetail?.statusDetail)}
        title={`Send to repair`}
        actions={
          <>
            <Button theme="white" onClick={handleUpdateCancel}>
              Cancel
            </Button>
            <Button
              theme={buttonColor(productDetail?.status, productDetail?.statusDetail)}
              onClick={handleSendToRepair}
              loading={updateLoading}
            >
              Confirm
            </Button>
          </>
        }
      />
      {/* send to recycle */}
      <Dialog
        show={showDialogSendToRecycle}
        setShow={setShowDialogSendToRecycle}
        icon="production"
        title={`Do you want send to recycle?`}
        actions={
          <>
            <Button theme="white" onClick={handleUpdateCancel}>
              Cancel
            </Button>
            <Button theme="primary" onClick={handleRecycle} loading={updateLoading}>
              Confirm
            </Button>
          </>
        }
      />
      {/* çomplete process */}
      <Dialog
        show={showDialogCompleted}
        setShow={setShowDialogCompleted}
        icon="production"
        title={lastConfirmationTitle()}
        actions={
          <>
            <Button theme="white" onClick={handleUpdateCancel}>
              Cancel
            </Button>
            <Button theme="primary" onClick={handleCompleteProcess} loading={updateLoading}>
              Confirm
            </Button>
          </>
        }
      />
    </>
  );
};

Process.propTypes = {
  className: PropTypes.string,
};

export default Process;
