import * as React from 'react';

function SvgTwitter(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 32 32" {...props}>
      <path d="M32 7.075a12.941 12.941 0 01-3.769 1.031 6.601 6.601 0 002.887-3.631 13.21 13.21 0 01-4.169 1.594A6.565 6.565 0 0022.155 4a6.563 6.563 0 00-6.563 6.563c0 .512.056 1.012.169 1.494A18.635 18.635 0 012.23 5.195a6.56 6.56 0 00-.887 3.3 6.557 6.557 0 002.919 5.463 6.565 6.565 0 01-2.975-.819v.081a6.565 6.565 0 005.269 6.437 6.574 6.574 0 01-2.968.112 6.588 6.588 0 006.131 4.563 13.17 13.17 0 01-9.725 2.719 18.568 18.568 0 0010.069 2.95c12.075 0 18.681-10.006 18.681-18.681 0-.287-.006-.569-.019-.85A13.216 13.216 0 0032 7.076z" />
    </svg>
  );
}

export default SvgTwitter;
