import * as React from 'react';

function SvgShipping(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M22 3H2v5.651h1v10.36c0 .5.21.979.586 1.332.375.353.884.552 1.414.552h14c.53 0 1.04-.199 1.414-.552A1.83 1.83 0 0021 19.011V8.651h1V3zM4 4.884h16v1.883H4V4.884zM19 19.01H5V8.651h14v10.36zM9 10.535h6c0 .5-.21.978-.586 1.332a2.065 2.065 0 01-1.414.551h-2c-.53 0-1.04-.198-1.414-.551A1.83 1.83 0 019 10.535z"
      />
    </svg>
  );
}

export default SvgShipping;
