import * as React from 'react';

function SvgCategoryB(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M9.778 2H3.11c-.295 0-.577.112-.786.312-.208.2-.325.472-.325.755v6.4c0 .283.117.554.325.754.209.2.491.312.786.312h6.667c.294 0 .577-.112.785-.312.209-.2.326-.471.326-.754v-6.4c0-.283-.117-.555-.325-.755A1.135 1.135 0 009.777 2zM8.667 8.4H4.222V4.133h4.445V8.4zM20.889 2h-6.667c-.294 0-.577.112-.785.312-.209.2-.326.472-.326.755v6.4c0 .283.117.554.326.754.208.2.49.312.785.312h6.667c.295 0 .577-.112.786-.312.208-.2.325-.471.325-.754v-6.4c0-.283-.117-.555-.325-.755A1.135 1.135 0 0020.889 2zm-1.111 6.4h-4.445V4.133h4.445V8.4zm-10 4.267H3.11c-.295 0-.577.112-.786.312-.208.2-.325.471-.325.754v6.4c0 .283.117.555.325.755.209.2.491.312.786.312h6.667c.294 0 .577-.112.785-.313.209-.2.326-.47.326-.754v-6.4c0-.283-.117-.554-.325-.754a1.135 1.135 0 00-.786-.312zm-1.111 6.4H4.222V14.8h4.445v4.267zm8.889-6.4c-2.452 0-4.445 1.913-4.445 4.266 0 2.353 1.993 4.267 4.445 4.267 2.45 0 4.444-1.914 4.444-4.267 0-2.353-1.993-4.266-4.444-4.266zm0 6.4c-1.226 0-2.223-.957-2.223-2.134 0-1.176.997-2.133 2.223-2.133 1.225 0 2.222.957 2.222 2.133 0 1.177-.997 2.134-2.222 2.134z"
      />
    </svg>
  );
}

export default SvgCategoryB;
