import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Input = ({
  id,
  type,
  name,
  value,
  placeholder,
  innerRef,
  onFocus,
  onChange,
  onKeyDown,
  valid,
  inValid,
  className,
  ...otherProps
}) => {
  return (
    <input
      id={id}
      type={type}
      name={name}
      value={value}
      placeholder={placeholder}
      ref={innerRef}
      onFocus={onFocus}
      onChange={onChange}
      onKeyDown={onKeyDown}
      className={classNames('input', { 'is-valid': valid }, { 'is-invalid': inValid }, className)}
      {...otherProps}
    />
  );
};

Input.defaultProps = {
  type: 'text',
};

Input.propTypes = {
  id: PropTypes.string,
  type: PropTypes.oneOf(['text', 'submit', 'number', 'email', 'password', 'search']),
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  valid: PropTypes.bool,
  inValid: PropTypes.bool,
  className: PropTypes.string,
};

export default Input;
