import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Radio = ({
  id,
  children,
  name,
  disabled,
  checked,
  optionType,
  buttonShape,
  className,
  onChange,
}) => {
  return (
    <span
      className={classNames(
        'radio',
        `radio--option-type-${optionType}`,
        `radio--button-shape-${buttonShape}`,
        className,
      )}
    >
      <input
        id={id}
        name={name}
        value={children}
        type="radio"
        className="radio__input"
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id} className="radio__label">
        {children}
      </label>
    </span>
  );
};

Radio.defaultProps = {
  children: 'Radio',
  disabled: false,
  checked: false,
  optionType: 'default',
  buttonShape: 'default',
};

Radio.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  children: PropTypes.any,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  optionType: PropTypes.oneOf(['default', 'button']),
  buttonShape: PropTypes.oneOf(['default', 'circle']),
  className: PropTypes.string,
};

export default Radio;
