import * as React from 'react';

function SvgFile(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M3.467 5.556h3.9a.734.734 0 000-1.467H3.35A1.351 1.351 0 002 5.44v15.238a1.351 1.351 0 001.351 1.352H20.65A1.352 1.352 0 0022 20.678V5.44a1.351 1.351 0 00-1.351-1.351h-4.016a.734.734 0 000 1.467h3.9v15.006H3.467V5.556z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.626 1h4.75a2.793 2.793 0 012.752 2.333L17.34 4.6c.025.147.025.297 0 .444l-.21 1.267a2.793 2.793 0 01-2.756 2.334M9.626 1A2.793 2.793 0 006.87 3.333L6.66 4.6a1.354 1.354 0 000 .446l.21 1.266a2.793 2.793 0 002.756 2.334h4.748m-1.877-1.467h1.877a1.326 1.326 0 001.308-1.108l.208-1.248-.209-1.247a1.326 1.326 0 00-1.306-1.108h-4.75a1.326 1.326 0 00-1.307 1.108L8.11 4.822l.208 1.248a1.326 1.326 0 001.308 1.108h1.29L10.29 5.61a.734.734 0 111.363-.545l.845 2.113zm-6.379 10c0-.405.329-.734.734-.734h10.296a.734.734 0 010 1.467H6.852a.734.734 0 01-.734-.733z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgFile;
