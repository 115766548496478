export const getValueFromLocalstorage = (key) => {
  return localStorage.getItem(key);
};

export const saveValueToLocalstorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const removeItemFromLocalstorage = (key) => {
  localStorage.removeItem(key);
};
