import * as React from 'react';

function SvgGooglePlus(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 32 32" {...props}>
      <path d="M10.181 14.294v3.494h5.775c-.231 1.5-1.744 4.394-5.775 4.394-3.475 0-6.313-2.881-6.313-6.431S6.706 9.32 10.181 9.32c1.981 0 3.3.844 4.056 1.569l2.762-2.662c-1.775-1.656-4.075-2.662-6.819-2.662C4.549 5.571-.001 10.121-.001 15.753s4.55 10.181 10.181 10.181c5.875 0 9.775-4.131 9.775-9.95a9.27 9.27 0 00-.163-1.688h-9.613zM32 14h-3v-3h-3v3h-3v3h3v3h3v-3h3z" />
    </svg>
  );
}

export default SvgGooglePlus;
