import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';
import classNames from 'classnames';
import Pagination from '../../components/Pagination';
import Button from '../../components/Button';
import Icon from '../../components/Icon';
import { Dialog } from '../../components/Modal';

import PageHeader from '../../components/PageHeader';
import API from '../../contexts/api';
import { formatDateDDMMYYYHHMM } from '../../utils/date';
import { activatedUpdateSideBar } from '../../contexts/utilSlice';
import constants, { Roles } from '../../utils/constants';

const Notifications = () => {
  const [notifications, setNotifications] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogDeleteNotification, setDialogDeleteNotification] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [role, setRole] = useState({});
  const dispatch = useDispatch();

  const findObject = (value, arrayObject) => {
    return arrayObject.findIndex((object) => {
      if (value === 'Repair 1' || value === 'Repair 2') {
        return object.group === 'Repair 1 + 2';
      }
      if (value === 'Repair 3' || value === 'Repair 4') {
        return object.group === 'Repair 3 + 4';
      }
      return object.group === value;
    });
  };

  const setValueGroup = (status) => {
    if (status === 'Repair 1' || status === 'Repair 2') {
      return 'Repair 1 + 2';
    }
    if (status === 'Repair 3' || status === 'Repair 4') {
      return 'Repair 3 + 4';
    }
    return status;
  };
  const convertResponseToExpectStructrue = (response) => {
    const result = [];
    response.data.data.forEach((value) => {
      const quality =
        value.product.statusDetail.qualityCheck &&
        `Quality check ${value.product.statusDetail.name}`;
      const weight =
        value.product.statusDetail.weight && `Weight ${value.product.statusDetail.name}`;
      let status = '';
      if (quality) {
        status = quality;
      } else if (weight) {
        status = weight;
      } else {
        status = value.product.statusDetail.name;
      }
      // 1. Firt of all, get model then push to Result[]
      if (findObject(value.product.status.name, result) === -1) {
        const group = setValueGroup(value.product.status.name);
        const itemResult = {
          group,
          rowSpan: 1,
          dependencies: [
            {
              notificationId: value.id,
              date: value.createAt,
              productId: value.product.id,
              model: value.product.productModel.model.name,
              boxType: value.product.productModel.boxType.name,
              size: value.product.productModel.size.name,
              finish: value.product.productModel.finish.name,
              logo: value.product.productModel.logo.name,
              content: value.content,
              status,
            },
          ],
        };
        result.push(itemResult);
      } else {
        // 2. Status include in Result[]
        // 2.1 rise rowSpan (of Model) + 1
        const modelIndex = findObject(value.product.status.name, result);
        result[modelIndex].rowSpan += 1;
        result[modelIndex].dependencies.push({
          notificationId: value.id,
          date: value.createAt,
          productId: value.product.id,
          model: value.product.productModel.model.name,
          size: value.product.productModel.size.name,
          finish: value.product.productModel.finish.name,
          boxType: value.product.productModel.boxType.name,
          logo: value.product.productModel.logo.name,
          content: value.content,
          status,
        });
      }
    });
    setNotifications(result);
  };

  const fetchNotifications = async () => {
    const response = await API.get(
      `notifications/list?limit=${process.env.REACT_APP_LIMIT_ITEM}&page=${currentPage}`,
    );
    convertResponseToExpectStructrue(response);
    // setNotifications(response.data.data);
    setTotalPages(response.data.totalPages);
    dispatch(activatedUpdateSideBar());
    if (response.data.totalPages < currentPage && response.data.totalPages > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    fetchNotifications();
    const token = localStorage.getItem('access_token');
    if (token) {
      const decodedToken = jwtDecode(token);
      const roleUser = decodedToken.role;
      setRole(roleUser);
    }
  }, [currentPage]);

  const handleClickDialogDelete = (id) => {
    setShowDialog(true);
    setDialogDeleteNotification(id);
  };

  const handleClickDialogCancel = () => {
    setShowDialog(false);
    setDialogDeleteNotification(null);
  };

  const handleDeleteNotification = async (id) => {
    try {
      await API.delete(`notifications/${id}`);
    } catch (error) {
      toast(`${error.response.data.message}`, { type: 'error' });
    }
    fetchNotifications();
    setShowDialog(false);
    setDialogDeleteNotification(null);
  };

  const handleDowloadPDF = async (id) => {
    const result = await API.get(`/pdf/exportPDF/notification/${id}`, { responseType: 'blob' });
    const href = window.URL.createObjectURL(new Blob([result.data], { type: 'application/pdf' }));
    const a = document.getElementById('pdfFile');
    a.download = `notification_${id}.pdf`;
    a.href = href;
    a.click();
    a.href = '';
  };

  return (
    <div className="page-notifications">
      <Helmet>
        <title>Notifications</title>
      </Helmet>
      <PageHeader title="Notifications" />
      <div style={{ maxWidth: '100%' }} className="page-notifications__content">
        {/* <div> */}
        <div className="page-notifications__section">
          <div className="content-grid">
            <table className="notification-table page-analytics__table">
              <thead>
                <tr>
                  <th>Group Status</th>
                  <th>Date</th>
                  <th>Prod ID</th>
                  <th>Model</th>
                  <th>Size</th>
                  <th>Finish</th>
                  <th>Box Type</th>
                  <th>Logo</th>
                  <th>Update</th>
                  <th>Status</th>
                  <th>Dowload</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {notifications
                  ? notifications.map((notify, index) => {
                      return notify.dependencies.map((item, indexDependencies) => {
                        console.log(item);
                        return (
                          <tr
                            key={`${notify.group}-${index}-${indexDependencies}`}
                            className={classNames(`is-default`)}
                          >
                            {indexDependencies === 0 ? (
                              <td rowSpan={notify.rowSpan}>{notify.group}</td>
                            ) : null}
                            {/* <td role="gridcell">{notify.group}</td> */}
                            <td className={classNames(`u-color-default-600`)} role="gridcell">
                              {formatDateDDMMYYYHHMM(item.date)}
                            </td>
                            <td role="gridcell">{item.productId}</td>
                            <td role="gridcell">{item.model}</td>
                            <td role="gridcell">{item.size}</td>
                            <td role="gridcell">{item.finish}</td>
                            <td role="gridcell">{item.boxType}</td>
                            <td role="gridcell">{item.logo}</td>
                            <td role="gridcell" style={{ textAlign: 'left' }}>
                              {item.content}
                            </td>
                            <td role="gridcell">{item.status}</td>
                            <td role="gridcell">
                              <div className="content-grid__item-actions">
                                {notify.action !== constants.ActionNotification.recycled ? (
                                  <>
                                    <Button
                                      download
                                      onClick={() => {
                                        handleDowloadPDF(item.notificationId);
                                      }}
                                      theme="blue"
                                      size="xsmall"
                                    >
                                      <Icon name="download" />
                                    </Button>
                                    <a id="pdfFile" type="hidden" href="/#">
                                      {' '}
                                    </a>
                                  </>
                                ) : null}
                              </div>
                            </td>
                            <td role="gridcell">
                              <div className="content-grid__item-actions">
                                <Button
                                  disabled={role !== Roles.ADMIN}
                                  theme="danger"
                                  size="xsmall"
                                  onClick={() => {
                                    handleClickDialogDelete(item.notificationId);
                                  }}
                                >
                                  <Icon name="trash" />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        );
                      });
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          className="page-tracking__pagination"
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
          threshold={2}
        />
      </div>
      <Dialog
        theme="danger"
        show={showDialog}
        setShow={handleClickDialogCancel}
        icon="production"
        title={`Do you want to delete this notification?`}
        actions={
          <>
            <Button theme="white" onClick={handleClickDialogCancel}>
              Cancel
            </Button>
            <Button
              theme="danger"
              onClick={() => handleDeleteNotification(dialogDeleteNotification)}
            >
              Confirm
            </Button>
          </>
        }
      />
    </div>
  );
};

export default Notifications;
