import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Select = ({ id, name, options, valid, inValid, className, value, ...otherProps }) => {
  return (
    <select
      id={id}
      name={name}
      className={classNames('select', { 'is-valid': valid }, { 'is-invalid': inValid }, className)}
      {...otherProps}
      value={value}
    >
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

Select.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  className: PropTypes.string,
};

export default Select;
