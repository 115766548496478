import * as React from 'react';

function SvgCongratulations(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={225}
      height={151}
      fill="none"
      viewBox="0 0 225 151"
      {...props}
    >
      <path
        fill="#0CBC8B"
        d="M12.239 25.485l-5.474-1.783a2.828 2.828 0 00-1.752 5.377l5.474 1.784a2.828 2.828 0 101.752-5.378zM212.765 26.253l5.474-1.784a2.828 2.828 0 011.752 5.378l-5.474 1.783a2.828 2.828 0 01-1.752-5.377z"
      />
      <path
        fill="#95D0BC"
        d="M112.559 151c41.697 0 75.5-33.802 75.5-75.5 0-41.697-33.803-75.5-75.5-75.5-41.698 0-75.5 33.803-75.5 75.5 0 41.698 33.802 75.5 75.5 75.5z"
        opacity={0.4}
      />
      <path
        fill="#4EB18F"
        d="M112.557 128.174c29.092 0 52.675-23.583 52.675-52.674 0-29.092-23.583-52.675-52.675-52.675-29.091 0-52.674 23.583-52.674 52.675 0 29.091 23.583 52.674 52.674 52.674z"
      />
      <path
        fill="#FEFEFE"
        d="M101.837 97.448c-.416 0-.829-.08-1.212-.237a3.093 3.093 0 01-1.017-.677l-16.89-16.883a2.963 2.963 0 01-.664-.977 2.868 2.868 0 01.034-2.288 2.97 2.97 0 01.694-.958 3.111 3.111 0 011.023-.627 3.206 3.206 0 012.382.058c.373.16.71.39.99.677l14.819 14.815 38.746-33.386c.301-.265.654-.47 1.038-.603a3.211 3.211 0 012.377.113c.369.169.699.406.972.698.273.292.482.633.617 1.003a2.857 2.857 0 01-.14 2.282 2.979 2.979 0 01-.736.927l-40.968 35.303a3.157 3.157 0 01-2.065.76z"
      />
      <path
        fill="#0CBC8B"
        d="M207.874 135.678l5.474 1.783a2.828 2.828 0 101.752-5.377l-5.474-1.784a2.828 2.828 0 00-1.752 5.378zM212.094 78.536l9.518-.01a2.828 2.828 0 00-.006-5.656l-9.519.01a2.828 2.828 0 00.007 5.656zM12.91 78.536l-9.519-.01a2.828 2.828 0 01.007-5.656l9.518.01a2.828 2.828 0 11-.006 5.656zM12.239 125.625l-5.474 1.784a2.829 2.829 0 01-1.752-5.378l5.474-1.783a2.827 2.827 0 111.752 5.377z"
      />
    </svg>
  );
}

export default SvgCongratulations;
