import React, { Fragment, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import ProductOptionSelect from '../../../components/ProductOptionSelect';
import { Field, Input } from '../../../components/Form';
import Button from '../../../components/Button';
import API from '../../../contexts/api';
import { Roles } from '../../../utils/constants';

const PRODUCT_SECTIONS = [];

const StockSchema = Yup.object().shape({
  model: Yup.string().required('Board information is required!'),
  size: Yup.string().required('Board information is required!'),
  finish: Yup.string().required('Board information is required!'),
  logo: Yup.string().required('Board information is required!'),
  boxType: Yup.string().required('Board information is required!'),
});

const Stock = () => {
  const [productSection, setProductSection] = useState([]);
  const [productModel, setProductModel] = useState(null);
  const [validModel, setValidModel] = useState([]);
  const [validSize, setValidSize] = useState([]);
  const [validFinish, setValidFinish] = useState([]);
  const [validLogo, setValidLogo] = useState([]);
  const [validBoxType, setValidBoxType] = useState([]);
  const [role, setRole] = useState({});

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      model: '',
      size: '',
      logo: '',
      finish: '',
      boxType: '',
      quantity: 1,
    },
    validationSchema: StockSchema,
    onSubmit: (values) => {
      API.post('/order/stock', values)
        .then(() => {
          navigate('/production');
        })
        .catch((error) => {
          toast(`${error.response.data.message}`, { type: 'error' });
        });
    },
  });

  const getValidByProperty = (model, size, finish, logo, boxType, productModell) => {
    setValidModel(
      productModell.map((value) => {
        return value.model.name;
      }),
    );
    setValidSize(
      productModell
        .filter((value) => {
          return value.model.name === model;
        })
        .map((value) => {
          return value.size.name;
        }),
    );
    setValidFinish(
      productModell
        .filter((value) => {
          return value.model.name === model && value.size.name === size;
        })
        .map((value) => {
          return value.finish.name;
        }),
    );
    setValidLogo(
      productModell
        .filter((value) => {
          return (
            value.model.name === model && value.size.name === size && value.finish.name === finish
          );
        })
        .map((value) => {
          return value.logo.name;
        }),
    );
    setValidBoxType(
      productModell
        .filter((value) => {
          return (
            value.model.name === model &&
            value.size.name === size &&
            value.finish.name === finish &&
            value.logo.name === logo
          );
        })
        .map((value) => {
          return value.boxType.name;
        }),
    );
  };

  useEffect(() => {
    const fetch = async () => {
      let result = await API.get(`/product/list/model`);
      let options = result.data.map((item) => {
        return {
          id: `${item.id}_model_name`,
          label: item.name,
          value: item.name,
          name: 'model_name',
        };
      });
      PRODUCT_SECTIONS[0] = {
        id: 'model',
        title: 'Model',
        options,
      };
      setProductSection([...PRODUCT_SECTIONS]);

      result = await API.get(`/product/list/size`);
      options = result.data.map((item) => {
        return {
          id: `${item.id}_size_name`,
          label: item.name,
          value: item.name,
          name: 'size_name',
        };
      });
      PRODUCT_SECTIONS[1] = {
        id: 'size',
        title: 'Size',
        options,
      };
      setProductSection([...PRODUCT_SECTIONS]);

      result = await API.get(`/product/list/finish`);
      options = result.data.map((item) => {
        return {
          id: `${item.id}_finish_name`,
          label: item.name,
          value: item.name,
          name: 'finish_name',
        };
      });
      PRODUCT_SECTIONS[2] = {
        id: 'finish',
        title: 'Finish',
        options,
      };
      setProductSection([...PRODUCT_SECTIONS]);

      result = await API.get(`/product/list/logo`);
      options = result.data.map((item) => {
        return {
          id: `${item.id}_logos_name`,
          label: item.name,
          value: item.name,
          name: 'logos_name',
        };
      });
      PRODUCT_SECTIONS[3] = {
        id: 'logo',
        title: 'Logos',
        options,
      };
      setProductSection([...PRODUCT_SECTIONS]);

      result = await API.get(`/product/list/boxType`);
      options = result.data.map((item) => {
        return {
          id: `${item.id}_boxTypes_name`,
          label: item.name,
          value: item.name,
          name: 'boxTypes_name',
        };
      });
      PRODUCT_SECTIONS[4] = {
        id: 'boxType',
        title: 'Box Type',
        options,
      };
      setProductSection([...PRODUCT_SECTIONS]);

      result = await API.get(`/product-model/list`);
      setProductModel(result.data);
      getValidByProperty(
        formik.values.model,
        formik.values.size,
        formik.values.finish,
        formik.values.logo,
        formik.values.boxType,
        result.data,
      );
    };

    fetch();

    const token = localStorage.getItem('access_token');
    if (token) {
      const decodedToken = jwtDecode(token);
      const roleUser = decodedToken.role;
      setRole(roleUser);
    }
  }, []);

  const handleChangeBoard = (e) => {
    const { value } = e.target;
    const { name } = e.target;

    if (name === 'model_name') {
      formik.values.model = value;
      formik.values.size = '';
      formik.values.finish = '';
      formik.values.logo = '';
      formik.values.boxType = '';
      getValidByProperty(
        formik.values.model,
        formik.values.size,
        formik.values.finish,
        formik.values.logo,
        formik.values.boxType,
        productModel,
      );
    }
    if (name === 'size_name') {
      formik.values.size = value;
      formik.values.finish = '';
      formik.values.logo = '';
      formik.values.boxType = '';
      getValidByProperty(
        formik.values.model,
        formik.values.size,
        formik.values.finish,
        formik.values.logo,
        formik.values.boxType,
        productModel,
      );
    }
    if (name === 'finish_name') {
      formik.values.finish = value;
      formik.values.logo = '';
      formik.values.boxType = '';
      getValidByProperty(
        formik.values.model,
        formik.values.size,
        formik.values.finish,
        formik.values.logo,
        formik.values.boxType,
        productModel,
      );
    }
    if (name === 'logos_name') {
      formik.values.logo = value;
      formik.values.boxType = '';
      getValidByProperty(
        formik.values.model,
        formik.values.size,
        formik.values.finish,
        formik.values.logo,
        formik.values.boxType,
        productModel,
      );
    }
    if (name === 'boxTypes_name') {
      formik.values.boxType = value;
      getValidByProperty(
        formik.values.model,
        formik.values.size,
        formik.values.finish,
        formik.values.logo,
        formik.values.boxType,
        productModel,
      );
    }
    formik.setValues({
      ...formik.values,
    });
  };
  return (
    <Fragment>
      <header className="page-orders__header">
        <h1 className="page-orders__title">Create Order</h1>
      </header>
      <form onSubmit={formik.handleSubmit} className="form page-orders__form">
        <div className="page-orders__section">
          <h2 className="page-orders__section-title">Board Information</h2>
          {productSection.map((section, index) => {
            return (
              <ProductOptionSelect
                key={index}
                title={section.title || ''}
                options={section.options}
                className="page-orders__product-option-select"
                validModel={validModel}
                validSize={validSize}
                validFinish={validFinish}
                validLogo={validLogo}
                validBoxType={validBoxType}
                valueBoxType={formik.values.boxType}
                valueModel={formik.values.model}
                valueSize={formik.values.size}
                valueFinish={formik.values.finish}
                valueLogo={formik.values.logo}
                onChange={handleChangeBoard}
              />
            );
          })}
          {formik.errors.model ||
          formik.errors.size ||
          formik.errors.finish ||
          formik.errors.logo ||
          formik.errors.boxType ? (
            <p className="field-text is-invalid">Board Information is required!</p>
          ) : null}
        </div>
        <div className="page-orders__section">
          <h2 className="page-orders__section-title">Number of Products</h2>
          <div className="form grid">
            <Field className="form__field grid__col-4">
              <Input
                id="quantity"
                name="quantity"
                type="number"
                value={formik.values.quantity}
                min={1}
                onChange={formik.handleChange}
              />
            </Field>
          </div>
        </div>
        <div className="page-orders__form-actions">
          <Button disabled={role !== Roles.ADMIN} type="submit" theme="primary">
            Create Order
          </Button>
        </div>
      </form>
    </Fragment>
  );
};

export default Stock;
