import * as React from 'react';

function SvgSchedule(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M20 5.25A3.25 3.25 0 0016.75 2H5.25A3.25 3.25 0 002 5.25v11.5A3.25 3.25 0 005.25 20h5.772a6.471 6.471 0 01-.709-1.5H5.25a1.75 1.75 0 01-1.75-1.75V7.5h15v2.813c.528.171 1.032.41 1.5.709V5.25zM5.25 3.5h11.5c.966 0 1.75.784 1.75 1.75V6h-15v-.75c0-.966.784-1.75 1.75-1.75zM22 16.5a5.5 5.5 0 10-11 0 5.5 5.5 0 0011 0zm-5.5 0h2a.5.5 0 010 1H16a.5.5 0 01-.5-.491v-3.01a.5.5 0 011 0V16.5z"
      />
    </svg>
  );
}

export default SvgSchedule;
