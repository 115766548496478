import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: undefined,
  role: '',
  email: '',
  accessToken: localStorage.getItem('access_token') ? localStorage.getItem('access_token') : '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUser: (state, action) => {
      state.id = action.payload.id;
      state.email = action.payload.email;
      state.role = action.payload.role;
      state.accessToken = action.payload.accessToken;
      return state;
    },
    removeUser: () => {
      return initialState;
    },
  },
});

export const { getUser, removeUser } = userSlice.actions;
export default userSlice.reducer;
