import * as React from 'react';

function SvgProduct(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M12.492 6.203a.498.498 0 11-.995 0 .498.498 0 01.995 0zM12.492 4.46a.498.498 0 11-.995 0 .498.498 0 01.995 0z"
      />
      <path
        fill="currentColor"
        d="M20.199 17.605l.038-.044.017-.023a.508.508 0 00.067-.094 1.48 1.48 0 00.248-.822V7.407c0-.56-.31-1.069-.808-1.327l-.88-.455h-.002l-6.204-3.208a1.496 1.496 0 00-.472-.15.499.499 0 00-.41-.004c-.17.022-.337.074-.492.154l-7.075 3.67a1.489 1.489 0 00-.806 1.326v9.21a1.496 1.496 0 00.806 1.326l7.074 3.666a1.461 1.461 0 00.465.151l.011.002c.038.005.073.009.099.01l.012.002.082.003h.02c.238 0 .476-.058.686-.167l7.088-3.67c.13-.067.249-.153.353-.254l.003-.003.027-.027.035-.036.018-.022v.001zm-15.514-.539a.496.496 0 01-.269-.442V7.828l1.846.954a.496.496 0 00.671-.213.498.498 0 00-.213-.671l-1.914-.99 6.954-3.607a.498.498 0 01.457 0l6.206 3.208h.002l.76.394-1.915.993a.498.498 0 00.458.884l1.845-.957v8.798a.492.492 0 01-.27.442l-6.811 3.527V17.92a.498.498 0 10-.996 0v2.675l-6.812-3.53z"
      />
      <path
        fill="currentColor"
        d="M5.036 16.04a.498.498 0 10.459.884.498.498 0 00-.459-.884zM6.95 15.656a.508.508 0 00-.27-.27.493.493 0 00-.38 0 .494.494 0 00-.307.46.494.494 0 00.497.498.477.477 0 00.277-.083.515.515 0 00.183-.224.488.488 0 00.028-.287.49.49 0 00-.028-.094zM17.04 16.037a.533.533 0 00.108.162.51.51 0 00.255.136.513.513 0 00.287-.029.512.512 0 00.224-.183.472.472 0 00.074-.179.477.477 0 000-.194.468.468 0 00-.074-.18.497.497 0 00-.874.467zM18.495 16.924a.497.497 0 10.459-.883.497.497 0 00-.458.883zM11.566 16.566a.891.891 0 00.286.093l.01.002.058.006.012.001.046.002h.014a.925.925 0 00.424-.104L15.443 15a.932.932 0 00.325-.282l.002-.002.002-.004c.111-.156.17-.34.17-.532v-3.935a.92.92 0 00-.499-.82l-.376-.194-2.65-1.37a.925.925 0 00-.85 0L8.547 9.43a.92.92 0 00-.498.819v3.933a.917.917 0 00.498.82l3.02 1.565zm-2.523-5.673l2.535 1.308a.924.924 0 00.848-.001l2.52-1.308v3.244l-2.46 1.273v-2.094a.498.498 0 10-.995 0v2.09l-2.447-1.268v-3.244zm2.95-2.13l2.452 1.268-1.107.574-.002.001-1.335.693-2.456-1.267 2.447-1.269z"
      />
    </svg>
  );
}

export default SvgProduct;
