import * as React from 'react';

function SvgAnalytics(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M5.008 12c-.267 0-.524.105-.713.293A.996.996 0 004 13v8c0 .265.106.52.295.707a1.012 1.012 0 001.426 0A.996.996 0 006.017 21v-8a.996.996 0 00-.296-.707A1.013 1.013 0 005.008 12zM10.05 2c-.267 0-.524.105-.713.293A.996.996 0 009.042 3v18c0 .265.106.52.295.707a1.012 1.012 0 001.427 0 .996.996 0 00.295-.707V3a.996.996 0 00-.295-.707A1.013 1.013 0 0010.05 2zm10.084 14c-.267 0-.524.105-.713.293a.996.996 0 00-.295.707v4c0 .265.106.52.295.707a1.012 1.012 0 001.427 0 .996.996 0 00.295-.707v-4a.996.996 0 00-.296-.707 1.012 1.012 0 00-.713-.293zm-5.042-8c-.267 0-.524.105-.713.293a.996.996 0 00-.295.707v12c0 .265.106.52.295.707a1.012 1.012 0 001.427 0A.996.996 0 0016.1 21V9a.996.996 0 00-.295-.707A1.013 1.013 0 0015.091 8z"
      />
    </svg>
  );
}

export default SvgAnalytics;
