import React from 'react';

import Icon from '../../components/Icon';
import { Grid } from '../../components/Grid';
import { Field, Input, Select } from '../../components/Form';
import Tag from '../../components/Tag';
import Button from '../../components/Button';

const OrderDetail = () => {
  return (
    <div className="product-drawer">
      <div className="product-drawer__header">
        <Grid>
          <Grid.Col col={4}>
            <span className="product-drawer__id">616086</span>
          </Grid.Col>
          <Grid.Col col={4}>
            <span className="product-drawer__header-title">
              <Icon name="category-b" className="sidebar-menu__link-icon" />
              Category B
            </span>
          </Grid.Col>
        </Grid>
      </div>
      <div className="product-drawer__body">
        <div className="product-drawer__section">
          <span className="product-drawer__prop">
            <span className="product-drawer__prop-label">Model:</span>
            <span className="product-drawer__prop-value">Glide</span>
          </span>
          <span className="product-drawer__prop">
            <span className="product-drawer__prop-label">Size:</span>
            <span className="product-drawer__prop-value">5&apos;2</span>
          </span>
          <span className="product-drawer__prop">
            <span className="product-drawer__prop-label">Finish:</span>
            <span className="product-drawer__prop-value">Gray</span>
          </span>
          <span className="product-drawer__prop">
            <span className="product-drawer__prop-label">Logos:</span>
            <span className="product-drawer__prop-value">Red</span>
          </span>
        </div>
        <div className="product-drawer__section">
          <Grid>
            <Grid.Col col={8} className="product-drawer__order-details">
              <span className="product-drawer__order-label">Shopify Number</span>
              <span className="product-drawer__order-value">#14344</span>
            </Grid.Col>
            <Grid.Col col={4} className="product-drawer__order-date">
              <Icon name="calendar" className="product-drawer__order-date-icon" />
              04.08.2022
            </Grid.Col>
          </Grid>

          <h2 className="product-drawer__section-title">Contact Information</h2>

          <div className="grid">
            <Field htmlFor="SelectAddress" className="form__field grid__col-4">
              <Select
                id="SelectAddress"
                name="selectAddress"
                options={[
                  { label: 'Select Address', value: '' },
                  { label: 'Value 1', value: 'value1' },
                ]}
              />
            </Field>
            <Field
              htmlFor="FirstName"
              label="First Name"
              className="form__field grid__col-6 grid__col-start-1"
            >
              <Input id="FirstName" name="firstName" placeholder="Your First Name" />
            </Field>
            <Field htmlFor="LastName" label="Last Name" className="form__field grid__col-6">
              <Input id="LastName" name="lastName" placeholder="Your Last Name" />
            </Field>
            <Field htmlFor="Company" label="Company" className="form__field grid__col-12">
              <Input id="Company" name="company" placeholder="Your Company" />
            </Field>
            <Field htmlFor="Address1" label="Address" className="form__field grid__col-12">
              <Input id="Address1" name="address1" placeholder="Your Address" />
            </Field>
            <Field
              htmlFor="Apartment"
              label="Apartment, suite, etc."
              className="form__field grid__col-12"
            >
              <Input id="Apartment" name="address2" placeholder="Your Apartment, suite, etc." />
            </Field>
            <Field htmlFor="PostCode" label="Post Code" className="form__field grid__col-6">
              <Input id="PostCode" name="postCode" placeholder="Your Post Code" />
            </Field>
            <Field htmlFor="City" label="City" className="form__field grid__col-6">
              <Input id="City" name="city" placeholder="Your City" />
            </Field>
            <Field
              htmlFor="countryRegion"
              label="Country/Region"
              className="form__field grid__col-6"
            >
              <Select
                id="countryRegion"
                name="countryRegion"
                options={[
                  { label: 'Your Country/Region', value: '' },
                  { label: 'Value 1', value: 'value1' },
                ]}
              />
            </Field>
            <Field htmlFor="Phone" label="Phone" className="form__field grid__col-6">
              <Input id="Phone" name="phone" placeholder="Your Phone" />
            </Field>
          </div>

          <h2 className="product-drawer__section-title">Tags</h2>

          <div className="product-drawer__tags">
            <Tag label="Urgent" theme="orange" closable={true} className="product-drawer__tag" />
            <Tag label="Repair" theme="orange" closable={true} className="product-drawer__tag" />
            <Tag label="Tag" theme="light-gray" closable={false} className="product-drawer__tag" />
            <Tag label="Tag" theme="light-gray" closable={false} className="product-drawer__tag" />
            <Tag label="Tag" theme="light-gray" closable={false} className="product-drawer__tag" />
          </div>
        </div>
      </div>
      <div className="product-drawer__footer product-drawer__actions">
        <Button theme="white">Delete Order</Button>
        <Button theme="primary" className="product-drawer__submit-button">
          Move to ready for Shipping
        </Button>
      </div>
    </div>
  );
};

export default OrderDetail;
