import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useSelector } from 'react-redux';
import Icon from '../Icon';
import Button from '../Button';
import API from '../../contexts/api';

const SidebarMenu = ({ className }) => {
  const [result, setResult] = useState([]);
  const location = useLocation();
  const util = useSelector((state) => state.util);

  const countOrder = (page) => {
    const productions = API.get(
      `/order/list/all?page=${page}&limit=${process.env.REACT_APP_LIMIT_ITEM}`,
    );
    const trackings = API.get(
      `/order/list/tracking-orders?page=${page}&limit=${process.env.REACT_APP_LIMIT_ITEM}`,
    );
    const stocks = API.get(
      `/order/list/stock?page=${page}&limit=${process.env.REACT_APP_LIMIT_ITEM}`,
    );
    const shippings = API.get(
      `/order/list/shipping?page=${page}&limit=${process.env.REACT_APP_LIMIT_ITEM}`,
    );
    const recycled = API.get(
      `/order/list/recycles?page=${page}&limit=${process.env.REACT_APP_LIMIT_ITEM}`,
    );
    const notifications = API.get(
      `/notifications/list?page=${page}&limit=${process.env.REACT_APP_LIMIT_ITEM}`,
    );

    return Promise.all([productions, trackings, stocks, shippings, recycled, notifications]);
  };

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      const rs = await countOrder(1);
      if (isMounted) {
        setResult(rs);
      }
    };

    fetchData().catch((reason) => console.log(reason));

    return () => {
      isMounted = false;
    };
  }, [location.pathname, util.updateSideBar]);
  return (
    <ul className={classNames('sidebar-menu', className)}>
      <li className="sidebar-menu__item">
        <NavLink to="/production" className="sidebar-menu__link">
          <Icon name="production" className="sidebar-menu__link-icon" />
          Production
          <span className="sidebar-menu__badge">{result[0]?.data.totalItems}</span>
        </NavLink>
      </li>
      <li className="sidebar-menu__item">
        <NavLink to="/tracking" className="sidebar-menu__link">
          <Icon name="link" className="sidebar-menu__link-icon" />
          Tracking Order
          <span className="sidebar-menu__badge">{result[1]?.data.totalItems}</span>
        </NavLink>
      </li>
      <li className="sidebar-menu__item">
        <NavLink to="/stock" className="sidebar-menu__link">
          <Icon name="stock" className="sidebar-menu__link-icon" />
          Stock
          <span className="sidebar-menu__badge">{result[2]?.data.totalItems || 0}</span>
        </NavLink>
      </li>
      <li className="sidebar-menu__item">
        <NavLink to="/shipping" className="sidebar-menu__link">
          <Icon name="shipping" className="sidebar-menu__link-icon" />
          Shipping
          <span className="sidebar-menu__badge">{result[3]?.data.totalItems || 0}</span>
        </NavLink>
      </li>
      <li className="sidebar-menu__item">
        <NavLink to="/notifications" className="sidebar-menu__link">
          <Icon name="bell" className="sidebar-menu__link-icon" />
          Notifications
          <span className="sidebar-menu__badge">{result[5]?.data.totalItems || 0}</span>
        </NavLink>
      </li>
      <li className="sidebar-menu__item">
        <NavLink to="/recycled" className="sidebar-menu__link">
          <Icon name="recycle" className="sidebar-menu__link-icon" />
          Recycled
          <span className="sidebar-menu__badge">{result[4]?.data.totalItems || 0}</span>
        </NavLink>
      </li>
      <li className="sidebar-menu__item sidebar-menu__item--action">
        <Button
          href="/orders/create/customer"
          theme="primary"
          className="sidebar-menu__order-link"
          block
        >
          Create Order
        </Button>
      </li>
      <li className="sidebar-menu__divider"></li>
      <li className="sidebar-menu__item">
        <NavLink to="/analytics" className="sidebar-menu__link">
          <Icon name="analytics" className="sidebar-menu__link-icon" />
          Analytics
        </NavLink>
      </li>
      <li className="sidebar-menu__item">
        <NavLink to="/live-inventory" className="sidebar-menu__link">
          <Icon name="analytics" className="sidebar-menu__link-icon" />
          Live Inventory
        </NavLink>
      </li>
      <li className="sidebar-menu__item">
        <NavLink to="/production-data" className="sidebar-menu__link">
          <Icon name="analytics" className="sidebar-menu__link-icon" />
          Production Data
        </NavLink>
      </li>
      <li className="sidebar-menu__seperator"></li>
      <li className="sidebar-menu__item">
        <NavLink to="/products" className="sidebar-menu__link">
          <Icon name="product" className="sidebar-menu__link-icon" />
          Products
        </NavLink>
      </li>
      <li className="sidebar-menu__item">
        <NavLink to="/operators" className="sidebar-menu__link">
          <Icon name="users" className="sidebar-menu__link-icon" />
          Operators
        </NavLink>
      </li>
      <li className="sidebar-menu__item">
        <NavLink to="/profile" className="sidebar-menu__link">
          <Icon name="user" className="sidebar-menu__link-icon" />
          Profile
        </NavLink>
      </li>
    </ul>
  );
};

SidebarMenu.propTypes = {
  className: PropTypes.string,
};

export default SidebarMenu;
