import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const PaginationLink = ({ children, className, onClick, page, active }) => {
  if (active) {
    return <span className={classNames('pagination-link', className)}>{children}</span>;
  }

  return (
    <button
      onClick={() => {
        onClick(page);
      }}
      className={classNames('pagination-link pagination-link--clickable', className)}
    >
      {children}
    </button>
  );
};

PaginationLink.defaultProps = {
  active: false,
};

PaginationLink.propTypes = {
  children: PropTypes.any.isRequired,
  page: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  className: PropTypes.string,
};

export default PaginationLink;
