import * as React from 'react';

function SvgTimes(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M20.7 4.75a1.025 1.025 0 00-1.45-1.45L12 10.55 4.75 3.3A1.025 1.025 0 103.3 4.75L10.55 12 3.3 19.25a1.025 1.025 0 001.45 1.45L12 13.45l7.25 7.25a1.025 1.025 0 101.45-1.45L13.45 12l7.25-7.25z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgTimes;
