import * as React from 'react';

function SvgStock(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="currentColor"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M21.8 17.317H2.2c-.11 0-.2.083-.2.183v1.28c0 .101.09.184.2.184h19.6c.11 0 .2-.083.2-.183V17.5c0-.101-.09-.184-.2-.184zM3.343 14.455l.992.903c.077.07.205.07.282 0l5.868-5.348 2.44 2.225c.15.136.353.212.565.212a.843.843 0 00.565-.212l6.605-6.018a.173.173 0 000-.258l-.992-.906A.21.21 0 0019.526 5a.21.21 0 00-.141.053l-5.893 5.373-2.442-2.224a.843.843 0 00-.565-.213.843.843 0 00-.565.213l-6.578 5.995a.182.182 0 00-.043.059.168.168 0 00.043.199z" />
    </svg>
  );
}

export default SvgStock;
