import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';

import PageHeader from '../../components/PageHeader';
import { Field, Select } from '../../components/Form';
import Button from '../../components/Button';
import API from '../../contexts/api';

const ProductUpdate = () => {
  const [selectOptions, setSelectOptions] = useState({
    model: [{ label: '', value: '' }],
    size: [{ label: '', value: '' }],
    finish: [{ label: '', value: '' }],
    logo: [{ label: '', value: '' }],
    length: [{ label: '', value: '' }],
    width: [{ label: '', value: '' }],
    volume: [{ label: '', value: '' }],
  });

  const [product, setProduct] = useState({
    model: '',
    size: '',
    finish: '',
    logo: '',
    length: '',
    width: '',
    volume: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  useEffect(() => {
    const fetch = async () => {
      try {
        const items = ['model', 'size', 'finish', 'logo', 'length', 'width', 'volume'];
        const apis = items.map((item) => API.get(`/product/list/${item}`));
        const result = await Promise.all(apis);
        result.forEach((value, index) => {
          const options = value.data.map((o) => {
            return {
              label: o.name,
              value: o.id,
            };
          });
          setProduct((prev) => ({ ...prev, [items[index]]: params.get(items[index]) }));
          setSelectOptions((prev) => ({ ...prev, [items[index]]: options }));
        });
      } catch (error) {
        toast(`${error.response.data.message}`, { type: 'error' });
      }
    };

    fetch();
  }, []);

  function getProductName(item) {
    const name = selectOptions[item].find((o) => o.value === +product[item]).label;
    return name;
  }

  const handleUpdate = async (e) => {
    e.preventDefault();

    const productsName = {
      model: getProductName('model'),
      size: getProductName('size'),
      finish: getProductName('finish'),
      logo: getProductName('logo'),
      length: getProductName('length'),
      width: getProductName('width'),
      volume: getProductName('volume'),
    };

    try {
      await API.put(`/product-model/${params.get('id')}`, productsName);
      navigate(`/products`);
    } catch (error) {
      toast(`${error.response.data.message}`, { type: 'error' });
    }
  };

  return (
    <div className="page-products">
      <Helmet>
        <title>Update Product</title>
      </Helmet>
      <PageHeader title="Update Product" />
      <div className="page-products__content">
        <form className="form page-products__form">
          <div className="page-products__section">
            <div className="grid">
              <Field
                htmlFor="model"
                label="Model"
                className="form__field grid__col-6 grid__col-start-1"
              >
                <Select
                  id="model"
                  name="model"
                  options={selectOptions.model}
                  value={product.model}
                  onChange={handleChange}
                />
              </Field>
              <Field
                htmlFor="size"
                label="Size"
                className="form__field grid__col-6 grid__col-start-1"
              >
                <Select
                  id="size"
                  name="size"
                  options={selectOptions.size}
                  value={product.size}
                  onChange={handleChange}
                />
              </Field>
              <Field
                htmlFor="finish"
                label="Finish"
                className="form__field grid__col-6 grid__col-start-1"
              >
                <Select
                  id="finish"
                  name="finish"
                  options={selectOptions.finish}
                  value={product.finish}
                  onChange={handleChange}
                />
              </Field>
              <Field
                htmlFor="logo"
                label="Logos"
                className="form__field grid__col-6 grid__col-start-1"
              >
                <Select
                  id="logo"
                  name="logo"
                  options={selectOptions.logo}
                  value={product.logo}
                  onChange={handleChange}
                />
              </Field>
              <Field
                htmlFor="length"
                label="Length"
                className="form__field grid__col-6 grid__col-start-1"
              >
                <Select
                  id="length"
                  name="length"
                  options={selectOptions.length}
                  value={product.length}
                  onChange={handleChange}
                />
              </Field>
              <Field
                htmlFor="width"
                label="Width"
                className="form__field grid__col-6 grid__col-start-1"
              >
                <Select
                  id="width"
                  name="width"
                  options={selectOptions.width}
                  value={product.width}
                  onChange={handleChange}
                />
              </Field>
              <Field
                htmlFor="volume"
                label="Volume"
                className="form__field grid__col-6 grid__col-start-1"
              >
                <Select
                  id="volume"
                  name="volume"
                  options={selectOptions.volume}
                  value={product.volume}
                  onChange={handleChange}
                />
              </Field>
            </div>
          </div>
          <div className="page-products__actions">
            <Button href="/products" theme="primary-outline">
              Back
            </Button>
            <Button type="submit" theme="primary" onClick={handleUpdate}>
              Update
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

ProductUpdate.propTypes = {
  children: PropTypes.any,
};

export default ProductUpdate;
