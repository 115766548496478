import * as React from 'react';

function SvgCalendar(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M7.673 2a.7.7 0 01.7.7v1.309h7.517v-1.3a.7.7 0 111.4 0v1.3H20a2 2 0 012 1.999v13.993A2 2 0 0120 22H4a2 2 0 01-2-2V6.009a2 2 0 012-1.999h2.973v-1.31a.7.7 0 01.7-.699zM3.4 9.742v10.259a.601.601 0 00.6.6h16a.603.603 0 00.6-.6V9.756L3.4 9.742zm5.267 6.877v1.666H7v-1.666h1.667zm4.166 0v1.666h-1.666v-1.666h1.666zm4.167 0v1.666h-1.667v-1.666H17zm-8.333-3.977v1.666H7v-1.666h1.667zm4.166 0v1.666h-1.666v-1.666h1.666zm4.167 0v1.666h-1.667v-1.666H17zM6.973 5.408H4a.6.6 0 00-.6.6v2.335l17.2.014V6.008a.6.6 0 00-.6-.6h-2.71v.929a.7.7 0 11-1.4 0v-.929H8.373v.92a.7.7 0 01-1.4 0v-.92z"
      />
    </svg>
  );
}

export default SvgCalendar;
