import React from 'react';
import PropTypes from 'prop-types';

import FilterBarLink from './FilterBarLink';

const FilterBar = ({ links, selectedIndex, onSelect, productionData }) => {
  return (
    <div className="filter-bar">
      <ul className="filter-bar__list">
        {links.map((link, index) => {
          return (
            <li key={index} className="filter-bar__list-item">
              <FilterBarLink
                url={link.url}
                count={link.count}
                isActive={selectedIndex === index}
                onClick={() => onSelect(index, link)}
                productionData={productionData}
              >
                {link.name}
              </FilterBarLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

FilterBar.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.exact({
      url: PropTypes.string,
      name: PropTypes.string,
      count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  selectedIndex: PropTypes.number,
  onSelect: PropTypes.func,
};

export default FilterBar;
