import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import PageHeader from '../../components/PageHeader';
import FilterBar from '../../components/FilterBar';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import API from '../../contexts/api';
import { formatDate } from '../../utils/date';
import Pagination from '../../components/Pagination';
import { Dialog } from '../../components/Modal';
import { activatedUpdateSideBar } from '../../contexts/utilSlice';
import Drawer from '../../components/Drawer';
import OrderDetail from './OrderDetail';
import constants from '../../utils/constants';
import Tag from '../../components/Tag';

const listStatus = [
  'New order',
  'CNC',
  'Backshaping',
  'Infusion',
  'Sanding',
  'Coating',
  'Repair 1 & 2',
  'Detailing',
  'Logo + varnish',
  'Wet sanding + pad installation',
  'Repair 3 & 4',
  'Packaging',
];

const Production = ({ className }) => {
  const location = useLocation();
  const [orders, setOrders] = useState([]);
  const [searchParam] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [ordersStatus, setOrdersStatus] = useState([]);
  const [filterBarSelectedIndex, setFilterBarSelectedIndex] = useState(null);
  const [showDialogDeleteOrder, setShowDialogDeleteOrder] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [selectId, setSelectId] = useState(null);
  const [orderDetailShow, setOrderDetailShow] = useState(false);
  const [updateOrderModalShow, setUpdateOrderModalShow] = useState(false);
  const [bodyData, setBodyData] = useState(null);
  const [updateConfirmOrderLoading, setUpdateConfirmOrderLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const orderDetailToggle = (id) => {
    if (filterBarSelectedIndex === 0) {
      setOrderDetailShow(!orderDetailShow);
      setSelectId(id);
    }
  };

  const handleUpdateCancel = () => {
    setUpdateLoading(false);
    setShowDialogDeleteOrder(false);
  };

  const handleDeleteOrder = async () => {
    setUpdateLoading(true);
    try {
      await API.delete(`order/${selectId}`);
    } catch (error) {
      toast(`${error.response.data.message}`, { type: 'error' });
    }
    setUpdateLoading(false);
    setShowDialogDeleteOrder(false);
    setOrderDetailShow(false);
  };

  useEffect(() => {
    let newOrderStatusURL = '';
    Promise.all([API.get('status/all'), API.get('order/count/status')]).then((result) => {
      const listStatusToShow = result[1].data.filter(
        (status) => listStatus.indexOf(status.status) !== -1,
      );
      listStatusToShow.forEach((status) => {
        let repair1Id;
        let repair2Id;
        let repair3Id;
        let repair4Id;
        status.name = status.status;
        delete status.status;
        result[0].data.forEach((item) => {
          if (status.name === item.name) {
            status.url = `/production/all?status=${item.id}`;
          }
          if (item.name === 'Repair 1') {
            repair1Id = item.id;
          }
          if (item.name === 'Repair 2') {
            repair2Id = item.id;
          }
          if (item.name === 'Repair 3') {
            repair3Id = item.id;
          }
          if (item.name === 'Repair 4') {
            repair4Id = item.id;
          }
        });

        if (status.name === 'Repair 1 & 2') {
          status.url = `/production/all?status=${repair1Id}&status2=${repair2Id}`;
        }
        if (status.name === 'Repair 3 & 4') {
          status.url = `/production/all?status=${repair3Id}&status2=${repair4Id}`;
        }
        if (status.name === 'New order') {
          newOrderStatusURL = status.url;
        }
      });
      setOrdersStatus(listStatusToShow);

      if (location.pathname === '/production') {
        navigate(newOrderStatusURL);
      }
      if (
        (location.pathname === '/production' && searchParam.get('status') === null) ||
        location.pathname === '/'
      ) {
        setFilterBarSelectedIndex(0);
      }

      let url = null;
      if (searchParam.get('status') && searchParam.get('status2')) {
        url = `/order/list/all?page=${currentPage}&limit=${
          process.env.REACT_APP_LIMIT_ITEM
        }&status=${searchParam.get('status')}&status2=${searchParam.get('status2')}`;
      }
      if (searchParam.get('status') && !searchParam.get('status2')) {
        url = `/order/list/all?page=${currentPage}&limit=${
          process.env.REACT_APP_LIMIT_ITEM
        }&status=${searchParam.get('status')}`;
      }
      if (!searchParam.get('status') && !searchParam.get('status2')) {
        url = `/order/list/all?page=${currentPage}&limit=${process.env.REACT_APP_LIMIT_ITEM}&key=STT0`;
      }
      API.get(url)
        .then((value) => {
          setOrders(value.data.data);
          setCurrentPage(value.data.currentPage);
          setTotalPages(value.data.totalPages);
        })
        .catch((reason) => {
          console.log(reason);
        });
    });
    dispatch(activatedUpdateSideBar());
  }, [location.pathname, navigate, searchParam, currentPage, showDialogDeleteOrder]);

  useEffect(() => {
    if (!updateOrderModalShow) {
      setUpdateLoading(false);
    }
  }, [updateOrderModalShow]);

  const textColor = (type, category) => {
    if (type === constants.ProductType.Stock || category === constants.ProductType.CategoryB) {
      return 'default';
    }
    if (type === constants.ProductType.Customer) {
      return 'red';
    }
    if (type === constants.ProductType.Prototype) {
      return 'orange';
    }
    return 'default';
  };

  const handleDowloadPDF = async (productId, orderId) => {
    const result = await API.get(`/pdf/exportPDF/${orderId}`, { responseType: 'blob' });
    const href = window.URL.createObjectURL(new Blob([result.data], { type: 'application/pdf' }));
    const a = document.getElementById('pdfFile');
    a.download = `Production_process_${productId}.pdf`;
    a.href = href;
    a.click();
    a.href = '';
  };

  const handleUpdateOrder = (data) => {
    setBodyData(data);
    setUpdateLoading(true);
    setUpdateOrderModalShow(true);
  };

  const handleUpdateCancelOrder = () => {
    setUpdateLoading(false);
    setUpdateOrderModalShow(false);
  };

  const handleUpdateConfirmOrder = async () => {
    setUpdateConfirmOrderLoading(true);
    try {
      await API.put(`/order/${selectId}`, bodyData);
    } catch (error) {
      toast(`${error.response.data.message}`, { type: 'error' });
    }
    setUpdateLoading(false);
    setUpdateOrderModalShow(false);
    setUpdateConfirmOrderLoading(false);
    setOrderDetailShow(!orderDetailShow);
  };

  return (
    <>
      <div className={classNames('page-production', className)}>
        <Helmet>
          <title>Production</title>
        </Helmet>
        <PageHeader title="Production" />
        <FilterBar
          links={ordersStatus}
          selectedIndex={filterBarSelectedIndex}
          onSelect={(index) => {
            setCurrentPage(1);
            setFilterBarSelectedIndex(index);
          }}
        />
        <div className="page-production__content">
          <table className="order-table page-production__table">
            <thead>
              <tr>
                <th>Type</th>
                <th>Prod ID</th>
                <th>Model</th>
                <th>Size</th>
                <th>Finish</th>
                <th>Box Type</th>
                <th>Logos</th>
                <th>Creation date</th>
                <th>Status</th>
                <th>Days</th>
                <th>Tags</th>
                <th>PDF</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((item, index) => (
                <tr
                  key={index}
                  className={classNames(
                    {
                      'is-danger':
                        item.product.type === 'Customer' &&
                        item.product.category !== constants.ProductType.CategoryB,
                    },
                    {
                      'is-warning':
                        item.product.type === 'Prototype' &&
                        item.product.category !== constants.ProductType.CategoryB,
                    },
                  )}
                >
                  <td
                    onClick={() => orderDetailToggle(item.id)}
                    role="gridcell"
                    className={classNames(
                      `u-color-${textColor(item.product.type, item.product.category)}-600`,
                    )}
                  >
                    {item.product.category === constants.ProductType.CategoryB
                      ? constants.ProductType.CategoryB
                      : item.product.type}
                  </td>
                  <td onClick={() => orderDetailToggle(item.id)} role="gridcell">
                    {item.product.id}
                  </td>
                  <td onClick={() => orderDetailToggle(item.id)} role="gridcell">
                    {item.product?.productModel?.model.name}
                  </td>
                  <td onClick={() => orderDetailToggle(item.id)} role="gridcell">
                    {item.product?.productModel?.size.name}
                  </td>
                  <td onClick={() => orderDetailToggle(item.id)} role="gridcell">
                    {item.product?.productModel?.finish.name}
                  </td>
                  <td onClick={() => orderDetailToggle(item.id)} role="gridcell">
                    {item.product?.productModel?.boxType?.name || 'No box'}
                  </td>
                  <td onClick={() => orderDetailToggle(item.id)} role="gridcell">
                    {item.product?.productModel?.logo.name}
                  </td>
                  <td onClick={() => orderDetailToggle(item.id)} role="gridcell">
                    {formatDate(item.product.orderDate)}
                  </td>
                  <td
                    onClick={() => orderDetailToggle(item.id)}
                    role="gridcell"
                    className="u-background-color-green-600 u-color-white"
                  >
                    {item.product?.statusDetail?.qualityCheck &&
                      `Quality check ${item.product?.statusDetail?.name.toLowerCase()}`}
                    {item.product?.statusDetail?.weight &&
                      `Weight ${item.product?.statusDetail?.name.toLowerCase()}`}
                    {!item.product?.statusDetail?.qualityCheck &&
                      !item.product?.statusDetail?.weight &&
                      item.product?.statusDetail?.name}
                  </td>
                  <td onClick={() => orderDetailToggle(item.id)} role="gridcell">
                    {item.product.days}
                  </td>
                  <td>
                    {item.product.productTag.map((tag, tagIndex) => (
                      <Tag key={tagIndex} label={tag.tag.name} theme="orange" />
                    ))}
                  </td>
                  <td>
                    <Button
                      download
                      href={item.product.pdfUrl}
                      onClick={() => handleDowloadPDF(item.product.id, item.id)}
                      theme="blue"
                      size="xsmall"
                    >
                      <Icon name="download" />
                    </Button>
                    <a id="pdfFile" type="hidden" href="/#">
                      {' '}
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* delete order */}
          <Dialog
            theme="danger"
            show={showDialogDeleteOrder}
            setShow={setShowDialogDeleteOrder}
            icon="production"
            title={`Do you want to delete this order?`}
            actions={
              <>
                <Button theme="white" onClick={handleUpdateCancel}>
                  Cancel
                </Button>
                <Button theme="danger" onClick={handleDeleteOrder} loading={updateLoading}>
                  Confirm
                </Button>
              </>
            }
          />
          {orderDetailShow ? (
            <>
              <Drawer show={orderDetailShow} setShow={setOrderDetailShow}>
                <OrderDetail
                  onSubmit={handleUpdateOrder}
                  loading={updateLoading}
                  id={selectId}
                  toggle={orderDetailShow}
                  setShowDialogDelete={setShowDialogDeleteOrder}
                />
              </Drawer>
            </>
          ) : null}
          <Dialog
            theme="success"
            show={updateOrderModalShow}
            setShow={setUpdateOrderModalShow}
            icon="production"
            title="Update Order"
            text="Would you like to update this order?"
            actions={
              <>
                <Button theme="white" onClick={handleUpdateCancelOrder}>
                  Cancel
                </Button>
                <Button
                  theme="primary"
                  onClick={handleUpdateConfirmOrder}
                  loading={updateConfirmOrderLoading}
                >
                  Confirm
                </Button>
              </>
            }
          />
          {/* delete order */}
          <Dialog
            theme="danger"
            show={showDialogDeleteOrder}
            setShow={setShowDialogDeleteOrder}
            icon="production"
            title={`Do you want to delete this order?`}
            actions={
              <>
                <Button theme="white" onClick={handleUpdateCancel}>
                  Cancel
                </Button>
                <Button theme="danger" onClick={handleDeleteOrder} loading={updateLoading}>
                  Confirm
                </Button>
              </>
            }
          />
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            className="page-tracking__pagination"
            onPageChange={(page) => {
              setCurrentPage(page);
            }}
            threshold={2}
          />
        </div>
      </div>
    </>
  );
};

Production.propTypes = {
  className: PropTypes.string,
};

export default Production;
