import Loadable from 'react-loadable';

import Loading from '../../components/Loading';

import Shipping from './Shipping';

const LoadableShipping = Loadable({
  loader: () => Promise.resolve(Shipping),
  loading: Loading,
});

export default LoadableShipping;
