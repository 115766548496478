import * as React from 'react';

function SvgSearch(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4.198 10.494c0-3.483 2.806-6.296 6.254-6.296 3.448 0 6.254 2.813 6.254 6.296s-2.806 6.297-6.254 6.297c-3.448 0-6.254-2.814-6.254-6.297zM10.452 2C5.778 2 2 5.809 2 10.494c0 4.686 3.778 8.494 8.452 8.494a8.386 8.386 0 005.149-1.757l4.52 4.547a1.099 1.099 0 001.56-1.55l-4.53-4.554a8.487 8.487 0 001.753-5.18C18.904 5.81 15.126 2 10.452 2z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgSearch;
