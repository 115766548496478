import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const PageHeader = ({ title, actions, className }) => {
  return (
    <div className={classNames('page-header', className)}>
      <h1 className="page-header__title">{title}</h1>

      {actions || <div className="page-header__actions">{actions}</div>}
    </div>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
};

export default PageHeader;
