import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import PageHeader from '../../components/PageHeader';
import { Field, Input } from '../../components/Form';
import Button from '../../components/Button';
import API from '../../contexts/api';

const ProductAdd = () => {
  const [product, setProduct] = useState({
    model: '',
    size: '',
    finish: '',
    logo: '',
    length: '',
    width: '',
    volume: '',
    boxType: '',
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await API.post(`product-model/product`, product);
      navigate(`/products`);
    } catch (error) {
      toast(`${error.response.data.message}`, { type: 'error' });
    }

    console.log(product);
  };

  return (
    <div className="page-products">
      <Helmet>
        <title>Add Product</title>
      </Helmet>
      <PageHeader title="Add Product" />
      <div className="page-products__content">
        <form className="form page-products__form">
          <div className="page-products__section">
            <div className="grid">
              <Field
                htmlFor="model"
                label="Model"
                className="form__field grid__col-6 grid__col-start-1"
              >
                <Input
                  id="model"
                  name="model"
                  placeholder="Model of board"
                  value={product.model}
                  onChange={handleChange}
                />
              </Field>
              <Field
                htmlFor="size"
                label="Size"
                className="form__field grid__col-6 grid__col-start-1"
              >
                <Input
                  id="size"
                  placeholder="Size of Board"
                  name="size"
                  value={product.size}
                  onChange={handleChange}
                />
              </Field>
              <Field
                htmlFor="finish"
                label="Finish"
                className="form__field grid__col-6 grid__col-start-1"
              >
                <Input
                  id="finish"
                  placeholder="Finish of Board"
                  name="finish"
                  value={product.finish}
                  onChange={handleChange}
                  maxLength={30}
                />
              </Field>
              <Field
                htmlFor="logo"
                label="Logos"
                className="form__field grid__col-6 grid__col-start-1"
              >
                <Input
                  id="logo"
                  placeholder="Logos of Board"
                  name="logo"
                  value={product.logo}
                  onChange={handleChange}
                />
              </Field>
              <Field
                htmlFor="length"
                label="Length"
                className="form__field grid__col-6 grid__col-start-1"
              >
                <Input
                  id="length"
                  placeholder="Length of Board"
                  name="length"
                  value={product.length}
                  onChange={handleChange}
                />
              </Field>
              <Field
                htmlFor="width"
                label="Width"
                className="form__field grid__col-6 grid__col-start-1"
              >
                <Input
                  id="width"
                  placeholder="Width of Board"
                  name="width"
                  value={product.width}
                  onChange={handleChange}
                />
              </Field>
              <Field
                htmlFor="boxType"
                label="Type of Box"
                className="form__field grid__col-6 grid__col-start-1"
              >
                <Input
                  id="boxType"
                  placeholder="Type of Box"
                  name="boxType"
                  value={product.boxType}
                  onChange={handleChange}
                />
              </Field>
              <Field
                htmlFor="volume"
                label="Volume"
                className="form__field grid__col-6 grid__col-start-1"
              >
                <Input
                  id="volume"
                  placeholder="Volume of Board"
                  name="volume"
                  value={product.volume}
                  onChange={handleChange}
                />
              </Field>
            </div>
          </div>
          <div className="page-products__actions">
            <Button href="/products" theme="primary-outline">
              Back
            </Button>
            <Button type="submit" theme="primary" onClick={handleSubmit}>
              Add Product
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

ProductAdd.propTypes = {
  children: PropTypes.any,
};

export default ProductAdd;
