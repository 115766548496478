import React from 'react';
import PropTypes from 'prop-types';

import DashboardRoutes from './Routes';

const Pages = ({ className }) => <DashboardRoutes className={className} />;

Pages.propTypes = {
  className: PropTypes.string,
};

export default Pages;
