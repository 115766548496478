import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import PaginationLink from './PaginationLink';

const Pagination = ({ totalPages, currentPage, threshold, className, onPageChange }) => {
  const [pages, setPages] = useState([]);

  const generatePages = (newTotalPages, newCurrentPage, newThreshold) => {
    if (newTotalPages === 1) {
      return [];
    }

    const max = 2 * newThreshold + 1;

    if (newTotalPages < max) {
      return Array.from({ length: newTotalPages }, (value, index) => index + 1);
    }

    if (newCurrentPage <= newThreshold) {
      return Array.from({ length: max }, (value, index) => index + 1);
    }

    if (newCurrentPage + newThreshold > newTotalPages) {
      return Array.from({ length: max }, (value, index) => newTotalPages - max + index + 1);
    }

    return Array.from({ length: max }, (value, index) => newCurrentPage - newThreshold + index);
  };

  useMemo(() => {
    const newPages = generatePages(totalPages, currentPage, threshold);
    setPages(newPages);
  }, [currentPage, threshold, totalPages]);

  if (totalPages === 1) {
    return null;
  }

  return (
    <ul className={classNames('pagination', className)} role="navigation" aria-label="pagination">
      {pages.map((page) => (
        <li
          key={page}
          className={classNames('pagination__item', { 'is-active': currentPage === page })}
        >
          <PaginationLink onClick={onPageChange} page={page} active={currentPage === page}>
            {page}
          </PaginationLink>
        </li>
      ))}
    </ul>
  );
};

Pagination.propTypes = {
  totalPages: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  threshold: PropTypes.number.isRequired,
  onPageChange: PropTypes.func,
  className: PropTypes.string,
};

export default Pagination;
