import { DateTime } from 'luxon';

export const formatDate = (jsDate) => {
  if (jsDate) {
    return DateTime.fromJSDate(new Date(jsDate)).toFormat('dd/MM/yyyy');
  }
  return '';
};

export const formatDateDDMMYYYHHMM = (jsDate) => {
  return DateTime.fromJSDate(new Date(jsDate)).toFormat('dd-MM-yyyy HH:mm');
};
