import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Checkbox = ({
  id,
  children,
  name,
  disabled,
  checked,
  optionType,
  buttonShape,
  className,
  onChange,
  value,
}) => {
  return (
    <span
      className={classNames(
        'checkbox',
        optionType ? `checkbox--option-type-${optionType}` : false,
        buttonShape ? `checkbox--button-shape-${buttonShape}` : false,
        className,
      )}
    >
      <input
        id={id}
        name={name}
        value={value}
        type="checkbox"
        className="checkbox__input"
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id} className="checkbox__label">
        {children}
      </label>
    </span>
  );
};

Checkbox.defaultProps = {
  children: 'Checkbox',
  disabled: false,
  checked: false,
};

Checkbox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  children: PropTypes.any,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  optionType: PropTypes.oneOf(['default', 'button']),
  buttonShape: PropTypes.oneOf(['default', 'circle']),
  className: PropTypes.string,
};

export default Checkbox;
