import * as React from 'react';

function SvgShipped(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M2 5v1.208h11.875v9.063h-3.848a2.477 2.477 0 00-2.402-1.812 2.477 2.477 0 00-2.402 1.812H4.5v-3.02H3.25v4.229h1.973a2.477 2.477 0 002.402 1.812 2.477 2.477 0 002.402-1.812h5.196a2.477 2.477 0 002.402 1.812 2.477 2.477 0 002.402-1.812H22v-4.928l-.04-.095-1.25-3.625-.135-.415h-5.45V5H2zm.625 2.417v1.208H8.25V7.417H2.625zm12.5 1.208h4.55l1.075 3.097v3.55h-.723a2.477 2.477 0 00-2.402-1.813 2.477 2.477 0 00-2.402 1.812h-.098V8.625zM3.25 9.834v1.208H7V9.834H3.25zm4.375 4.833c.698 0 1.25.534 1.25 1.209 0 .674-.552 1.208-1.25 1.208s-1.25-.534-1.25-1.208c0-.675.552-1.209 1.25-1.209zm10 0c.698 0 1.25.534 1.25 1.209 0 .674-.552 1.208-1.25 1.208s-1.25-.534-1.25-1.208c0-.675.552-1.209 1.25-1.209z"
      />
    </svg>
  );
}

export default SvgShipped;
