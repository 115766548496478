export const Roles = {
  ADMIN: 'admin',
  USER: 'user',
  VIEWER: 'viewer',
};

const ProductStatus = {
  NewOrder: 'New order',
  Weight: 'Weight',
  QualityCheck: 'Quality check',
  Repair: 'Repair',
  BoardPackaging: 'Packaging',
  Sanding_After_Third_Hot_Coat_Repair: 'Sanding after third hot coat repair',
  AfterDetailing: 'After detailing',
  AfterWetSanding: 'After wet sanding',
  HotCoatRepair_Advanced: 'Hot coat repair (advanced board)',
  Sanding_After_Second_Hot_Coat: 'Sanding after the second hot coat layer',
  After_Sanding_After_Second_Hot_Coat: 'After sanding after the second hot coat layer',
  Repair_In_Hot_Coat: 'Repair in hot coat',
  Hot_Coat_Repair: 'Hot coat repair',
  Completed: 'Completed',
  AfterInfusion: 'After infusion',
};

const ParentStatus = {
  NewOrder: 'New order',
  Infusion: 'Infusion',
  Sanding: 'Sanding',
  Repair1: 'Repair 1',
  Repair2: 'Repair 2',
  Repair3: 'Repair 3',
  Repair4: 'Repair 4',
  WetSanding: 'Wet sanding + pad installation',
  Detailing: 'Detailing',
};

const ProductType = {
  Customer: 'Customer',
  Stock: 'Stock',
  Prototype: 'Prototype',
  CategoryB: 'Category B',
};

const ActionProduct = {
  product: 'product',
  stock: 'Stock',
  shipping: 'Shipping',
  readyforShipping: 'Ready for Shipping',
  recycled: 'Recycled',
};

const ActionNotification = {
  recycled: 'Recycled',
  swap: 'Swap',
};

export default {
  ActionProduct,
  Roles,
  ProductStatus,
  ParentStatus,
  ProductType,
  ActionNotification,
};
