import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';

import PrivateRoute from '../components/PrivateRoutes/PrivateRoute';

// import Home from './Home';
import NoMatch from './NoMatch';
import Production from './Production';
import Tracking from './Tracking';
import Stock from './Stock';
// import CategoryB from './CategoryB';
import Shipping from './Shipping';
// import Shipped from './Shipped';
import Recycled from './Recycled';

import Orders from './Orders';
import OrdersCreate from './Orders/Create';
import OrdersCreateCustomer from './Orders/Create/Customer';
import OrdersCreateStock from './Orders/Create/Stock';
import OrdersCreatePrototypes from './Orders/Create/Prototypes';

// import Analytics from './Analytics';
// import AnalyticsOngoingProduction from './Analytics/AnalyticsOngoingProduction';
import AnalyticsLiveInventory from './Analytics/AnalyticsLiveInventory';
import ProductionData from './ProductionData';

import ProductsLayout from './Products';
import Product from './Products/Product';
import Products from './Products/Products';
import ProductAdd from './Products/ProductAdd';
import ProductUpdate from './Products/ProductUpdate';

// import Process from './Process';
import Login from './Auth/Login';
import ResetPassword from './Auth/ResetPassword';
import Profile from './Profile';

import UsersLayout from './Users';
import User from './Users/User';
import Users from './Users/Users';

import UserRolesLayout from './Users/Roles';
import UserRole from './Users/Roles/UserRole';
import UserRoles from './Users/Roles/UserRoles';
import UserRoleAdd from './Users/Roles/UserRoleAdd';

import OperatorsLayout from './Operators';
import Operator from './Operators/Operator';
import Operators from './Operators/Operators';
import OperatorAdd from './Operators/OperatorAdd';

import Notifications from './Notifications';
import AnalyticsPage from './Analytics/AnalyticsPage';
import Process from './Process';

const DashboardRoutes = ({ className }) => (
  <Routes>
    <Route path="/login" element={<Login />} />
    <Route path="/reset-password" element={<ResetPassword />} />
    <Route path="process/:id" element={<Process className={className} />} />

    <Route path="/" element={<PrivateRoute />}>
      <Route index element={<Production className={className} />} />
      <Route path="profile" element={<Profile className={className} />} />
      <Route path="notifications" element={<Notifications className={className} />} />

      <Route path="users" element={<UsersLayout className={className} />}>
        <Route index element={<Users className={className} />} />

        <Route path="roles" element={<UserRolesLayout className={className} />}>
          <Route index element={<UserRoles className={className} />} />
          <Route path="add" element={<UserRoleAdd className={className} />} />
          <Route path=":id" element={<UserRole className={className} />} />
          <Route path="*" element={<NoMatch className={className} />} />
        </Route>

        <Route path=":id" element={<User className={className} />} />
        <Route path="*" element={<NoMatch className={className} />} />
      </Route>

      <Route path="production" element={<Production className={className} />}>
        <Route index element={<Production className={className} />} />
        <Route path="all" element={<Production className={className} />} />
        <Route path="order/:id" element={<Production className={className} />} />
        <Route path="*" element={<NoMatch className={className} />} />
      </Route>

      <Route path="tracking" element={<Tracking className={className} />} />

      <Route path="stock" element={<Stock className={className} />}>
        <Route index element={<Stock className={className} />} />
        <Route path="all" element={<Stock className={className} />} />
        <Route path="*" element={<NoMatch className={className} />} />
      </Route>

      <Route path="recycled" element={<Recycled className={className} />} />

      <Route path="shipping" element={<Shipping className={className} />}>
        <Route index element={<Shipping className={className} />} />
        <Route path="all" element={<Shipping className={className} />} />
        <Route path="*" element={<NoMatch className={className} />} />
      </Route>

      {/* <Route path="analytics" element={<Analytics className={className} />}>
        <Route index element={<AnalyticsOngoingProduction className={className} />} />
        <Route index element={<AnalyticsLiveInventory className={className} />} />
        <Route path="*" element={<NoMatch className={className} />} />
      </Route> */}
      <Route path="analytics" element={<AnalyticsPage className={className} />} />
      <Route path="live-inventory" element={<AnalyticsLiveInventory className={className} />} />
      <Route path="production-data" element={<ProductionData className={className} />}>
        <Route index element={<ProductionData className={className} />} />
        <Route path="all" element={<ProductionData className={className} />} />
        {/* <Route path="order/:id" element={<ProductionData className={className} />} /> */}
        <Route path="*" element={<NoMatch className={className} />} />
      </Route>

      <Route path="orders" element={<Orders className={className} />}>
        <Route index element={<OrdersCreate className={className} />} />
        <Route path="create" element={<OrdersCreate className={className} />}>
          <Route index path="customer" element={<OrdersCreateCustomer className={className} />} />
          <Route path="stock" element={<OrdersCreateStock className={className} />} />
          <Route path="prototypes" element={<OrdersCreatePrototypes className={className} />} />
          <Route path="*" element={<NoMatch className={className} />} />
        </Route>
        <Route path="*" element={<NoMatch className={className} />} />
      </Route>

      <Route path="products" element={<ProductsLayout className={className} />}>
        <Route index element={<Products className={className} />} />
        <Route path="add" element={<ProductAdd className={className} />} />
        <Route path="update" element={<ProductUpdate className={className} />} />
        <Route path=":id" element={<Product className={className} />} />
        <Route path="*" element={<NoMatch className={className} />} />
      </Route>

      <Route path="operators" element={<OperatorsLayout className={className} />}>
        <Route index element={<Operators className={className} />} />
        <Route path="add" element={<OperatorAdd className={className} />} />
        <Route path=":id" element={<Operator className={className} />} />
        <Route path="*" element={<NoMatch className={className} />} />
      </Route>

      <Route path="*" element={<NoMatch className={className} />} />
    </Route>
  </Routes>
);

DashboardRoutes.propTypes = {
  className: PropTypes.string,
};

export default DashboardRoutes;
