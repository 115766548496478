import Loadable from 'react-loadable';

import Loading from '../../components/Loading';

import ProductionData from './ProductionData';

const LoadableProduction = Loadable({
  loader: () => Promise.resolve(ProductionData),
  loading: Loading,
});

export default LoadableProduction;
