import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Textarea = ({
  id,
  name,
  cols,
  rows,
  value,
  placeholder,
  valid,
  inValid,
  className,
  ...otherProps
}) => {
  return (
    <textarea
      id={id}
      name={name}
      cols={cols}
      rows={rows}
      placeholder={placeholder}
      value={value}
      className={classNames(
        'textarea',
        { 'is-valid': valid },
        { 'is-invalid': inValid },
        className,
      )}
      {...otherProps}
    />
  );
};

Textarea.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  cols: PropTypes.number,
  rows: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  valid: PropTypes.bool,
  inValid: PropTypes.bool,
  className: PropTypes.string,
};

export default Textarea;
