import React from 'react';

import Icon from '../../components/Icon';
import { Grid } from '../../components/Grid';
import { Field, Textarea } from '../../components/Form';
import Button from '../../components/Button';

const OrderComments = () => {
  return (
    <div className="product-drawer">
      <div className="product-drawer__header">
        <Grid>
          <Grid.Col col={4}>
            <span className="product-drawer__id">616086</span>
          </Grid.Col>
          <Grid.Col col={4}>
            <span className="product-drawer__header-title">
              <Icon name="category-b" className="sidebar-menu__link-icon" />
              Category B
            </span>
          </Grid.Col>
        </Grid>
      </div>
      <div className="product-drawer__body">
        <div className="product-drawer__section">
          <Grid>
            <Grid.Col col={4} start={8} className="product-drawer__order-date">
              <Icon name="calendar" className="product-drawer__order-date-icon" />
              04.08.2022
            </Grid.Col>
          </Grid>

          <h2 className="product-drawer__section-title">Notes</h2>

          <Field htmlFor="Notes" className="form__field">
            <Textarea id="Notes" name="notes" rows={4} placeholder="Add Notes..." />
          </Field>
        </div>
      </div>
      <div className="product-drawer__footer product-drawer__actions">
        <Button theme="white">Delete Order</Button>
        <Button theme="primary" className="product-drawer__submit-button">
          Update Order
        </Button>
      </div>
    </div>
  );
};

export default OrderComments;
