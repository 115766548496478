import React, { Fragment, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Field, Input } from '../../../components/Form';
import Button from '../../../components/Button';
import API from '../../../contexts/api';
import { Roles } from '../../../utils/constants';

const PrototypeSchema = Yup.object().shape({
  model: Yup.string().required('Model is required!'),
  size: Yup.string().required('Size is required!'),
  finish: Yup.string().required('Finish is required!'),
  logo: Yup.string().required('Logo is required!'),
  boxType: Yup.string().required('Box type is required!'),
  shopifyId: Yup.string().required('Shopify id is required!'),
});

const Prototypes = () => {
  const [role, setRole] = useState({});
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      model: '',
      size: '',
      finish: '',
      logo: '',
      boxType: '',
      shopifyId: '',
    },
    validationSchema: PrototypeSchema,
    onSubmit: (values) => {
      API.post('/order/prototypes', values)
        .then(() => {
          navigate('/production');
        })
        .catch((error) => {
          toast(`${error.response.data.message}`, { type: 'error' });
        });
    },
  });

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      const decodedToken = jwtDecode(token);
      const roleUser = decodedToken.role;
      setRole(roleUser);
    }
  });

  return (
    <Fragment>
      <header className="page-orders__header">
        <h1 className="page-orders__title">Create Order</h1>
      </header>
      <form onSubmit={formik.handleSubmit} className="form page-orders__form">
        <div className="page-orders__section">
          <h2 className="page-orders__section-title">Board Information</h2>

          <div className="grid">
            <Field
              htmlFor="model"
              label="Model"
              className="form__field grid__col-6 grid__col-start-1"
            >
              <Input
                maxLength={50}
                id="model"
                placeholder="Model of board"
                value={formik.values.model}
                onChange={formik.handleChange}
              />
              {formik.errors.model ? (
                <p className="field-text is-invalid">{formik.errors.model}</p>
              ) : null}
            </Field>
            <Field
              htmlFor="size"
              label="Size"
              className="form__field grid__col-6 grid__col-start-1"
            >
              <Input
                id="size"
                placeholder="Size of Board"
                value={formik.values.size}
                onChange={formik.handleChange}
                maxLength={50}
              />
              {formik.errors.size ? (
                <p className="field-text is-invalid">{formik.errors.size}</p>
              ) : null}
            </Field>
            <Field
              htmlFor="finish"
              label="Finish"
              className="form__field grid__col-6 grid__col-start-1"
            >
              <Input
                id="finish"
                placeholder="Finish of Board"
                value={formik.values.finish}
                onChange={formik.handleChange}
                maxLength={50}
              />
              {formik.errors.finish ? (
                <p className="field-text is-invalid">{formik.errors.finish}</p>
              ) : null}
            </Field>
            <Field
              htmlFor="logo"
              label="Logos"
              className="form__field grid__col-6 grid__col-start-1"
            >
              <Input
                id="logo"
                placeholder="Logos of Board"
                value={formik.values.logo}
                onChange={formik.handleChange}
                maxLength={50}
              />
              {formik.errors.logo ? (
                <p className="field-text is-invalid">{formik.errors.logo}</p>
              ) : null}
            </Field>
            <Field
              htmlFor="boxType"
              label="Box Type"
              className="form__field grid__col-6 grid__col-start-1"
            >
              <Input
                id="boxType"
                placeholder="Box type of Board"
                value={formik.values.boxType}
                onChange={formik.handleChange}
                maxLength={50}
              />
              {formik.errors.logo ? (
                <p className="field-text is-invalid">{formik.errors.boxType}</p>
              ) : null}
            </Field>
            <Field
              htmlFor="shopifyID"
              label="Shopify Id"
              className="form__field grid__col-6 grid__col-start-1"
            >
              <Input
                id="shopifyID"
                placeholder="Shopify Id"
                name="shopifyId"
                value={formik.values.shopifyId}
                onChange={formik.handleChange}
                maxLength={50}
              />
              {formik.errors.shopifyId ? (
                <p className="field-text is-invalid">{formik.errors.shopifyId}</p>
              ) : null}
            </Field>
          </div>
        </div>
        <div className="page-orders__form-actions">
          <Button disabled={role !== Roles.ADMIN} type="submit" theme="primary">
            Create Order
          </Button>
        </div>
      </form>
    </Fragment>
  );
};

export default Prototypes;
