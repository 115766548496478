import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '../Icon';

const Tag = ({ label, theme, className, closable, onClick, ...otherProps }) => {
  const closeButton = (
    <button type="button" className="tag__close-button">
      <Icon name="times" className="tag__close-button-icon" />
    </button>
  );
  return (
    <span
      className={classNames('tag', `tag--${theme}`, className)}
      onClick={onClick}
      aria-hidden="true"
      {...otherProps}
    >
      <span className="tag__label">{label}</span>
      {!closable || closeButton}
    </span>
  );
};

Tag.defaultProps = {
  label: 'Tag',
  theme: 'light-gray',
  closable: false,
};

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  theme: PropTypes.oneOf([
    'gray',
    'light-gray',
    'purple',
    'blue',
    'green',
    'red',
    'orange',
    'yellow',
  ]).isRequired,
  closable: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default Tag;
