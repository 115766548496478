import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const OrderTabs = ({ links, selectedIndex, onSelect, className }) => {
  return (
    <div className={classNames('orders-tab', className)}>
      <ul className="orders-tab__menu">
        {links.map((link, index) => (
          <li key={index} className="orders-tab__menu-item">
            <NavLink
              to={link.url}
              className={classNames('orders-tab__menu-link', {
                'is-active': selectedIndex === index,
              })}
              onClick={() => onSelect(index, link)}
            >
              {link.label}
            </NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

OrderTabs.propTypes = {
  links: PropTypes.array,
  selectedIndex: PropTypes.number,
  onSelect: PropTypes.func,
  className: PropTypes.string,
};

export default OrderTabs;
