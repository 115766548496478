import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';

import OrdersTab from '../../../components/OrdersTab';

const tabUrls = [
  { url: 'customer', label: 'Customer' },
  { url: 'stock', label: 'Stock' },
  { url: 'prototypes', label: 'Prototypes' },
];

const OrdersCreate = () => {
  const [orderTabSelectedIndex, setOrderTabSelectedIndex] = useState(null);

  return (
    <div className="page-orders">
      <OrdersTab
        links={tabUrls}
        selectedIndex={orderTabSelectedIndex}
        onSelect={(index) => setOrderTabSelectedIndex(index)}
        className="page-orders__tab"
      />
      <div className="page-orders__content">
        <Outlet />
      </div>
    </div>
  );
};

export default OrdersCreate;
