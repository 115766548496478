import React, { Fragment, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import ProductOptionSelect from '../../../components/ProductOptionSelect';
import { Field, Input } from '../../../components/Form';
import Button from '../../../components/Button';
import API from '../../../contexts/api';
import { Roles } from '../../../utils/constants';

const PRODUCT_SECTIONS = [];

const CustomerSchema = Yup.object().shape({
  model: Yup.string().required('Board information is required!'),
  size: Yup.string().required('Board information is required!'),
  finish: Yup.string().required('Board information is require!'),
  logo: Yup.string().required('Board information is required!'),
  boxType: Yup.string().required('Board information is required!'),
  firstName: Yup.string().required('First name is required!'),
  lastName: Yup.string().required('Last name is required!'),
  shopifyID: Yup.string().required('Shopify id is required!'),
});

const Customer = () => {
  const [listCustomer, setListCustomer] = useState([]);
  const [productSection, setProductSection] = useState([]);
  const [productModel, setProductModel] = useState(null);
  const [validModel, setValidModel] = useState([]);
  const [validSize, setValidSize] = useState([]);
  const [validFinish, setValidFinish] = useState([]);
  const [validBoxType, setValidBoxType] = useState([]);
  const [validLogo, setValidLogo] = useState([]);
  const [role, setRole] = useState({});

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      id: '',
      shopifyID: '',
      model: '',
      size: '',
      logo: '',
      finish: '',
      boxType: '',
      firstName: '',
      lastName: '',
      company: '',
      address1: '',
      address2: '',
      postCode: '',
      city: '',
      countryRegion: '',
      countryCode: '',
      phone: '',
    },
    validationSchema: CustomerSchema,
    onSubmit: (values) => {
      const bodyData = {
        contact: {},
      };
      bodyData.model = values.model;
      bodyData.size = values.size;
      bodyData.logo = values.logo;
      bodyData.finish = values.finish;
      bodyData.boxType = values.boxType;
      bodyData.contact.firstName = values.firstName;
      bodyData.contact.lastName = values.lastName;
      bodyData.contact.company = values.company;
      bodyData.contact.address1 = values.address1;
      bodyData.contact.address2 = values.address2;
      bodyData.contact.postCode = values.postCode;
      bodyData.contact.city = values.city;
      bodyData.contact.countryRegion = values.countryRegion;
      bodyData.contact.phone = values.phone;
      bodyData.contact.countryCode = values.countryCode;
      bodyData.contact.customerId = values.id;
      bodyData.shopifyOrder = values.shopifyID;
      API.post('/order/customer', bodyData)
        .then(() => {
          navigate('/production');
        })
        .catch((error) => {
          toast(`${error.response.data.message}`, { type: 'error' });
        });
    },
  });

  const getValidByProperty = (model, size, finish, logo, boxType, productModell) => {
    setValidModel(
      productModell.map((value) => {
        return value.model.name;
      }),
    );
    setValidSize(
      productModell
        .filter((value) => {
          return value.model.name === model;
        })
        .map((value) => {
          return value.size.name;
        }),
    );
    setValidFinish(
      productModell
        .filter((value) => {
          return value.model.name === model && value.size.name === size;
        })
        .map((value) => {
          return value.finish.name;
        }),
    );
    setValidLogo(
      productModell
        .filter((value) => {
          return (
            value.model.name === model && value.size.name === size && value.finish.name === finish
          );
        })
        .map((value) => {
          return value.logo.name;
        }),
    );
    setValidBoxType(
      productModell
        .filter((value) => {
          return (
            value.model.name === model &&
            value.size.name === size &&
            value.finish.name === finish &&
            value.logo.name === logo
          );
        })
        .map((value) => {
          return value.boxType.name;
        }),
    );
  };

  const handleChangeBoard = (e) => {
    const { value } = e.target;
    const { name } = e.target;

    if (name === 'model_name') {
      formik.values.model = value;
      formik.values.size = '';
      formik.values.finish = '';
      formik.values.logo = '';
      formik.values.boxType = '';
      getValidByProperty(
        formik.values.model,
        formik.values.size,
        formik.values.finish,
        formik.values.logo,
        formik.values.boxType,
        productModel,
      );
    }
    if (name === 'size_name') {
      formik.values.size = value;
      formik.values.finish = '';
      formik.values.logo = '';
      formik.values.boxType = '';
      getValidByProperty(
        formik.values.model,
        formik.values.size,
        formik.values.finish,
        formik.values.logo,
        formik.values.boxType,
        productModel,
      );
    }
    if (name === 'finish_name') {
      formik.values.finish = value;
      formik.values.logo = '';
      formik.values.boxType = '';
      getValidByProperty(
        formik.values.model,
        formik.values.size,
        formik.values.finish,
        formik.values.logo,
        formik.values.boxType,
        productModel,
      );
    }
    if (name === 'logos_name') {
      formik.values.logo = value;
      formik.values.boxType = '';
      getValidByProperty(
        formik.values.model,
        formik.values.size,
        formik.values.finish,
        formik.values.logo,
        formik.values.boxType,
        productModel,
      );
    }
    if (name === 'boxTypes_name') {
      formik.values.boxType = value;
      getValidByProperty(
        formik.values.model,
        formik.values.size,
        formik.values.finish,
        formik.values.logo,
        formik.values.boxType,
        productModel,
      );
    }
    formik.setValues({
      ...formik.values,
    });
  };

  useEffect(() => {
    const fetch = async () => {
      let result = await API.get(`/product/list/model`);
      let options = result.data.map((item) => {
        return {
          id: `${item.id}_model_name`,
          label: item.name,
          value: item.name,
          name: 'model_name',
        };
      });
      PRODUCT_SECTIONS[0] = {
        id: 'model',
        title: 'Model',
        options,
      };
      setProductSection([...PRODUCT_SECTIONS]);

      result = await API.get(`/product/list/size`);
      options = result.data.map((item) => {
        return {
          id: `${item.id}_size_name`,
          label: item.name,
          value: item.name,
          name: 'size_name',
        };
      });
      PRODUCT_SECTIONS[1] = {
        id: 'size',
        title: 'Size',
        options,
      };
      setProductSection([...PRODUCT_SECTIONS]);

      result = await API.get(`/product/list/finish`);
      options = result.data.map((item) => {
        return {
          id: `${item.id}_finish_name`,
          label: item.name,
          value: item.name,
          name: 'finish_name',
        };
      });
      PRODUCT_SECTIONS[2] = {
        id: 'finish',
        title: 'Finish',
        options,
      };
      setProductSection([...PRODUCT_SECTIONS]);

      result = await API.get(`/product/list/logo`);
      options = result.data.map((item) => {
        return {
          id: `${item.id}_logos_name`,
          label: item.name,
          value: item.name,
          name: 'logos_name',
        };
      });
      PRODUCT_SECTIONS[3] = {
        id: 'logo',
        title: 'Logos',
        options,
      };
      setProductSection([...PRODUCT_SECTIONS]);

      result = await API.get(`/product/list/boxType`);
      options = result.data.map((item) => {
        return {
          id: `${item.id}_boxTypes_name`,
          label: item.name,
          value: item.name,
          name: 'boxTypes_name',
        };
      });
      PRODUCT_SECTIONS[4] = {
        id: 'boxType',
        title: 'Box Type',
        options,
      };
      setProductSection([...PRODUCT_SECTIONS]);

      result = await API.get(`/product-model/list`);
      setProductModel(result.data);
      getValidByProperty(
        formik.values.model,
        formik.values.size,
        formik.values.finish,
        formik.values.logo,
        formik.values.boxType,
        result.data,
      );
    };

    fetch();

    const token = localStorage.getItem('access_token');
    if (token) {
      const decodedToken = jwtDecode(token);
      const roleUser = decodedToken.role;
      setRole(roleUser);
    }
  }, []);

  useEffect(() => {
    const delay = setTimeout(() => {
      API.get(`/contact/list/${formik.values.firstName}`)
        .then((value) => {
          setListCustomer(value.data);
        })
        .catch((reason) => {
          console.log(reason);
        });
    }, 500);

    return () => clearTimeout(delay);
  }, [formik.values.firstName]);

  useEffect(() => {
    const delay = setTimeout(() => {
      API.get(`/contact/list/${formik.values.lastName}`)
        .then((value) => {
          setListCustomer(value.data);
        })
        .catch((reason) => {
          console.log(reason);
        });
    }, 500);

    return () => clearTimeout(delay);
  }, [formik.values.lastName]);

  const fillDataToForm = (e, value) => {
    formik.setValues({
      ...formik.values,
      firstName: value.firstName,
      lastName: value.lastName,
      company: value.company,
      address1: value.address1,
      address2: value.address2,
      postCode: value.postCode,
      city: value.city,
      countryRegion: value.country,
      phone: value.phone,
      countryCode: value.countryCode,
      id: value.id,
    });
  };

  return (
    <Fragment>
      <header className="page-orders__header">
        <h1 className="page-orders__title">Create Order</h1>
      </header>
      <form onSubmit={formik.handleSubmit} className="form page-orders__form">
        <div className="page-orders__section">
          <h2 className="page-orders__section-title">Board Information</h2>
          {productSection.map((section, index) => {
            return (
              <ProductOptionSelect
                key={index}
                title={section.title || ''}
                options={section.options}
                className="page-orders__product-option-select"
                validModel={validModel}
                validSize={validSize}
                validFinish={validFinish}
                validLogo={validLogo}
                validBoxType={validBoxType}
                valueModel={formik.values.model}
                valueBoxType={formik.values.boxType}
                valueSize={formik.values.size}
                valueFinish={formik.values.finish}
                valueLogo={formik.values.logo}
                onChange={handleChangeBoard}
              />
            );
          })}
          {formik.errors.model ||
          formik.errors.size ||
          formik.errors.finish ||
          formik.errors.logo ||
          formik.errors.boxType ? (
            <p className="field-text is-invalid">Board information is required!</p>
          ) : null}
        </div>
        <div className="page-orders__section">
          <Field htmlFor="ShopifyID" label="Shopify ID" className="form__field grid__col-6">
            <Input
              id="ShopifyID"
              name="shopifyID"
              onChange={formik.handleChange}
              value={formik.values.shopifyID}
              placeholder="Shopify ID"
            />
            {formik.errors.shopifyID ? (
              <p className="field-text is-invalid">{formik.errors.shopifyID}</p>
            ) : null}
          </Field>

          <h2 className="page-orders__section-title">Contact Information</h2>

          <div className="grid">
            <Field
              htmlFor="FirstName"
              label="First Name"
              className="form__field grid__col-6 grid__col-start-1"
            >
              <Autocomplete
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                onChange={(e, value) => {
                  fillDataToForm(e, value);
                }}
                value={formik.values.firstName}
                const="true"
                options={listCustomer.map((option) => ({
                  id: `${option.id}`,
                  firstName: `${option.firstName}`,
                  label: `${option.firstName} ${option.lastName} * ${option.email}`,
                  lastName: `${option.lastName}`,
                  company: `${option.company}`,
                  email: `${option.email}`,
                  address1: `${option.address1}`,
                  address2: `${option.address2}`,
                  postCode: `${option.postCode}`,
                  city: `${option.city}`,
                  country: `${option.country}`,
                  phone: `${option.phone}`,
                  countryCode: `${option.countryCode}`,
                }))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search input"
                    InputProps={{
                      ...params.InputProps,
                      type: 'search',
                    }}
                    id="FirstName"
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                  />
                )}
              />
              {formik.errors.firstName ? (
                <p className="field-text is-invalid">{formik.errors.firstName}</p>
              ) : null}
            </Field>
            <Field htmlFor="LastName" label="Last Name" className="form__field grid__col-6">
              <Autocomplete
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                onChange={(e, value) => {
                  fillDataToForm(e, value);
                }}
                value={formik.values.lastName}
                const="true"
                options={listCustomer.map((option) => ({
                  id: `${option.id}`,
                  firstName: `${option.firstName}`,
                  label: `${option.firstName} ${option.lastName} * ${option.email}`,
                  lastName: `${option.lastName}`,
                  company: `${option.company}`,
                  email: `${option.email}`,
                  address1: `${option.address1}`,
                  address2: `${option.address2}`,
                  postCode: `${option.postCode}`,
                  city: `${option.city}`,
                  country: `${option.country}`,
                  phone: `${option.phone}`,
                  countryCode: `${option.countryCode}`,
                }))}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search input"
                    InputProps={{
                      ...params.InputProps,
                      type: 'search',
                    }}
                    id="LastName"
                    name="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                  />
                )}
              />
              {formik.errors.lastName ? (
                <p className="field-text is-invalid">{formik.errors.lastName}</p>
              ) : null}
            </Field>
            <Field htmlFor="Company" label="Company" className="form__field grid__col-12">
              <Input
                id="Company"
                name="company"
                onChange={formik.handleChange}
                value={formik.values.company}
                placeholder="Your Company"
              />
            </Field>
            <Field htmlFor="Address1" label="Address" className="form__field grid__col-12">
              <Input
                id="Address1"
                name="address1"
                onChange={formik.handleChange}
                value={formik.values.address1}
                placeholder="Your Address"
              />
            </Field>
            <Field
              htmlFor="Apartment"
              label="Apartment, suite, etc."
              className="form__field grid__col-12"
            >
              <Input
                id="Apartment"
                name="address2"
                onChange={formik.handleChange}
                value={formik.values.address2}
                placeholder="Your Apartment, suite, etc."
              />
            </Field>
            <Field htmlFor="PostCode" label="Post Code" className="form__field grid__col-6">
              <Input
                id="PostCode"
                name="postCode"
                onChange={formik.handleChange}
                value={formik.values.postCode}
                placeholder="Your Post Code"
              />
            </Field>
            <Field htmlFor="City" label="City" className="form__field grid__col-6">
              <Input
                id="City"
                name="city"
                onChange={formik.handleChange}
                value={formik.values.city}
                placeholder="Your City"
              />
            </Field>
            <Field
              htmlFor="countryRegion"
              label="Country/Region"
              className="form__field grid__col-6"
            >
              <Input
                id="countryRegion"
                name="countryRegion"
                onChange={formik.handleChange}
                value={formik.values.countryRegion}
                placeholder="Your Country"
              />
            </Field>
            <Field htmlFor="Phone" label="Phone" className="form__field grid__col-6">
              <Input
                id="Phone"
                name="phone"
                onChange={formik.handleChange}
                value={formik.values.phone}
                placeholder="Your Phone"
              />
            </Field>
          </div>
        </div>
        <div className="page-orders__form-actions">
          <Button disabled={role !== Roles.ADMIN} type="submit" theme="primary">
            Create Order
          </Button>
        </div>
      </form>
    </Fragment>
  );
};

export default Customer;
