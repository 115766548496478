import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { toast } from 'react-toastify';

import Pagination from '../../components/Pagination';
import PageHeader from '../../components/PageHeader';
import Button from '../../components/Button';
import { Dialog } from '../../components/Modal';
import API from '../../contexts/api';
import { activatedUpdateSideBar } from '../../contexts/utilSlice';

// TODO: Connect API
// const PRODUCTS = [
//   { id: 1, name: 'Comet V3', size: '4"1', finish: 'Black', logo: 'Black' },
//   { id: 2, name: 'Glide', size: '4"1', finish: 'Black', logo: 'Black' },
//   { id: 3, name: 'Glide V2', size: '4"1', finish: 'Black', logo: 'Black' },
//   { id: 4, name: 'Koster Pro', size: '4"1', finish: 'Black', logo: 'Black' },
//   { id: 5, name: 'Slate', size: '4"1', finish: 'Black', logo: 'Black' },
//   { id: 6, name: 'Slate SW', size: '4"1', finish: 'Black', logo: 'Black' },
//   { id: 7, name: 'Trix', size: '4"1', finish: 'Black', logo: 'Black' },
// ];

const Products = () => {
  const [products, setProducts] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogDeleteProduct, setDialogDeleteProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const dispatch = useDispatch();

  const handleClickDialogDelete = (id) => {
    setLoading(true);
    setShowDialog(true);
    setDialogDeleteProduct(id);
  };

  const handleClickDialogCancel = () => {
    setLoading(false);
    setShowDialog(false);
    setDialogDeleteProduct(null);
  };

  const handleDeleteProduct = async (id) => {
    try {
      await API.delete(`/product-model/${id}`);
    } catch (error) {
      toast(`${error.response.data.message}`, { type: 'error' });
    }
    const filteredProducts = products.filter((product) => product.id !== id);
    setProducts(filteredProducts);
    setLoading(false);
    setShowDialog(false);
    setDialogDeleteProduct(null);
  };

  const fetchProducts = async () => {
    const response = await API.get(
      `product-model/list-products?limit=${process.env.REACT_APP_LIMIT_ITEM}&page=${currentPage}`,
    );
    setProducts(response.data.data);
    setTotalPages(response.data.totalPages);
    dispatch(activatedUpdateSideBar());
    if (response.data.totalPages < currentPage && response.data.totalPages > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [currentPage]);

  return (
    <div className="page-products">
      <Helmet>
        <title>Products</title>
      </Helmet>
      <PageHeader title="Products" />
      <div className="page-products__content">
        <div className="page-products__section">
          <div className="content-grid">
            <table className="order-table page-stock__table">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Model</th>
                  <th>Size</th>
                  <th>Finish</th>
                  <th>Logos</th>
                  <th>Length</th>
                  <th>Width</th>
                  <th>Volume</th>
                  <th>Box Type</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {products
                  ? products.map((product, index) => {
                      return (
                        <tr key={index} className={classNames(`is-default`)}>
                          <td role="gridcell">{product.id}</td>
                          <td role="gridcell">{product.model.name}</td>
                          <td role="gridcell">{product.size.name}</td>
                          <td role="gridcell">{product.finish.name}</td>
                          <td role="gridcell">{product.logo.name}</td>
                          <td role="gridcell">{product.length.name}</td>
                          <td role="gridcell">{product.width.name}</td>
                          <td role="gridcell">{product.volume.name}</td>
                          <td role="gridcell">{product.boxType.name}</td>
                          <td role="gridcell">
                            <div className="content-grid__item-actions">
                              <Button
                                href={`/products/update?id=${product.id}&model=${product.model.id}&size=${product.size.id}&finish=${product.finish.id}&logo=${product.logo.id}&length=${product.length.id}&width=${product.width.id}&volume=${product.volume.id}`}
                                theme="warning"
                                size="xsmall"
                              >
                                Update
                              </Button>
                              <Button
                                theme="danger"
                                size="xsmall"
                                onClick={() => {
                                  handleClickDialogDelete(product.id);
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          className="page-tracking__pagination"
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
          threshold={2}
        />
        <div className="page-products__actions">
          <Button href="/products/add" theme="primary" loading={loading} disabled={loading}>
            Add Product
          </Button>
        </div>
      </div>
      <Dialog
        theme="danger"
        show={showDialog}
        setShow={handleClickDialogCancel}
        icon="production"
        title={`Do you want to delete this product?`}
        actions={
          <>
            <Button theme="white" onClick={handleClickDialogCancel}>
              Cancel
            </Button>
            <Button theme="danger" onClick={() => handleDeleteProduct(dialogDeleteProduct)}>
              Confirm
            </Button>
          </>
        }
      />
    </div>
  );
};

Products.propTypes = {
  children: PropTypes.any,
};

export default Products;
